<template>
  <div class="object-list">
    <div class="object-card" v-for="object in objects" :key="object.id">
      <img :src="object.image" alt="Object" />
      <div class="object-card-text">
        <h3>{{ object.name }}</h3>
        <p class="object-card-addr">{{ object.status }}</p>
        <p class="object-card-addr">{{ object.date }}</p>
        <div class="object-card-info">
          <div><p class="object-card-price"><strong>{{ object.price }} р</strong></p></div>
        </div>
        <button
            class="btn"
            @click="goToBook(object.id)"
            :disabled="object.status === 'Отменено'"
            :class="{ 'btn-disabled': object.status === 'Отменено' }"
        >
          Просмотреть информацию о бронировании
          <svg xmlns="http://www.w3.org/2000/svg" width="22" height="16" fill="none">
            <path fill="#fff" d="M10.12 12.547a.494.494 0 0 1-.353-.147.503.503 0 0 1 0-.707L13.46 8 9.767 4.307a.503.503 0 0 1 0-.707.503.503 0 0 1 .706 0l4.047 4.047a.503.503 0 0 1 0 .706L10.473 12.4c-.1.1-.226.147-.353.147Z"/>
            <path fill="#fff" d="M14.053 8.5H2.833a.504.504 0 0 1-.5-.5c0-.273.227-.5.5-.5h11.22c.274 0 .5.227.5.5s-.226.5-.5.5Z"/>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

function formatUnixToDate(unixTime) {
  const date = new Date(unixTime * 1000);  // Преобразуем UnixTime в миллисекунды
  const day = String(date.getDate()).padStart(2, '0');  // Добавляем ведущий 0
  const month = String(date.getMonth() + 1).padStart(2, '0');  // Месяцы в JS начинаются с 0
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;  // Возвращаем дату в формате DD.MM.YYYY
}
export default {
  name: 'ReservationList',
  data() {
    return {
      objects: []  // Изначально пустой массив
    };
  },
  methods: {
    async fetchReservations() {
      try {
        const myHeaders = new Headers();
        myHeaders.append("token", this.$store.getters.token);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow"
        };

        const response = await fetch("https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/vrs", requestOptions);
        const data = await response.json();

        if (response.ok) {
          this.objects = data.data.map(reservation => ({
            id: reservation.id,
            name: reservation.nm,
            status: reservation.status,
            date: `${formatUnixToDate(reservation.start_dt)} - ${formatUnixToDate(reservation.end_dt)}`,  // Формируем дату как диапазон
            price: reservation.total_price,
            image: reservation.file_id
                ? `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/file/${reservation.file_id}/${reservation.file_name}`
                : 'https://via.placeholder.com/200'  // Заглушка, если нет изображения
          }));
        } else {
          console.error("Ошибка при загрузке данных:", data.errors);
        }
      } catch (error) {
        console.error("Ошибка запроса:", error);
      }
    },
    goToBook(id) {
      this.$router.push({
        name: 'BookingInfo',
        params: { id }
      });
    }
  },
  mounted() {
    this.fetchReservations();  // Загружаем данные при монтировании компонента
  }
}
</script>

<style scoped>

.btn {
  width: 100%;
  height: 68px;
  font-size: 16px;
  line-height: 20px;
}

@media (width < 768px) {
  .btn {
    padding: 16px 14px;
    gap: 5px;
    font-size: 14px;
    line-height: 18px;
    height: 56px;
  } 
}
.object-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

/* @media (width >= 870px) { */
@media (width >= 768px) {
  .object-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: start;
  } 
}

@media (width >= 1236px) {
  .object-list {
    grid-template-columns: repeat(3, 1fr);
  } 
}

@media (width >= 1600px) {
  .object-list {
    grid-template-columns: repeat(4, 1fr);
  } 
}

.object-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 280px;
  cursor: pointer;
}

@media (width >= 870px) {
  .object-card {
    width: 346px;
  } 
}

.object-card img {
  width: 280px;
  height: 202px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

@media (width >= 870px) {
  .object-card img {
    width: 346px;
    height: 250px;
  } 
}

.object-card-text {
  padding: 20px;
}

.object-card h3 {
  text-align: left;
}

.object-card-addr {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  text-align: left;
  color: #979797;
}

@media (width >= 768px) {
  .object-card-addr {
    font-size: 16px;
    line-height: 22px;
  } 
}

@media (width >= 768px) {
  .object-card-addr {
    font-size: 18px;
    line-height: 24px;
  } 
}

.object-card-info {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 16px;
}

.object-card-size {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #222222;
}

@media (width >= 768px) {
  .object-card-size {
    font-size: 16px;
  } 
}

@media (width >= 1440px) {
  .object-card-size {
    font-size: 16px;
  } 
}

.object-card-price {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  color: #199057;
  margin: 0;
}

@media (width >= 768px) {
  .object-card-price {
    font-size: 18px;
  } 
}

@media (width >= 1440px) {
  .object-card-price {
    font-size: 20px;
  } 
}

</style>