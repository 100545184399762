<template>
  <div class="form-field">
    <label :for="id" class="text">{{ label }}</label>

    <!-- Для пароля с глазиком -->
    <div v-if="type === 'password'" class="password-input-wrapper">
      <input
          :id="id"
          :type="passwordVisible ? 'text' : 'password'"
          :value="modelValue"
          :placeholder="placeholder"
          :class="{ 'input-error': !isValid && showErrors }"
          @input="$emit('update:modelValue', $event.target.value)"
          v-bind="inputProps"
      />
      <span
          class="toggle-password-icon"
          @click="togglePasswordVisibility"
          :title="passwordVisible ? 'Скрыть пароль' : 'Показать пароль'"
      >
        <i :class="passwordVisible ? 'icon-eye-off' : 'icon-eye'"></i>
      </span>
    </div>
    <!--
    <template v-if="type === 'password'">
    <input
          :id="id"
          :type="passwordVisible ? 'text' : 'password'"
          :value="modelValue"
          :placeholder="placeholder"
          :class="{ 'input-error': !isValid && showErrors }"
          @input="$emit('update:modelValue', $event.target.value)"
          v-bind="inputProps"
      />
      <span
          class="toggle-password-icon"
          @click="togglePasswordVisibility"
          :title="passwordVisible ? 'Скрыть пароль' : 'Показать пароль'"
      >
        <i :class="passwordVisible ? 'icon-eye-off' : 'icon-eye'"></i>
      </span>
    </template>-->

    <!-- Для select -->
    <select
        v-else-if="type === 'select'"
        :id="id"
        :value="modelValue"
        :class="{ 'input-error': !isValid && showErrors }"
        @change="$emit('update:modelValue', $event.target.value)"
        v-bind="inputProps"
    >
      <slot />
    </select>

    <!-- Для обычного input -->
    <input
        v-else
        :id="id"
        :type="type"
        :value="modelValue"
        :placeholder="placeholder"
        :class="{ 'input-error': !isValid && showErrors }"
        @input="$emit('update:modelValue', $event.target.value)"
        v-bind="inputProps"
    />

    <!-- Сообщение об ошибке -->
    <ErrorMessage v-if="!isValid && showErrors" :message="errorMessage" />

    <!-- Слот для footer -->
    <div v-if="$slots.footer" class="form-field-footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
import ErrorMessage from "./ErrorMessage.vue";

export default {
  name: "FormField",
  components: {
    ErrorMessage,
  },
  props: {
    label: { type: String, required: true },
    id: { type: String, required: true },
    modelValue: { type: [String, Number], required: true },
    placeholder: { type: String, default: "" },
    errorMessage: { type: String, default: "" },
    isValid: { type: Boolean, default: true },
    showErrors: { type: Boolean, default: false },
    type: { type: String, default: "text" },
    inputProps: { type: Object, default: () => ({}) },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      passwordVisible: false, // Для переключения видимости пароля
    };
  },
  methods: {
    togglePasswordVisibility() {
      this.passwordVisible = !this.passwordVisible;
    },
  },
};
</script>

<style scoped>
.input-error {
  border: 1px solid red;
}

.password-input-wrapper {
  position: relative;
}

.password-input-wrapper input {
  width: 78%;
  padding-right: 40px; /* Учитываем место для иконки */
}

@media (width >= 340px) {
  .password-input-wrapper input {
    width: 80%;
  }
}

@media (width >= 380px) {
  .password-input-wrapper input {
    width: 82%;
  }
}

@media (width >= 420px) {
  .password-input-wrapper input {
    width: 84%;
  }
}

@media (width >= 460px) {
  .password-input-wrapper input {
    width: 86%;
  }
}

@media (width >= 500px) {
  .password-input-wrapper input {
    width: 87%;
  }
}

@media (width >= 540px) {
  .password-input-wrapper input {
    width: 87%;
  }
}

@media (width >= 580px) {
  .password-input-wrapper input {
    width: 89%;
  }
}

@media (width >= 660px) {
  .password-input-wrapper input {
    width: 91%;
  }
}

@media (width >= 768px) {
  .password-input-wrapper input {
    width: 504px;
  }
}

.toggle-password-icon {
  position: absolute;
  top: 57%;
  right: 20px;
  transform: translateY(-50%);
  cursor: pointer;
}

/* Пример для иконок, замените на вашу иконографику или используйте библиотеку */
.icon-eye::before {
  content: url("@/assets/eye.svg");
  /* background-size: 24px 24px; */
}

.icon-eye-off::before {
  content: url("@/assets/eye-slash.svg"); /* Иконка закрытого глаза */
  /* background-size: 24px 24px; */
}

/* Стиль для footer */
.form-field-footer {
  margin-top: 5px;
  text-align: right;
  font-size: 12px;
  color: #007bff;
  cursor: pointer;
}

.form-field-footer:hover {
  text-decoration: underline;
}

</style>