<template>
  <div class="form">
    <div class="form-field-wrapper">

      <!-- Название комнаты -->
      <div class="form-field">
        <p class="text">Название</p>
        <input
            type="text"
            :value="roomName"
            @input="$emit('update:roomName', $event.target.value)"
            placeholder="Введите название"
        />
      </div>

      <!-- Цена -->
      <div class="form-field">
        <p class="text">Цена</p>
        <input
            type="number"
            :value="roomPrice"
            @input="$emit('update:roomPrice', $event.target.value)"
            placeholder="Введите цену"
        />
      </div>

      <!-- Количество гостей -->
      <div class="form-field">
        <p class="text">Количество гостей</p>
        <input
            type="number"
            :value="roomGuests"
            @input="$emit('update:roomGuests', $event.target.value)"
            placeholder="Введите количество гостей"
        />
      </div>

      <!-- Количество комнат -->
      <div class="form-field">
        <p class="text">Количество комнат</p>
        <input
            type="number"
            :value="roomApartments"
            @input="$emit('update:roomApartments', $event.target.value)"
            placeholder="Введите количество комнат"
        />
      </div>

      <!-- Площадь -->
      <div class="form-field">
        <p class="text">Площадь</p>
        <input
            type="number"
            :value="roomSquare"
            @input="$emit('update:roomSquare', $event.target.value)"
            placeholder="Введите площадь"
        />
      </div>

      <br/>

      <!-- Можно с животными -->
      <div class="checkbox-item">
        <input
            type="checkbox"
            id="animals"
            :checked="roomPets"
            @change="$emit('update:roomPets', $event.target.checked)"
            class="checkbox-input"
        />
        <label for="animals" class="checkbox-text">Можно с животными</label>
      </div>

      <!-- Завтрак включен -->
      <div class="checkbox-item">
        <input
            type="checkbox"
            id="breakfast"
            :checked="roomBreakfast"
            @change="$emit('update:roomBreakfast', $event.target.checked)"
            class="checkbox-input"
        />
        <label for="breakfast" class="checkbox-text">Завтрак включен</label>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'RoomFormData',
  props: {
    roomName: { type: String, required: true },
    roomPrice: { type: Number, required: true },
    roomGuests: { type: Number, required: true },
    roomApartments: { type: Number, required: true },
    roomSquare: { type: Number, required: true },
    roomPets: { type: Boolean, required: true },
    roomBreakfast: { type: Boolean, required: true },
  },
  emits: [
    'update:roomName',
    'update:roomPrice',
    'update:roomGuests',
    'update:roomApartments',
    'update:roomSquare',
    'update:roomPets',
    'update:roomBreakfast'
  ],
};
</script>
