<template>
  <div class="objects">
    <h1>Реквизиты</h1>
    <div class="form">
      <div class="form-field-wrapper">
        <div class="form-field">
          <p class="text">БИК банка</p>
          <input type="text"
                 v-model="userBik"
                 placeholder="Введите БИК для автозаполнения"
                 @change="fetchBankData" />
          <p v-if="!userBik" class="hint">Сначала введите БИК банка, чтобы заполнить остальные поля</p>
        </div>
        <div class="form-field">
          <p class="text">К/С</p>
          <input type="text" v-model="userKS" placeholder="К/с" />
        </div>
        <div class="form-field">
          <p class="text">Р/С</p>
          <input type="text" v-model="userRS" placeholder="Р/с" />
        </div>
        <button class="btn" :disabled="!isFormValid" @click="saveChanges">Сохранить изменения</button>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'BankDetails',
  data() {
    return {
      userBik: '',
      userKS: '',
      userRS: '',
      errorMessage: ''
    };
  },
  computed: {
    userParams() {
      return this.$store.getters.userParams;
    },
    isFormValid() {
      const onlyDigits = /^\d+$/; // Регулярное выражение для проверки только цифр
      return (
          this.userBik && onlyDigits.test(this.userBik) &&
          this.userKS && onlyDigits.test(this.userKS) &&
          this.userRS && onlyDigits.test(this.userRS)
      );
    }
  },
  methods: {
    async loadUserParams() {
      if (!this.userParams.length) {
        await this.$store.dispatch('fetchUserParams');
      }
      this.initializeFormFields();
    },
    initializeFormFields() {
      // Ищем значения для каждого параметра и присваиваем их полям формы
      const bikParam = this.userParams.find(param => param.dpt_id === 'BIK');
      const ksParam = this.userParams.find(param => param.dpt_id === 'ks');
      const rsParam = this.userParams.find(param => param.dpt_id === 'rs');

      this.userBik = bikParam ? bikParam.val : '';
      this.userKS = ksParam ? ksParam.val : '';
      this.userRS = rsParam ? rsParam.val : '';
    },
    async fetchBankData() {
      const dadataToken = this.$store.getters.dadataToken;
      try {
        const response = await axios.post(
            'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/bank',
            { query: this.userBik },
            {
              headers: {
                'Authorization': `Token ${dadataToken}`,
                'Content-Type': 'application/json'
              }
            }
        );

        const bankData = response.data.suggestions[0].data;
        this.userKS = bankData.correspondent_account || '';
      } catch (error) {
        console.error('Ошибка при получении данных по БИК:', error);
        this.errorMessage = 'Не удалось загрузить данные по БИК';
      }
    },
    async saveChanges() {
      try {
        const token = this.$store.getters.token;
        const userId = this.$store.getters.userId; // Получаем ID пользователя из store

        // Подготовка массива параметров для сохранения
        const payload = [
          { dpt_id: 'BIK', val: this.userBik },
          { dpt_id: 'ks', val: this.userKS },
          { dpt_id: 'rs', val: this.userRS }
        ];

        // Получаем текущие параметры из БД, чтобы проверить, какие из них уже существуют
        const existingParams = this.userParams || [];

        console.log("Existing Parameters:", existingParams);
        console.log("Payload:", payload);

        for (const param of payload) {
          const existingParam = existingParams.find(item => item.dpt_id === param.dpt_id);

          if (existingParam) {
            // Параметр существует, выполняем PATCH-запрос для обновления значения
            await axios.patch(
                `https://лк.домшеринг.рф/api/ups/${existingParam.id}`, // Используем ID существующей записи
                { val: param.val },
                {
                  headers: {
                    'Token': token,
                    'Content-Type': 'application/json'
                  }
                }
            );
          } else {
            // Параметр не существует, выполняем POST-запрос для создания новой записи
            await axios.post(
                'https://лк.домшеринг.рф/api/ups',
                {
                  dpt_id: param.dpt_id,
                  usr_id: userId,
                  val: param.val
                },
                {
                  headers: {
                    'Token': token,
                    'Content-Type': 'application/json'
                  }
                }
            );
          }
        }

        alert('Изменения успешно сохранены');
      } catch (error) {
        console.error('Ошибка при сохранении данных:', error);
        alert('Произошла ошибка при сохранении данных');
      }
    }
  },
  mounted() {
    this.loadUserParams();
  },
  watch: {
    userParams(newParams) {
      const bikParam = newParams.find(p => p.ups_id === 'BIK');
      const ksParam = newParams.find(p => p.ups_id === 'ks');
      const rsParam = newParams.find(p => p.ups_id === 'rs');

      this.userBik = bikParam ? bikParam.val : '';
      this.userKS = ksParam ? ksParam.val : '';
      this.userRS = rsParam ? rsParam.val : '';
    }
  }
};
</script>

<style scoped>

h1 {
  margin-bottom: 40px;
}

.btn {
  width: 100%;
}

@media (width >= 768px) {
  .btn {
    margin-top: auto;
  }
}

.text {
  font-size: 16px;
  font-weight: 400;
  color: #686868;
  margin: 0;
}

.objects {
  margin-top: 64px;
}

</style>