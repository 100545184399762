<template>
  <div>
    <AppHeader />

    <div class="card-wrapper">
      <button class="btn btn-light" @click="$router.go(-1)">
        <svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
          <path fill="#199057" d="M9.57 5.18c.19 0 .38.07.53.22.29.29.29.77 0 1.06L4.56 12l5.54 5.54c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0l-6.07-6.07a.754.754 0 0 1 0-1.06L9.04 5.4c.15-.15.34-.22.53-.22Z"/>
          <path fill="#199057" d="M3.67 11.25H20.5c.41 0 .75.34.75.75s-.34.75-.75.75H3.67c-.41 0-.75-.34-.75-.75s.34-.75.75-.75Z"/>
        </svg>
        Назад
      </button>
      <h2>{{ roomName }}</h2>
    </div>

    <PhotosBlock :romId="roomId" />

    <div class="specification">
      <div class="objects objects-specification">
        <div class="form-field">
          <p class="text">Цена</p>
          <p class="specification-text">{{ roomPrice }} р.</p>
        </div>
        <div class="form-field">
          <p class="text">Количество гостей</p>
          <p class="specification-text">{{ roomGuests }}</p>
        </div>
        <div class="form-field">
          <p class="text">Количество комнат</p>
          <p class="specification-text">{{ roomApartments }}</p>
        </div>
        <div class="form-field">
          <p class="text">Площадь</p>
          <p class="specification-text">{{ roomSquare }}</p>
        </div>
        <div class="form-field">
          <p class="text">Можно с животными</p>
          <p class="specification-text">{{ roomPetsText }}</p>
        </div>
        <div class="form-field">
          <p class="text">Завтрак включен</p>
          <p class="specification-text">{{ roomBreakfastText }}</p>
        </div>
        <h3>Описание</h3>
        <pre>{{ roomDescription }}</pre>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import axios from 'axios';
import PhotosBlock from "@/components/PhotosBlock.vue";

export default {
  name: 'ViewRoom',
  components: {
    PhotosBlock,
    AppHeader,
    AppFooter
  },
  data() {
    return {
      roomId: this.$route.params.id,
      objectId: null,
      roomName: '',
      roomPrice: null,
      roomGuests: null,
      roomApartments: null,
      roomSquare: null,
      roomPets: null,
      roomBreakfast: null,
      roomFileID: null,
      roomFileName: '',
      roomDescription: '',
      photos: []
    };
  },
  computed: {
    roomBreakfastText() {
      return this.roomBreakfast === 1 ? 'да' : 'нет';
    },
    roomPetsText() {
      return this.roomPets === 1 ? 'да' : 'нет';
    }
  },
  methods: {
    editRoom(id) {
      this.$router.push({ name: 'EditRoom', params: { id } }).then(() => {
        window.scrollTo(0, 0);
      });
    },
    async fetchRoomData() {
      const token = this.$store.getters.token;
      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/rom/${this.roomId}`, {
          headers: {
            'Token': token
          }
        });
        const room = response.data.data;
        this.objectId = room.cot_id;
        this.roomName = room.nm;
        this.roomPrice = room.price_dec;
        this.roomGuests = room.num_of_guests;
        this.roomApartments = room.num_of_rooms;
        this.roomSquare = room.square;
        this.roomPets = room.with_pets_is;
        this.roomBreakfast = room.with_breakfast_is;
        this.roomDescription = room.descr;
      } catch (error) {
        console.error('Ошибка при загрузке данных варианта размещения:', error);
      }
    },
    async fetchPhotos() {
      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/fil?filters[rom_id-e]=${this.roomID}`);
        this.photos = response.data.data; // Загружаем фото в массив
      } catch (error) {
        console.error('Ошибка при загрузке фотографий:', error);
      }
    }
  },
  mounted() {
    this.fetchRoomData();
    this.fetchPhotos();
  }
};
</script>

<style scoped>

.map {
  width: 100%;
  height: 300px;
  margin-top: 20px;
}

.btn {
  font-size: 14px;
}

@media (width >= 768px) {
  .btn {
    font-size: 16px;
  }
}

.btn-light {
  gap: 12px;
  width: 190px;
  height: 48px;
}

@media (width >= 768px) {
  .btn-light {
    height: 56px;
  }
}

@media (width >= 768px) {
  .light-wrapper {
    margin: 62px 43px 64px;
  }
}

h2 {
  text-align: center;
  margin: 0;
}

.card-wrapper {
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding: 30px 20px;
}

@media (width >= 768px) {
  .card-wrapper  {
    padding: 64px 80px;
  }
}


.specification {
  padding: 30px 0 0;
}

@media (width >= 768px) {
  .specification {
    padding: 60px 0 0;
  }
}

.cards {
  margin-top: 22px;
}

.comfort-list {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

@media (width > 460px) and (width < 910px) {
  .comfort-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 20px;
  }
}


@media (width >= 910px) and (width < 1140px){
  .comfort-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    gap: 20px;
  }
}

@media (width >= 1140px) {
  .comfort-list {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.comfort-item {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 12px;
}


h1 {
  margin-bottom: 40px;
}

.objects {
  margin-top: 64px;
}

.photo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 0 20px;
}

@media (width >= 860px) {
  .photo-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    padding: 0 80px;
  }
}

@media (width >= 1230px) {
  .photo-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width >= 1600px) {
  .photo-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

.photo img {
  width: 280px;
  height: 202px;
}

@media (width >= 768px) {
  .photo img {
    width: 346px;
    height: 250px;
  }
}

.objects-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.objects-table th, .objects-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.objects-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.highlight-yellow {
  background-color: #fff8c4;
}

.highlight-red {
  background-color: #f8d7da;
}

.highlight-green {
  background-color: #d4edda;
}
</style>