<template>
  <BaseForm @submit="login" title="Вход">
    <!-- Поля формы -->
    <FormField 
        v-model="email"
        label="Email"
        placeholder="Введите ваш email"
        type="email"
        :isValid="isEmailValid"
        errorMessage="Введите корректный email."
        :showErrors="showErrors"
    />
    <FormField
        v-model="password"
        label="Пароль"
        placeholder="Введите пароль"
        type="password"
        :isValid="isPasswordValid"
        errorMessage="Введите пароль."
        :showErrors="showErrors"
    >
      <template #footer>
        <p class="text-password" @click="goToPassword">Восстановить пароль</p>
      </template>
    </FormField>

    <!-- Ошибки -->
    <ErrorMessage v-if="errorMessage" :message="errorMessage" />

    <!-- Кнопки -->
    <div class="btn-wrapper">
      <BaseButton type="submit" :disabled="isLoading">
        {{ isLoading ? "Вход..." : "Войти" }}
      </BaseButton>
      <BaseButton variant="light" @click="goToRegistration">Регистрация</BaseButton>
    </div>

    <!-- Модальное окно загрузки -->
    <BaseModal v-if="isLoading">
      <template #header>
        Авторизация
      </template>
      <template #body>
        <div class="spinner-wrapper">
          <div class="spinner"></div>
          <p>Проверяем данные...</p>
        </div>
      </template>
    </BaseModal>
  </BaseForm>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";
import FormField from "@/components/FormField.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";

export default {
  components: {
    BaseForm,
    FormField,
    BaseButton,
    BaseModal,
    ErrorMessage,
  },
  data() {
    return {
      email: "",
      password: "",
      errorMessage: "",
      isLoading: false,
      showErrors: false,
    };
  },
  computed: {
    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
    isPasswordValid() {
      return this.password.trim() !== "";
    },
    isFormValid() {
      return this.isEmailValid && this.isPasswordValid;
    },
  },
  methods: {
    goToRegistration() {
      this.$router.push({ name: "RegistrationPage" });
    },
    goToPassword() {
      this.$router.push({ name: "PasswordPage" });
    },
    async login() {
      if (this.isLoading || !this.isFormValid) {
        this.showErrors = true;
        return;
      }

      this.isLoading = true;
      this.errorMessage = "";

      const credentials = {
        login: this.email,
        pass: this.password,
      };

      try {
        // Вызов логина через Vuex
        const success = await this.$store.dispatch("login", credentials);

        if (success) {
          const targetRoute = JSON.parse(localStorage.getItem("targetRoute"));
          if (targetRoute) {
            localStorage.removeItem("targetRoute");
            this.$router.push(targetRoute);
          } else {
            this.$router.push("/");
          }
        } else {
          this.errorMessage = "Неправильный логин или пароль. Попробуйте снова.";
        }
      } catch (error) {
        console.error("Ошибка авторизации:", error);
        this.errorMessage = "Произошла ошибка. Попробуйте снова.";
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.btn-wrapper {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

/* Спиннер */
.spinner-wrapper {
  text-align: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3; /* Светлая часть */
  border-top: 4px solid #3498db; /* Синяя часть */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Ссылка для восстановления пароля */


.text-password {
  color: #A3A3A3;
  font-size: 12px;
  font-weight: 300;
  text-align: right;
  margin: 0;
}



.text-password:hover {
  text-decoration: underline;
}

@media (width >= 768px) {
  .text-password {
    font-size: 16px;
  }
}

@media (width >= 768px) {
  .text-password {
    font-size: 14px;
  }
}


/* Оставил на всякий случай... */
.form-wrapper {
  display: flex;
  background-color: #FAFDFC ;
  width: 100%;
  justify-content: center;
}

@media (width < 768px) {
  .form-wrapper {
    flex-direction: column;
  }
}

.form {
  background-color: #FAFDFC;
  padding: 0 20px;
}

@media (width >= 768px) {
  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (width >= 768px) and (width < 1280px) {
  .form {
    padding: 50px 98px;
    border-radius: 10px;
    top: 24%;
    position: absolute;
  }
}

.title {
  font-size: 22px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 19px;
  text-align: center;
}

@media (width >= 768px) {
  .title {
    font-size: 36px;
  }
}

.text {
  font-size: 14px;
  font-weight: 400;
  color: #686868;
  margin: 0;
}

/* Общие стили для полей формы */

.form-field-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* width: 504px; */
}

@media (width < 768px) {
  .form-field {
    width: 100%;
  }
}

@media (width >= 768px) {
.form-field {
    width: 566px;
  }
}

/* Кнопки */


.btn {
  width: 100%;
  font-size: 16px;
  height: 52px;
}

@media (width >= 768px) {
  .logo {
    font-size: 20px;
    height: 56px;
  }
}

</style>