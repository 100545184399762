<template>
  <div>
    <AppHeader />
    <div class="btn-wrapper">
      <button class="btn btn-light" @click="$router.go(-1)">
        <svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
          <path fill="#199057" d="M9.57 5.18c.19 0 .38.07.53.22.29.29.29.77 0 1.06L4.56 12l5.54 5.54c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0l-6.07-6.07a.754.754 0 0 1 0-1.06L9.04 5.4c.15-.15.34-.22.53-.22Z" />
          <path fill="#199057" d="M3.67 11.25H20.5c.41 0 .75.34.75.75s-.34.75-.75.75H3.67c-.41 0-.75-.34-.75-.75s.34-.75.75-.75Z" />
        </svg>
        Назад
      </button>
      <button class="btn" @click="bookRoom">Забронировать</button>
      <div v-if="bookingMessage" :class="{'success-message': bookingSuccess, 'error-message': !bookingSuccess}">
        {{ bookingMessage }}
      </div>
    </div>
    <div class="objects">
      <h1>Добавление бронирования</h1>
      <div class="form">
        <div class="form-field-wrapper">
          <div class="form-field">
            <p class="text">Дата начала</p>
            <input type="date" v-model="objectName" />
          </div>
          <div class="form-field">
            <p class="text">Дата окончания</p>
            <input type="date" v-model="objectDescription" />
          </div>
          <div class="form-field">
            <p class="text">Оплаченная сумма</p>
            <input type="number" v-model="objectLocation"/>
          </div>
          <div class="form-field">
            <p class="text">Общая стоимость</p>
            <input type="number" v-model="objectPrice" />
          </div>
          <div class="form-field">
            <p class="text">Дата создания</p>
            <input type="date" v-model="objectLatitude" />
          </div>
          <div class="form-field">
            <p class="text">Объект</p>
            <select id="objects" name="objects">
              <option value="house">Дом</option>
              <option value="house">Дом</option>
              <option value="house">Дом</option>
            </select>
          </div>
          <div class="form-field">
            <p class="text">Номер</p>
            <select id="objects" name="objects">
              <option value="house">Дом</option>
              <option value="house">Дом</option>
              <option value="house">Дом</option>
            </select>
          </div>
          <div class="form-field">
            <p class="text">Статус</p>
            <select id="objects" name="objects">
              <option value="house">Дом</option>
              <option value="house">Дом</option>
              <option value="house">Дом</option>
            </select>
          </div>
          <div class="form-field">
            <p class="text">Количество гостей</p>
            <input type="number" v-model="objectLatitude" />
          </div>
          <!-- Ошибки валидации -->
          <!-- <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
          <button class="btn" :disabled="!isFormValid" @click="saveChanges">Сохранить изменения</button> -->
        </div>

        <div class="form-field-wrapper">
          <h2>Основной гость</h2>
          <div class="form-field">
            <p class="text">ФИО</p>
            <input type="text" v-model="objectName" />
          </div>
          <div class="form-field">
            <p class="text">Серия паспорта</p>
            <input type="number" v-model="objectName" />
          </div>
          <div class="form-field">
            <p class="text">Номер паспорта</p>
            <input type="number" v-model="objectName" />
          </div>
          <div class="form-field">
            <p class="text">E-mail</p>
            <input type="text" v-model="objectName" />
          </div>
          <div class="form-field">
            <p class="text">Телефон</p>
            <input type="number" v-model="objectName" />
          </div>
          <!-- Ошибки валидации -->
          <!-- <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
          <button class="btn" :disabled="!isFormValid" @click="saveChanges">Сохранить изменения</button> -->
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';

export default {
  name: 'AddReservations',
  components: {
    AppHeader,
    AppFooter
  },
  data() {
    return {
      userId: null,
      userName: '',
      userPhone: '',
      userEmail: '',
      userLocation: '',
      originalUserData: {},
      suggestions: [],  // Подсказки Dadata
      errorMessage: '',
      dadataToken: '2467755542e320d99d52db07195776888a7e0366' // Ваш API ключ от Dadata
    };
  },
  computed: {
    isFormValid() {
      return this.isDataChanged && this.validateFields();
    },
    isDataChanged() {
      return (
          this.userName !== this.originalUserData.nm ||
          this.userPhone !== this.originalUserData.phone ||
          this.userEmail !== this.originalUserData.mail ||
          this.userLocation !== this.originalUserData.remark_tx
      );
    }
  },
  methods: {
    async fetchUserProfile() {
      try {
        const myHeaders = new Headers();
        myHeaders.append('token', this.$store.getters.token);

        const requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        const response = await fetch('https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/usr', requestOptions);
        const result = await response.json();

        if (response.ok) {
          const userData = result.data[0];
          // Обновляем значения после загрузки
          this.userId = userData.id;
          this.userName = userData.nm || '';
          this.userPhone = userData.phone || '';
          this.userEmail = userData.mail || '';
          this.userLocation = userData.remark_tx || '';

          this.originalUserData = { ...userData };
          // Валидируем поля после загрузки данных
          this.validateFields();
        } else {
          console.error('Ошибка при загрузке данных профиля:', result.errors);
        }
      } catch (error) {
        console.error('Ошибка запроса:', error);
      }
    },
    validateFields() {
      this.errorMessage = '';

      if (!this.userName.trim() || !this.userEmail.trim() || !this.userPhone.trim() || !this.userLocation.trim()) {
        this.errorMessage = 'Все поля должны быть заполнены.';
        return false;
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.userEmail)) {
        this.errorMessage = 'Неверный формат email.';
        return false;
      }

      const phonePattern = /^\+7\d{10}$/;
      if (!phonePattern.test(this.userPhone)) {
        this.errorMessage = 'Номер телефона должен начинаться с +7 и содержать 10 цифр.';
        return false;
      }

      return true;
    },
    async getDadataSuggestions() {
      if (!this.userLocation) {
        this.suggestions = [];
        return;
      }

      const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Token ' + this.dadataToken
        },
        body: JSON.stringify({ query: this.userLocation })
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();

        if (response.ok) {
          this.suggestions = result.suggestions;
        } else {
          this.suggestions = [];
        }
      } catch (error) {
        console.error('Ошибка получения подсказок:', error);
        this.suggestions = [];
      }
    },
    selectSuggestion(value) {
      this.userLocation = value;
      this.suggestions = [];
    },
    async saveChanges() {
      if (!this.isFormValid) return;

      try {
        const myHeaders = new Headers();
        myHeaders.append('token', this.$store.getters.token);
        myHeaders.append('Content-Type', 'application/json');

        const raw = JSON.stringify({
          nm: this.userName,
          mail: this.userEmail,
          phone: this.userPhone,
          remark_tx: this.userLocation
        });

        const requestOptions = {
          method: 'PATCH',
          headers: myHeaders,
          body: raw,
          redirect: 'follow'
        };

        const response = await fetch(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/usr/${this.userId}`, requestOptions);
        const result = await response.json();

        if (response.ok) {
          alert('Изменения успешно сохранены');
          this.fetchUserProfile(); // Обновляем данные профиля
        } else {
          console.error('Ошибка при сохранении данных:', result.errors);
        }
      } catch (error) {
        console.error('Ошибка запроса:', error);
      }
    }
  },
  mounted() {
    this.fetchUserProfile();
  }
};
</script>

<style scoped>


h1 {
  margin-bottom: 40px;
}

.objects {
  margin-top: 64px;
}

.form {
  display: flex;
  gap: 30px;
}

@media (width >= 768px) {
  .form {
    flex-direction: row;
    align-items: flex-start;
    gap: 50px;
  } 
}

@media (width >= 768px) {
  .form-field-wrapper {
    display: flex;
  } 
}

h2 {
  margin: 0;
}

.btn {
    width: 100%;
  }

  @media (width >= 768px) {
  .btn {
    width: 330px;
  }
}

  .btn-light {
    gap: 12px;
    width: 160px;
    height: 48px;
    width: 100%;
  }

  @media (width >= 768px) {
  .btn-light {
    height: 56px;
    width: 330px;
  }
}

.btn-wrapper {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (width >= 768px) {
  .btn-wrapper  {
    padding: 30px 80px;
    justify-content: space-between;
    flex-direction: row;
  }
}

</style>