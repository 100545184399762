<template>
  <div class="modal-overlay" @click.self="closeModal">
    <div class="modal-content">
      <div class="modal-header" v-if="$slots.header">
        <slot name="header" />
        <button class="close-button" @click="closeModal">×</button>
      </div>
      <div class="modal-body">
        <slot name="body" />
      </div>
      <div class="modal-footer" v-if="$slots.footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseModal",
  emits: ["close"],
  methods: {
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #eaeaea;
  padding-bottom: 20px;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  text-align: left;
}

@media (width >= 768px) {
  .modal-header {
    font-size: 30px;
    line-height: 40px;
  } 
}

.modal-body {
  padding: 10px 0;
}

.modal-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 40px;
  cursor: pointer;
  color: #aaa;
}
.close-button:hover {
  color: #000;
}
</style>