<template>
  <div class="changes-error">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
      <path stroke="#DF3939" stroke-width="2.475" d="M2.656 25.079a5.18 5.18 0 0 0 4.538 4.547c2.948.341 5.998.69 9.121.69s6.173-.349 9.121-.69a5.18 5.18 0 0 0 4.538-4.547c.329-2.934.656-5.97.656-9.079 0-3.108-.327-6.144-.656-9.079a5.18 5.18 0 0 0-4.538-4.546c-2.948-.342-5.998-.69-9.121-.69s-6.173.348-9.121.69A5.18 5.18 0 0 0 2.656 6.92C2.327 9.856 2 12.891 2 16.001c0 3.107.327 6.143.656 9.078Z"/>
      <path stroke="#DF3939" stroke-linecap="round" stroke-width="2.475" d="m12.266 11.951 8.098 8.098M20.364 11.951l-8.098 8.098"/>
    </svg>  
    <p class="changes-text">Ошибка! Изменения не могут быть сохранены.</p>
  </div>
</template>

<style scoped>
.changes-error {
  padding: 35px 65px;
  background-color: #FAEAE7;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  border: 2px solid #DF3939;
}

.changes-text {
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

</style>