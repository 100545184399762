<template>
  <div class="video-container">
    <iframe
        v-if="videoUrl"
        :src="embedUrl"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
    ></iframe>
  </div>
  <br/>
</template>

<script>
export default {
  name: "VideoEmbed",
  props: {
    videoUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    embedUrl() {
      if (this.videoUrl.includes("youtube.com") || this.videoUrl.includes("youtu.be")) {
        // Преобразуем ссылку YouTube в формат встраивания
        const videoId = this.videoUrl.split("v=")[1]?.split("&")[0] || this.videoUrl.split("/").pop();
        return `https://www.youtube.com/embed/${videoId}`;
      } else if (this.videoUrl.includes("vk.com")) {
        // ВКонтакте ссылки преобразуются в фрейм
        return this.videoUrl.replace("/video", "/video_ext");
      }
      return "";
    },
  },
};
</script>

<style scoped>
.video-container {
  position: relative;
  padding-bottom: 56.25%; /* Соотношение сторон 16:9 */
  height: 0;
  overflow: hidden;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>