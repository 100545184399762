<template>
  <div>
    <AppHeader />

    <div class="card-wrapper">
      <button class="btn btn-light" @click="$router.go(-1)">
        <svg class="arrow-icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
          <path fill="#199057" d="M9.57 5.18c.19 0 .38.07.53.22.29.29.29.77 0 1.06L4.56 12l5.54 5.54c.29.29.29.77 0 1.06-.29.29-.77.29-1.06 0l-6.07-6.07a.754.754 0 0 1 0-1.06L9.04 5.4c.15-.15.34-.22.53-.22Z"/>
          <path fill="#199057" d="M3.67 11.25H20.5c.41 0 .75.34.75.75s-.34.75-.75.75H3.67c-.41 0-.75-.34-.75-.75s.34-.75.75-.75Z"/>
        </svg>
        Назад
      </button>
      <h2>{{ objectName }}</h2>
    </div>

    <PhotosBlock :cot-id="objectId" />

    <div class="specification">
      <div class="objects objects-specification">
        <div class="form-field">
          <h3>Тип объекта</h3>
          <p class="specification-text">{{ objectTypeName }}</p>
        </div>
        <div class="form-field">
          <h3>Цена</h3>
          <p class="specification-text">{{ objectPrice }} р.</p>
        </div>

        <div class="form-field">
          <h3>Описание</h3>
          <pre>{{ objectDescription }}</pre>
        </div>
      </div>

      <VideoEmbed :videoUrl="objectVideo" v-if="objectVideo" />

      <div class="objects">
        <h3>Удобства</h3>
        <div v-if="selectedFacilities.length === 0">
          <p>Удобства не выбраны</p>
        </div>
        <div v-else class="comfort-list">
          <div class="comfort-item" v-for="facility in selectedFacilities" :key="facility.id">
            <p class="h3-description">{{ facility.name }}</p>
          </div>
        </div>
      </div>

      <div class="objects">
        <h3>Активности рядом</h3>
        <div v-if="selectedActivities.length === 0">
          <p>Активности не выбраны</p>
        </div>
        <div v-else class="comfort-list">
          <div class="comfort-item" v-for="activity in selectedActivities" :key="activity.id">
            <p class="h3-description">{{ activity.name }}</p>
          </div>
        </div>
      </div>

      <div class="objects">
        <h3>Расположение</h3>
        <p>{{ objectLocation }}</p>
        <!-- Кликабельная строка для уточнения координат -->
        <p class="update-coords-link" @click="toggleCoordsForm">
          Уточнить координаты
        </p>

        <!-- Форма для ввода широты и долготы -->
        <div v-if="showCoordsForm" class="coords-form">
          <div class="coords-inputs">
            <input type="text" v-model="newLatitude" placeholder="Широта" />
            <input type="text" v-model="newLongitude" placeholder="Долгота" />
          </div>
          <button class="btn btn-update" @click="updateCoordinates">Обновить</button>
        </div>
        <div id="map" class="map"></div> <!-- Карта будет здесь -->
      </div>
    </div>

    <div class="objects"  id="rooms">
      <h2>Варианты размещения</h2>
      <button class="btn btn-add" @click="goToCreateRoom()">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
          <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3.515v16.97M3.515 12h16.97"/>
        </svg>
        Добавить
      </button>
      <!-- Обработка трех состояний -->
      <div v-if="loadingRooms">
        <p>Загрузка вариантов размещения...</p>
      </div>
      <div v-else-if="!rooms.length">
        <p>Варианты размещения отсутствуют</p>
      </div>
      <div v-else>
        <table v-if="rooms.length" class="objects-table">
          <thead>
          <tr>
            <th>ID</th>
            <th>Название</th>
            <th>Цена</th>
            <th>Статус</th>
            <th>Действия</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="room in rooms" :key="room.id" :class="getRowClass(room.sto_id)"  @click="viewRoom(room.id)" style="cursor: pointer;">
            <td aria-label="ID">{{ room.id }}</td>
            <td aria-label="Название">{{ room.nm }}</td>
            <td aria-label="Цена">{{ room.price_dec }} р.</td>
            <td aria-label="Статус">{{ getStatusText(room) }} </td>
            <td aria-label="Действия" class="actions">
              <div class="actions-wrapper">
                <!-- Редактировать -->
                <i
                    class="edit action-icon"
                    @click.stop="editRoom(room.id)"
                    v-tippy="'Редактировать'"
                ></i>

                <!-- Отправить на модерацию -->
                <i
                    v-if="room.sto_id === 'draft' || room.sto_id === 'den'"
                    class="send action-icon"
                    @click.stop="sendToModerateRoom(room.id)"
                    v-tippy="'Отправить на модерацию'"
                ></i>

                <!-- Удалить -->
                <i
                    class="trash action-icon"
                    @click.stop="deleteRoom(room.id)"
                    v-tippy="'Удалить'"
                ></i>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import axios from 'axios';
import PhotosBlock from "@/components/PhotosBlock.vue";
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {nextTick} from "vue"; // Подключаем Leaflet
import tippy from 'tippy.js';
import VideoEmbed from "@/components/VideoEmbed.vue";


export default {
  name: 'ViewObject',
  components: {
    VideoEmbed,
    PhotosBlock,
    AppHeader,
    AppFooter
  },
  directives: {
    tippy: {
      mounted(el, binding) {
        tippy(el, {
          content: binding.value,
          placement: 'top', // Расположение (top, bottom, left, right)
        });
      },
    },
  },
  data() {
    return {
      objectId: this.$route.params.id,
      objectName: '',
      objectPrice: null,
      objectTypeName: '',
      objectLocation: '',
      objectFileID: null,
      objectFileName: '',
      objectLatitude: null,
      objectLongitude: null,
      objectVideo: '',
      geoLat: null,
      geoLon: null,
      objectDescription: '',
      selectedActivities: [],
      selectedFacilities: [],
      photos: [],
      map: null,
      rooms: [],
      loadingRooms: true,
      showCoordsForm: false,  // Показывать/скрывать форму
      newLatitude: null,        // Новая широта
      newLongitude: null         // Новая долгота
    };
  },
  watch: {
    // Следим за изменениями в данных объекта и инициализируем карту, если координаты есть
    objectLatitude() {
      this.initMap(); // Инициализация карты
    }
  },
  computed: {
    activities() {
      return this.$store.getters.activities || [];
    },
    facilities() {
      return this.$store.getters.facilities || [];
    },
    objectTypes() {
      return this.$store.getters.objectTypes || [];
    }
  },
  methods: {
    toggleCoordsForm() {
      this.showCoordsForm = !this.showCoordsForm;
      // Заполнить поля текущими координатами, если они есть
      if (this.object) {
        this.newLatitude = this.object.latitude || '';
        this.newLongitude = this.object.longitude || '';
      }
    },
    async updateCoordinates() {
      const token = this.$store.getters.token;

      if (!this.newLatitude || !this.newLongitude) {
        alert('Пожалуйста, введите корректные координаты.');
        return;
      }

      try {
        await axios.patch(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/cot/${this.objectId}`,
            {
              latitude: this.newLatitude,
              longitude: this.newLongitude
            },
            {
              headers: {
                'Token': token,
                'Content-Type': 'application/json'
              }
            }
        );

        // Обновляем объект с новыми координатами
        this.objectLatitude = this.newLatitude;
        this.objectLongitude = this.newLongitude;

        alert('Координаты успешно обновлены.');

        // Перезапускаем карту с новыми координатами
        this.initMap();

        // Скрываем форму после обновления
        this.showCoordsForm = false;
      } catch (error) {
        console.error('Ошибка при обновлении координат:', error);
        alert('Произошла ошибка при обновлении координат.');
      }
    },
    getRowClass(sto_id) {
      if (sto_id === 'mod') return 'highlight-yellow';
      if (sto_id === 'den') return 'highlight-red';
      if (sto_id === 'pub') return 'highlight-green';
      return 'highlight-white';
    },
    getStatusText(object) {
      switch (object.sto_id) {
        case 'mod':
          return 'На модерации';
        case 'draft':
          return 'Черновик';
        case 'den':
          return `Отклонено по причине: ${object.den_reason || 'не указана'}`;
        case 'pub':
          return 'Опубликован';
        default:
          return 'Неизвестный статус';
      }
    },
    goToCreateRoom(id) {
      this.$router.push({ name: 'AddRoom', params: { id } }).then(() => {
        window.scrollTo(0, 0);
      });
    },
    viewRoom(id) {
      this.$router.push({ name: 'ViewRoom', params: { id } }).then(() => {
        window.scrollTo(0, 0);
      });
    },
    editRoom(id) {
      this.$router.push({ name: 'EditRoom', params: { id } }).then(() => {
        window.scrollTo(0, 0);
      });
    },
    async deleteRoom(id) {
      const token = this.$store.getters.token;
      try {
        const response = await axios.patch(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/rom/${id}`,
            { sto_id: 'archive' },
            {
              headers: {
                'Token': token,
                'Content-Type': 'application/json'
              }
            }
        );

        if (response.status === 200) {
          alert('Вариант размещения успешно удален');
          this.fetchRooms();
        }
      } catch (error) {
        console.error('Ошибка при удалении варианта размещения:', error);
        alert('Произошла ошибка при удалении варианта размещения.');
      }
    },
    async sendToModerateRoom(id) {
      const token = this.$store.getters.token;
      try {
        const response = await axios.patch(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/rom/${id}`,
            { sto_id: 'mod' },
            {
              headers: {
                'Token': token,
                'Content-Type': 'application/json'
              }
            }
        );

        if (response.status === 200) {
          alert('Вариант размещения успешно отправлен на модерацию');
          this.fetchRooms(); // Обновление списка объектов после изменения
        }
      } catch (error) {
        console.error('Ошибка при отправке варианта размещения на модерацию:', error);
        alert('Произошла ошибка при отправке варианта размещения на модерацию.');
      }
    },
    initMap() {
      nextTick(() => {
        if (this.map) {
          this.map.remove(); // Удаляем старую карту перед перерисовкой
        }

        if (!this.objectLatitude || !this.objectLongitude) {
          console.error('Координаты объекта отсутствуют');
          return;
        }

        const latitude = parseFloat(this.objectLatitude);
        const longitude = parseFloat(this.objectLongitude);

        if (isNaN(latitude) || isNaN(longitude)) {
          console.error('Некорректные координаты:', latitude, longitude);
          return;
        }

        // Инициализация карты
        this.map = L.map('map', {
          center: [latitude, longitude],
          zoom: 16,
          attributionControl: false
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: 'Data by &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, under <a href="https://opendatacommons.org/licenses/odbl/">ODbL.</a>'
        }).addTo(this.map);

        const customIcon = L.icon({
          iconUrl: require('@/assets/location_map.svg'),
          iconSize: [25, 41],
          iconAnchor: [12, 41],
          popupAnchor: [1, -34]
        });

        L.marker([latitude, longitude], { icon: customIcon }).addTo(this.map)
            .bindPopup(`<strong>${this.objectName}</strong><br>${this.objectLocation}`);
      });
    },
    /*
    initMap() {
      nextTick(() => {
        if (!this.objectLatitude || !this.objectLongitude) {
          console.error('Координаты объекта отсутствуют');
          return;
        }

        const latitude = parseFloat(this.objectLatitude);
        const longitude = parseFloat(this.objectLongitude);

        if (isNaN(latitude) || isNaN(longitude)) {
          console.error('Некорректные координаты:', latitude, longitude);
          return;
        }

        // Инициализация карты с центром на объекте
        this.map = L.map('map', {
          center: [latitude, longitude],  // Координаты Санкт-Петербурга
          zoom: 16,
          zoomAnimation: false,
          attributionControl: false  // Отключаем стандартную атрибуцию
        });

        // Создаем кастомную атрибуцию
        const myAttrControl = L.control.attribution().addTo(this.map);
        myAttrControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');  // Устанавливаем кастомный префикс атрибуции

        // Добавляем слой карт от OpenStreetMap
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          detectRetina: true,
          attribution: 'Data by &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, under <a href="https://opendatacommons.org/licenses/odbl/">ODbL.</a>'
        }).addTo(this.map);

        const customIcon = L.icon({
          iconUrl: require('@/assets/location_map.svg'),  // Путь к вашей иконке маркера
          iconSize: [25, 41], // Размер иконки
          iconAnchor: [12, 41], // Точка привязки иконки (середина внизу)
          popupAnchor: [1, -34], // Точка, откуда будет "выплывать" окно
          // shadowUrl: 'https://your-site.com/path/to/marker-shadow.png',  // Путь к тени маркера (если нужно)
          shadowSize: [41, 41], // Размер тени
        });
        // Добавление маркера на карте
        const marker = L.marker([latitude, longitude], { icon: customIcon }).addTo(this.map);
        console.log(marker);
      });
    },*/
    async fetchObjectData() {
      const token = this.$store.getters.token;
      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/cot/${this.objectId}`, {
          headers: {
            'Token': token
          }
        });
        const object = response.data.data;
        this.objectName = object.nm;
        this.objectLocation = object.location;
        this.objectLatitude = object.latitude;
        this.objectLongitude = object.longitude;
        this.objectPrice = object.price_dec;
        this.objectVideo = object.video;
        this.objectTypeName = this.objectTypes.find(type => type.id === object.obj_id)?.name || 'Неизвестно';
        this.objectDescription = object.descr; // Отформатированный текст
      } catch (error) {
        console.error('Ошибка при загрузке данных объекта:', error);
      }
    },
    async fetchRooms() {
      const token = this.$store.getters.token;
      this.loadingRooms = true;
      try {
        // Получаем токен из Vuex
        const response = await axios.get(
            `https://лк.домшеринг.рф/api/rom?sort=-id&filters[cot_id-e]=${this.objectId}&filters[sto_id-ee]=archive`,
            {
              headers: {
                'Token': token,
                'Content-Type': 'multipart/form-data'
              }
            }
        );
        const rooms = response.data.data;
        this.rooms = [...new Map(rooms.map(item => [item.id, item])).values()];
      } catch (error) {
        console.error('Ошибка при загрузке вариантов размещения:', error);
        this.rooms = []; // Устанавливаем пустой массив в случае ошибки
      } finally {
        this.loadingRooms = false; // Завершаем загрузку
      }
    },
    async fetchPhotos() {
      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/fil?filters[cot_id-e]=${this.objectId}`);
        this.photos = response.data.data; // Загружаем фото в массив
      } catch (error) {
        console.error('Ошибка при загрузке фотографий:', error);
      }
    },
    async loadSelectedActivities() {
      const token = this.$store.getters.token;
      try {
        const response = await axios.get(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/coa?filters[cot_id-e]=${this.objectId}`, {
          headers: {
            'Token': token
          }
        });

        const activityData = response.data.data;
        const activityIds = activityData.map(activity => activity.atv_id);
        this.selectedActivities = this.activities.filter(activity => activityIds.includes(activity.id));
      } catch (error) {
        console.error('Ошибка при загрузке связанных активностей:', error);
      }
    },
    async loadSelectedFacilities() {
      const token = this.$store.getters.token;
      try {
        const response = await axios.get(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/cof?filters[cot_id-e]=${this.objectId}`, {
          headers: {
            'Token': token
          }
        });

        const facilityData = response.data.data;
        const facilityIds = facilityData.map(facility => facility.fac_id);
        this.selectedFacilities = this.facilities.filter(facility => facilityIds.includes(facility.id));
      } catch (error) {
        console.error('Ошибка при загрузке связанных удобств:', error);
      }
    },

  },
  mounted() {
    this.fetchObjectData();
    this.fetchPhotos();
    this.fetchRooms();
    this.$store.dispatch('loadActivities').then(() => {
      this.loadSelectedActivities();
    });
    this.$store.dispatch('loadFacilities').then(() => {
      this.loadSelectedFacilities();
    });
    this.$store.dispatch('loadObjectTypes');
  }
};
</script>

<style scoped>

.map {
  width: 100%;
  height: 300px;
  margin-top: 20px;
}

.btn {
  font-size: 14px;
}

@media (width >= 768px) {
  .btn {
    font-size: 16px;
  }
}

.btn-light {
  gap: 12px;
  width: 190px;
  height: 48px;
}

@media (width >= 768px) {
  .btn-light {
    height: 56px;
  }
}

@media (width >= 768px) {
  .light-wrapper {
    margin: 62px 43px 64px;
  }
}

h2 {
  text-align: center;
  margin: 0;
}

.card-wrapper {
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding: 30px 20px;
}

@media (width >= 768px) {
  .card-wrapper  {
    padding: 64px 80px;
  }
}


.specification {
  padding: 30px 0 0;
}

@media (width >= 768px) {
  .specification {
    padding: 60px 0 0;
  }
}

.cards {
  margin-top: 22px;
}

.comfort-list {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

@media (width > 460px) and (width < 910px) {
  .comfort-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
    gap: 20px;
  }
}


@media (width >= 910px) and (width < 1140px){
  .comfort-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: space-between;
    gap: 20px;
  }
}

@media (width >= 1140px) {
  .comfort-list {
    display: grid;
    justify-content: space-between;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.comfort-item {
  text-align: center;
  display: flex;
  align-items: center;
  gap: 12px;
}


h1 {
  margin-bottom: 40px;
}

.objects {
  margin-top: 64px;
}

.photo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 0 20px;
}

@media (width >= 860px) {
  .photo-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    padding: 0 80px;
  }
}

@media (width >= 1230px) {
  .photo-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (width >= 1600px) {
  .photo-wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}

.photo img {
  width: 280px;
  height: 202px;
}

@media (width >= 768px) {
  .photo img {
    width: 346px;
    height: 250px;
  }
}

.highlight-yellow {
  background-color: #FFFCED;
}

.highlight-red {
  background-color: #FAEAE7;
}

.highlight-green {
  background-color: #CCEBCC;
}

.highlight-white {
  background-color: #ffffff;
}

/* Таблица */
.objects-table {
  width: 100%;
  border: 0px;
  border-collapse: collapse;
  margin-top: 20px;
}

.objects-table thead {
  font-weight: bold;
  background-color: #199057;
  color: #ffffff;
  font-size: 18px;
}

.objects-table td {
  padding: 20px;
  text-align: center;
  border-bottom: 1px solid #e8e9eb;
  border-top: 1px solid #e8e9eb;
  border-right: 1px solid #e8e9eb;
}

.objects-table th {
  padding: 20px;
}

@media screen and (max-width: 950px) {
  .objects-table thead {
    display: none;
  }

  .objects-table tr {
    display: block;
    margin-bottom: 16px;
    border-bottom: 2px solid #D5D6DE;
    border-left: 1px solid #D5D6DE;
    /* background-color: #199057; */
  }

  .objects-table td {
    display: block;
    text-align: right;
  }

  .objects-table td:before {
    content: attr(aria-label);
    float: left;
    font-weight: bold;
  }
}

.objects-table tbody {
  font-size: 18px;
}

@media (width >= 950px) {
  .objects-table tbody {
    border-left: 1px solid #D5D6DE;
  }
}

thead tr:first-child th:first-child { border-top-left-radius: 10px; }
thead tr:first-child th:last-child { border-top-right-radius: 10px; }

tbody tr:last-child td:first-child { border-bottom-left-radius: 10px; }
tbody tr:last-child td:last-child { border-bottom-right-radius: 10px; }

.actions {
  border: none;
  border-right: 1px solid #D5D6DE;
  border-bottom: 1px solid #D5D6DE;
}

.action-icon:hover {
  color: #007bff;
}

.actions-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 14px;
  margin-top: 14px;
}

@media (width >= 950px) {
  .actions-wrapper {
    justify-content: space-around;
  }
}

.edit {
  background-image: url("@/assets/edit.svg");
  background-size: 26px;
  height: 26px;
  width: 26px;
  background-repeat: no-repeat;
}

@media (width >= 950px) {
  .edit {
    background-size: 30px;
    height: 30px;
    width: 30px;
  }
}

.send {
  background-image: url("@/assets/send-2.svg");
  background-size: 26px;
  height: 26px;
  width: 26px;
  background-repeat: no-repeat;
}

@media (width >= 950px) {
  .send {
    background-size: 30px;
    height: 30px;
    width: 30px;
  }
}

.trash {
  background-image: url("@/assets/trash.svg");
  background-size: 24px;
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
}

@media (width >= 950px) {
  .trash {
    background-size: 30px;
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 950px) {
  .objects-table th {
    display: none;
  }
  .objects-table tr {
    display: block;
    margin-bottom: 1rem;
    border-bottom: 2px solid #e8e9eb;
  }
  .objects-table td {
    display: block;
    text-align: right;
  }
  .objects-table td:before {
    content: attr(aria-label);
    float: left;
    font-weight: bold;
  }
}

.objects-specification {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.specification-text {
  margin: 0;
  font-size: 14px;
  line-height: 20px;
}

@media (width >= 768px) {
  .specification-text {
    font-size: 20px;
    line-height: 24px;
  }
}

@media (width >= 1280px) {
  .specification-text {
    font-size: 25px;
    line-height: 30px;
  }
}

.specification-text p {
  margin: 0;
}

pre {
  font-family: Arial, sans-serif; /* Используйте шрифт, поддерживающий эмодзи */
}

.btn-add {
  margin-top: 30px;
  margin-bottom: 0;
}

@media (width >= 768px) {
  .btn-add {
    margin-bottom: 30px;
  }
}

.form-field pre {
  white-space: pre-wrap;      /* Перенос строк */
  word-wrap: break-word;      /* Перенос слов для длинных слов */
  overflow-wrap: break-word;  /* Альтернативный способ для современных браузеров */
  overflow: auto;             /* Добавляет прокрутку при необходимости */
  max-width: 100%;            /* Ограничение по ширине блока */
}


.update-coords-link {
  color: #199057;
  cursor: pointer;
  text-decoration: underline;
  margin: 10px 0;
}

.coords-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.coords-inputs {
  display: flex;
  gap: 10px;
}

.coords-inputs input {
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
}

.btn-update {
  padding: 10px;
  background-color: #199057;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100px;
}

.btn-update:hover {
  background-color: #157a45;
}
</style>