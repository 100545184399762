<template>
  <div class="changes-saved">
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none">
      <path stroke="#199057" stroke-width="2.475" d="M2.656 25.076a5.18 5.18 0 0 0 4.538 4.547c2.947.342 5.996.69 9.119.69 3.122 0 6.171-.348 9.118-.69a5.18 5.18 0 0 0 4.538-4.547c.329-2.933.656-5.968.656-9.076 0-3.108-.327-6.143-.656-9.076a5.18 5.18 0 0 0-4.538-4.547c-2.947-.342-5.996-.69-9.119-.69-3.122 0-6.171.348-9.118.69a5.18 5.18 0 0 0-4.538 4.547C2.327 9.857 2 12.892 2 16c0 3.108.327 6.143.656 9.076Z"/>
      <path stroke="#199057" stroke-linecap="round" stroke-linejoin="round" stroke-width="2.475" d="m10.327 17.171 4.164 4.294c1.96-5.628 3.591-8.098 7.286-11.45"/>
  </svg>
    <p class="changes-text">Изменения сохранены.</p>
  </div>
</template>

<style scoped>
.changes-saved {
  padding: 35px 65px;
  background-color: #CCEBCC;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  border: 2px solid #199057;
}

.changes-text {
  font-size: 18px;
  line-height: 24px;
  margin: 0;
}

</style>