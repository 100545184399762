<template>
  <div class="logo">
    <router-link to="/">
      <img class="logo" src="@/assets/logo.svg" alt="ДомШеринг" />
    </router-link>
  </div>
  <div class="wrapper">
    <PasswordForm />
    <picture>
      <source media="(min-width: 1600px)" srcset="@/assets/password.jpg" width="900"
        height="880">
      <source media="(min-width: 1280px)" srcset="@/assets/password-1280.jpg" width="580"
        height="880">
      <img class="picture" src="@/assets/password-768.jpg" height="700">
    </picture>
  </div>
</template>

<script>
import PasswordForm from './PasswordForm.vue';

export default {
name: 'PasswordPage',
components: {
  PasswordForm
}
}
</script>

<style scoped>

.form {
  top: 30%;
}

.logo {
padding: 10px;
width: 80px;
}

@media (width >= 768px) {
.logo {
  width: 110px;
}
}

@media (width < 1280px) {
.logo {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  justify-content: center;
}
}

@media (width >= 1280px) {
.logo {
  position: absolute;
}
}

.wrapper-logo {
padding: 20px;
}

@media (width >= 768px) {
  .wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
}

@media (width >= 1280px) {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

@media (width < 768px) {
.picture {
  display: none;
}
}

@media (width < 1280px) {
.picture {
  width: 100%;
}
}

@media (width >= 1280px) and (width < 1600px){
.picture {
  width: 580px;
  height: 880px;
}
}

</style>