<template>
  <div>
    <AppHeader />
    <div class="objects">
      <h1>Добавление варианта размещения</h1>
      <RoomFormData
          :roomName="roomName"
          :roomPrice="roomPrice"
          :roomGuests="roomGuests"
          :roomApartments="roomApartments"
          :roomSquare="roomSquare"
          :roomPets="roomPets"
          :roomBreakfast="roomBreakfast"
          @update:roomName="roomName = $event"
          @update:roomPrice="roomPrice = $event"
          @update:roomGuests="roomGuests = $event"
          @update:roomApartments="roomApartments = $event"
          @update:roomSquare="roomSquare = $event"
          @update:roomPets="roomPets = $event"
          @update:roomBreakfast="roomBreakfast = $event"
      />
    </div>

    <TextAreaField
        label="Описание варианта размещения"
        id="room-description"
        v-model="roomDescription"
        placeholder="Введите описание"
        :maxLength="65535"
    />


    <PhotoLoad @file-selected="handleFileSelection" />
    <div class="photo-wrapper">
      <div class="photo" v-for="(photo, index) in selectedFiles" :key="photo.id || index">
        <img :src="photo.preview" alt="photo" />
        <button @click="removeFile(index)" class="delete-button">✖</button>
      </div>
    </div>


    <button class="btn" @click="saveRoom">Сохранить</button>

    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <div v-if="isLoading">
          <p>Создание варианта размещения...</p>
          <div class="spinner"></div>
        </div>
        <div v-else>
          <p>Вариант размещения успешно создан!</p>
          <button class="btn" @click="closeModal">ОК</button>
        </div>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import PhotoLoad from './PhotoLoad.vue';
import { ref } from 'vue';
import axios from "axios";
import TextAreaField from "@/components/TextAreaField.vue";
import RoomFormData from './RoomFormData.vue'

export default {
  name: 'AddRoom',
  components: {
    TextAreaField,
    AppHeader,
    AppFooter,
    PhotoLoad,
    RoomFormData
  },
  data() {
    return {
      objectId: this.$route.params.id,
      roomName: '',
      roomDescription: '',
      roomPrice: null,
      roomGuests: null,
      roomApartments: null,
      roomSquare: null,
      roomPets: null,
      roomBreakfast: null,
      selectedFiles: [],
      activities: ref([]),
      userName: '',
      userPhone: '',
      userEmail: '',
      userLocation: '',
      originalUserData: {},
      suggestions: [],  // Подсказки Dadata
      errorMessage: '',
      showModal: false,
      isLoading: false
    };
  },
  computed: {
    isFormValid() {
      return this.isDataChanged && this.validateFields();
    },
    isDataChanged() {
      return (
          this.userName !== this.originalUserData.nm ||
          this.userPhone !== this.originalUserData.phone ||
          this.userEmail !== this.originalUserData.mail ||
          this.userLocation !== this.originalUserData.remark_tx
      );
    }
  },
  methods: {
    handleFileSelection(fileData) {
      this.selectedFiles.push(fileData); // Добавляем полученные файлы в массив
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1); // Удаляем файл из массива по индексу
    },
    closeModal() {
      this.showModal = false;
      const id = this.objectId
      this.$router.push({ name: 'ViewObject', params: { id } }).then(() => {
        this.$nextTick(() => {
          const target = document.getElementById('rooms');
          if (target) {
            target.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        });
      });
    },
    async createFileRecord(rom_id, filename) {
      const token = this.$store.getters.token;
      const userId = this.$store.getters.userId;
      const fileData = {
        nm: filename,
        own_usr_id: userId,
        rom_id: rom_id
      };
      try {
        const response = await axios.post(
            'https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/fil',
            fileData,
            {
              headers: {
                'Token': token,
                'Content-Type': 'application/json'
              }
            }
        );
        return response.data.data.id;
      } catch (error) {
        console.error('Ошибка при создании записи в fil:', error);
        throw error;
      }
    },
    async uploadFileToServer(file, fileId) {
      const token = this.$store.getters.token;
      try {
        const formData = new FormData();
        formData.append('up', file);

        await axios.post(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/upload/${fileId}`,
            formData,
            {
              headers: {
                'Token': token,
                'Content-Type': 'multipart/form-data'
              }
            }
        );

      } catch (error) {
        console.error('Ошибка при загрузке файла:', error);
      }
    },
    async saveRoom() {
      this.showModal = true;
      this.isLoading = true;
      try {
        const token = this.$store.getters.token;

        // 1. Сохранение объекта
        const roomData = {
          nm: this.roomName,
          descr: this.roomDescription,
          price_dec: this.roomPrice,
          num_of_guests: this.roomGuests,
          num_of_rooms: this.roomApartments,
          with_breakfast_is: this.roomBreakfast,
          with_pets_is: this.roomPets,
          square: this.roomSquare,
          cot_id: this.objectId
        };

        const roomResponse = await axios.post('https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/rom', roomData, {
          headers: {
            'Token': token,
            'Content-Type': 'application/json'
          }
        });

        const roomId = roomResponse.data.data.id;

        for (const fileData of this.selectedFiles) {
          const fileId = await this.createFileRecord(roomId, fileData.name);
          await this.uploadFileToServer(fileData.file, fileId);
        }

        this.isLoading = false;
      } catch (error) {
        console.error('Ошибка при сохранении варианта размешения:', error);
        alert('Произошла ошибка при сохранении варианта размешения.');
        this.isLoading = false;
        this.showModal = false;
      }
    }
  }
};
</script>

<style scoped>

h1 {
  margin-bottom: 40px;
}

.objects {
  margin-top: 64px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.photo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 30px 20px;
}

@media (width >= 640px) {
  .photo-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}

@media (width >= 1000px) {
  .photo-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}


@media (width >= 1440px) {
  .photo-wrapper {
    padding: 40px 20px;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px 80px;
  }
}

.photo {
  position: relative;
}

.photo img {
  width: 280px;
  object-fit: cover;
}

.btn {
  width: 295px;
  margin: 40px auto;
}

.photo img {
  width: 280px;
  height: 202px;
  object-fit: cover;
}

@media (width >= 768px) {
  .photo img {
    width: 346px;
    height: 250px;
  }
}

.delete-button {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: 2px solid #222222;
  border-radius: 5px;
  background-image: url("@/assets/cross.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: -13px;
  left: 0;
  cursor: pointer;
}


</style>