import { createRouter, createWebHistory } from 'vue-router';
import MainPage from '@/components/MainPage.vue';
import AboutPage from '@/components/AboutPage.vue';
import ContactsPage from '@/components/ContactsPage.vue';
import NotAuthorized from '@/components/NotAuthorized.vue';
import Options from '@/components/Options.vue';
import ViewCard from '@/components/ViewCard.vue';
import PhotosBlock from '@/components/PhotosBlock.vue';
import LoginPage from '@/components/LoginPage.vue';
import LoginList from '@/components/LoginForm.vue';
import ReservationsPage from '@/components/ReservationsPage.vue';
import ReservationsList from '@/components/ReservationsList.vue';
import ReservationsCard from '@/components/ReservationsCard.vue';
import RegistrationPage from '@/components/RegistrationPage.vue';
import RegistrationList from '@/components/RegistrationForm.vue';
import PasswordPage from '@/components/PasswordPage.vue';
import PasswordList from '@/components/PasswordForm.vue';
import TenantProfile from '@/components/TenantProfile.vue';
import ComfortList from '@/components/ComfortList.vue';
import BookingInfo from '@/components/BookingInfo.vue';
import NotificationOk from '@/components/NotificationOk.vue';
import NotificationError from '@/components/NotificationError.vue';
import PhotoLoad from '@/components/PhotoLoad.vue';
import AddReservations from '@/components/AddReservations.vue';


//Проверено
import RentalProfile from '@/components/RentalProfile.vue';
import RentalObjectsPage from '@/components/RentalObjectsPage.vue';
import RentalBookingPage from '@/components/RentalBookingPage.vue';
import AddObject from '@/components/AddObject.vue';
import EditObject from '@/components/EditObject.vue';
import ViewObject from '@/components/ViewObject.vue';
import AddRoom from '@/components/AddRoom.vue';
import ViewRoom from '@/components/ViewRoom.vue';
import EditRoom from '@/components/EditRoom.vue';
import ReserveObjectPage from '@/components/ReserveObjectPage.vue';
import TenantBookingPage from '@/components/TenantBookingPage.vue';
import ResetPasswordPage from '@/components/ResetPasswordPage.vue';
import ConfirmRegisterPage from '@/components/ConfirmRegisterPage.vue';


const routes = [
  {
    path: '/',
    name: 'Home',
    component: MainPage
  },
  {
    path: '/about',
    name: 'About',
    component: AboutPage
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ContactsPage
  },
  {
    path: '/not_authorized',
    name: 'NotAuthorized',
    component: NotAuthorized 
  },
  {
    path: '/options',
    name: 'Options',
    component: Options
  },
  {
    path: '/view_card',
    name: 'ViewCard',
    component: ViewCard
  },
  {
    path: '/object/:id',  // Динамический маршрут для страницы объекта
    name: 'ViewCard',
    component: ViewCard,
    props: true  // Пробрасываем параметры маршрута как props в компонент
  },
  {
    path: '/photos_block',
    name: 'PhotosBlock',
    component: PhotosBlock
  },
  {
    path: '/login',
    name: 'LoginPage',
    component: LoginPage
  },
  {
    path: '/login_list',
    name: 'LoginList',
    component: LoginList
  },
  {
    path: '/reservations',
    name: 'ReservationsPage',
    component: ReservationsPage
  },
  {
    path: '/reservations_list',
    name: 'ReservationsList',
    component: ReservationsList
  },
  {
    path: '/profile',
    name: 'TenantProfile',
    component: TenantProfile
  },
  {
    path: '/registration_list',
    name: 'RegistrationList',
    component: RegistrationList
  },
  {
    path: '/registration',
    name: 'RegistrationPage',
    component: RegistrationPage
  },
  {
    path: '/password_list',
    name: 'PasswordList',
    component: PasswordList
  },
  {
    path: '/password',
    name: 'PasswordPage',
    component: PasswordPage
  },
  {
    path: '/reservations_card/:id',
    name: 'ReservationsCard',
    component: ReservationsCard,
    props: true
  },
  {
    path: '/reserve_object/:id',
    name: 'ReserveObjectPage',
    component: ReserveObjectPage,
    props: true
  },
  {
    path: '/bookinginfo/:id',
    name: 'BookingInfo',
    component: BookingInfo,
    props: true
  },
  {
    path: '/comfort_list',
    name: 'ComfortList',
    component: ComfortList,
  },
  {
    path: '/add_object',
    name: 'AddObject',
    component: AddObject,
  },
  {
    path: '/edit_object/:id',
    name: 'EditObject',
    component: EditObject,
    props: true
  },
  {
    path: '/view_object/:id',
    name: 'ViewObject',
    component: ViewObject,
    props: true
  },
  {
    path: '/notification_ok',
    name: 'NotificationOk',
    component: NotificationOk,
  },
  {
    path: '/notification_error',
    name: 'NotificationError',
    component: NotificationError,
  },
  {
    path: '/photo_load',
    name: 'PhotoLoad',
    component: PhotoLoad,
  },
  {
    path: '/add_room/:id',
    name: 'AddRoom',
    component: AddRoom,
    props: true
  },
  {
    path: '/view_room/:id',
    name: 'ViewRoom',
    component: ViewRoom,
    props: true
  },
  {
    path: '/edit_room/:id',
    name: 'EditRoom',
    component: EditRoom,
    props: true
  },
  {
    path: '/add_reservations',
    name: 'AddReservations',
    component: AddReservations,
  },
  {
    path: '/myobjects',
    name: 'RentalObjectsPage',
    component: RentalObjectsPage,
  },
  {
    path: '/myreservations',
    name: 'RentalBookingPage',
    component: RentalBookingPage,
  },
  {
    path: '/rentalprofile',
    name: 'RentalProfile',
    component: RentalProfile,
  },
  {
    path: '/mybooking',
    name: 'TenantBookingPage',
    component: TenantBookingPage,
  },
  {
    path: '/reset-pass',
    name: 'ResetPasswordPage',
    component: ResetPasswordPage,
    meta: {
      title: 'Сброс пароля',
    },
  },
  {
    path: '/register-confirm',
    name: 'ConfirmRegisterPage',
    component: ConfirmRegisterPage,
    meta: {
      title: 'Подтверждение регистрации',
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;