<template>
  <div class="shadow">
    <div id="app">

      <!-- Здесь будет отображаться текущая страница -->
      <router-view />
    </div>
  </div>
</template>

<script>


export default {
  name: 'App',
  components: {

  },
  created () {
    document.title = "ДомШеринг";
  }
}
</script>

<style>
body {
  margin: 0 auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1600px;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  background-color: #ffffff;
  font-weight: 300;
}

@media (width >= 768px) {
  body {
    font-size: 20px;
    line-height: 24px;
    } 
  }

@media (width >= 1280px) {
  body {
    font-size: 25px;
    line-height: 30px;
    }
  }

.shadow {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 20px rgba(7, 65, 115, 0.15);
}

h1 {
  font-size: 25px;
  margin: 0;
  line-height: 30px;
}

@media (width < 768px) {
  h1 {
    text-align: center;
  } 
}

@media (width >= 768px) {
  h1 {
    font-size: 40px;
    line-height: 50px;
  } 
}

@media (width >= 1440px) {
  h1 {
    font-size: 50px;
    line-height: 60px;
  }
}

h2 {
  font-size: 20px;
  line-height: 24px;
  margin: 0 0 30px;
  text-align: center;
}

@media (width >= 768px) {
  h2 {
    font-size: 30px;
    line-height: 36px;
    margin: 0 0 40px;
    text-align: left;
  } 
}

@media (width >= 1440px) {
  h2 {
    font-size: 36px;
    line-height: 44px;
    margin: 0 0 50px;
  } 
}

h3 {
  font-size: 16px;
  margin: 0;
  margin-bottom: 14px;
}

@media (width >= 768px) {
  h3 {
    font-size: 24px;
    margin-bottom: 22px;
  } 
}

@media (width >= 1440px) {
  h3 {
    font-size: 26px;
  }
}

.h3-description {
  margin: 0;
  font-size: 16px;
  line-height: 24px;
}

@media (width >= 768px) {
  .h3-description {
    font-size: 20px;
    line-height: 28px;
    }
  }

@media (width >= 1440px) {
  .h3-description {
    font-size: 22px;
    line-height: 30px;
    }
  }

.description {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
  margin: 0;
}

@media (width >= 768px) {
  .description {
    font-size: 24px;
    line-height: 30px;
    margin-top: 16px;
    } 
  }

@media (width >= 1440px) {
  .description {
    font-size: 28px;
    line-height: 36px;
    }
  }

.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0;
  flex-direction: column;
  /* gap: 16px; */
}

@media (width >= 768px) {
  .hero {
    gap: 30px;
    flex-direction: row;
  } 
}

@media (width < 768px) {
  .hero-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}

li {
  list-style: none;
}

#app {
  font-family: Hind Madurai, Cabin, sans-serif;
  /* text-align: center; */
  background: #FDFEFA;
}

.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 147px;
  height: 48px;
  padding: 16px 32px;
  background-color: #199057;
  color: white;
  border: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  cursor: pointer;
  gap: 8px;
  border-radius: 10px;
  opacity: 1;
  transition: background-color 0.3s ease;
}

@media (width < 768px) {
  .btn {
    width: 100%;
  } 
}

@media (width >= 768px) {
  .btn {
    font-size: 16px;
    line-height: 24px;
    height: 56px;
  } 
}

@media (width >= 1440px) {
  .btn {
    font-size: 18px;
    line-height: 30px;
  } 
}

.btn:hover {
  background-color: #157a45;
}

.btn:disabled {
  background-color: #EAEAEA; /* Цвет для неактивной кнопки */
  color: #D5D6DE;
  cursor: not-allowed; /* Курсор для неактивной кнопки */
}

.btn-light {
    background-color: white;
    color: #199057;
    border: 2px solid #199057;
  }

.light-wrapper {
  background: #CFE89F;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  margin: -30px 0px 50px;
}

@media (width >= 768px) {
  .light-wrapper {
    margin: -20px 43px 64px;
  } 
}

.dark-wrapper {
  background: #2D2D2D;
  width: 100%;
  padding: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  margin: 50px;
}

.objects {
  background: #FFFFFF;
  box-shadow: 4px 4px 20px 0px #0000000D;
  border-radius: 10px;
  padding: 30px 20px;
  margin-top: 20px;
}

@media (width < 768px) {
  .objects {
    display: flex;
    flex-direction: column;
    align-items: center;
  } 
}

@media (width >= 768px) {
  .objects {
    margin: 0 43px 64px;
    padding: 40px 37px;
  } 
}

.btn-add {
  width: 100%;
  margin-bottom: 30px;
}

@media (width >= 768px) {
  .btn-add {
    width: 230px;
  } 
}

.btn-delete {
  background-color: #DF3939;
}

@media (width < 550px) {
  .checkbox-wrapper {
    margin-right: auto;
  }
}

.checkbox-list {
  display: flex;
  gap: 10px;
  flex-direction: column;
  padding: 0;
  width: 100%;
}

@media (width >= 550px) {
  .checkbox-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
  }

@media (width >= 800px) {
  .checkbox-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
  }

@media (width >= 1100px) {
  .checkbox-list {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
    }
  }

  @media (width >= 1280px) {
  .checkbox-list {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
    }
  }

@media (width < 1440px) {
  .checkbox-list {
    margin-bottom: 10px;
  }
}

.checkbox-text {
  font-size: 14px;
  line-height: 20px;
  }

@media (width >= 768px) {
  .checkbox-text {
    font-size: 14px;
    line-height: 20px;
  }
}

@media (width >= 1440px) {
  .checkbox-text {
    font-size: 16px;
    line-height: 24px;
  }
}

.checkbox-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  height: 24px;
  width: 24px;
}

/* .checkbox-item {
  width: 24px;
  height: 24px;
  display: flex;
} */

.checkbox-item:not(last-child) {
  padding-bottom: 12px;
}


.checkbox-input+label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  width: 200px;;
  position: relative;
  vertical-align: middle;
  padding: 0 0 0 40px;
  cursor: pointer;
}

/* Чекбокс в состоянии неактивен */
.checkbox-input+label::before {
  content: '';
  position: absolute;
  top: -1px;
  left: 0;
  width: 24px;
  height: 24px;
  border: 1px solid #D5D6DE;
  border-radius: 4px;
}

/* Чекбокс в состоянии активен */
.checkbox-input + label:after {
    content: '';
    position: absolute;
}

/* Фон чекбокса в состоянии активен */
.checkbox-input:checked + label:after {
    position: absolute;
    top: -1px;
    left: 0;
    border-radius: 4px;
    border: 1px solid #199057;
    height: 26px;
    width: 26px;
    border-color: #199057;
    background-color: #199057;
    background-image: url("@/assets/check.svg");
    background-size: 24px 14px;
    background-repeat: no-repeat;
    background-position: center;
}

/* стили при наведении курсора на checkbox */
/* .checkbox-input:not(:disabled):not(:checked)+label:hover::before {
  border-color: #199057;
  background-color: #199057;
  background-image: url("@/assets/check.svg");
} */

/* стили для чекбокса, находящегося в фокусе */
/* .checkbox-input:focus+label::before {
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
} */
/* стили для чекбокса, находящегося в фокусе и не находящегося в состоянии checked */
/* .checkbox-input:focus:not(:checked)+label::before {
  border-color: #80bdff;
} */
/* стили для чекбокса, находящегося в состоянии disabled */
.checkbox-input:disabled+label::before {
  background-color: #D5D6DE;
  border: 1px solid #D5D6DE;
}

/* Плашки при сохранении информации */
.changes-saved {
  padding: 35px 65px;
  background-color: #CCEBCC;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
}

.changes-text {
  font-size: 18px;
  line-height: 24px;
}

.error {
  background-color: #FAEAE7;
}

/* Общие стили для полей формы */
.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.form-field-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  gap: 16px;
  align-items: flex-start;
}

@media (width >= 768px) and (width < 1280px) {
  .form-field-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  } 
}


@media (width >= 1280px) {
  .form-field-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  } 
}

.form-field label {
  margin-bottom: 8px;
}

.form-field input,
.form-field select {
  padding: 18px 20px;
  border: 1px solid #D5D6DE;
  border-radius: 10px;
  background: #FFFFFF;
  font-size: 16px;
}

@media (width < 768px) {
  .form-field input {
    font-size: 12px;
  }
}

.form-field input:focus {
  border: 1px solid #199057;
}

.form-field input:focus {
  outline: none;
}


.form {
  align-items: center;
  justify-content: center;
}

@media (width < 768px) {
  .form {
    width: 100%;
  } 
}

.form {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.text {
  font-size: 16px;
  font-weight: 400;
  color: #686868;
  margin: 0;
  margin-bottom: 6px;
}

.hint {
  font-size: 12px;
  color: #888;
  margin: 0;
  margin-top: 5px;
}

/* Таблица */
.objects-table {
  width: 100%; 
  border: 0px; 
  border-collapse: collapse; 
  margin-top: 20px;
}

.objects-table thead {
  font-weight: bold; 
  background-color: #199057; 
  color: #ffffff; 
  font-size: 18px;
}

.objects-table td {
  padding: 20px; 
  text-align: center; 
  border-bottom: 1px solid #e8e9eb;
  border-top: 1px solid #e8e9eb;
  border-right: 1px solid #e8e9eb;
}

.objects-table th {
  padding: 20px; 
}

@media screen and (max-width: 950px) {
  .objects-table thead {
    display: none;
  }

  .objects-table tr {
    display: block;
    margin-bottom: 16px;
    border-bottom: 2px solid #D5D6DE;
    border-left: 1px solid #D5D6DE;
    background-color: #199057;
  }

  .objects-table td {
    display: block;
    text-align: right;
  }

  .objects-table td:before {
    content: attr(aria-label);
    float: left;
    font-weight: bold;
  }
}

.objects-table tbody {
  font-size: 18px;
}

@media (width >= 950px) {
  .objects-table tbody {
    border-left: 1px solid #D5D6DE;
  }
}

thead tr:first-child th:first-child { border-top-left-radius: 10px; }
thead tr:first-child th:last-child { border-top-right-radius: 10px; }

tbody tr:last-child td:first-child { border-bottom-left-radius: 10px; }
tbody tr:last-child td:last-child { border-bottom-right-radius: 10px; }

.actions {
  border: none;
  border-right: 1px solid #D5D6DE;
  border-bottom: 1px solid #D5D6DE;
}

.action-icon:hover {
  color: #007bff;
}

.actions-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 14px;
  margin-top: 14px;
}

@media (width >= 950px) {
  .actions-wrapper {
    justify-content: space-around;
  }
}

.edit {
  background-image: url("@/assets/edit.svg");
  background-size: 26px;
  height: 26px;
  width: 26px;
  background-repeat: no-repeat;
}

@media (width >= 950px) {
  .edit {
    background-size: 30px;
    height: 30px;
    width: 30px;
  }
}

.send {
  background-image: url("@/assets/send-2.svg");
  background-size: 26px;
  height: 26px;
  width: 26px;
  background-repeat: no-repeat;
}

@media (width >= 950px) {
  .send {
    background-size: 30px;
    height: 30px;
    width: 30px;
  }
}

.trash {
  background-image: url("@/assets/trash.svg");
  background-size: 24px;
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
}

@media (width >= 950px) {
  .trash {
    background-size: 30px;
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 950px) {
  .objects-table th {
    display: none;
  }
  .objects-table tr {
    display: block;
    margin-bottom: 1rem;
    border-bottom: 2px solid #e8e9eb;
  }
  .objects-table td {
    display: block;
    text-align: right;
  }
  .objects-table td:before {
    content: attr(aria-label);
    float: left;
    font-weight: bold;
  }
}


/* Стили для списка подсказок DaData */
.suggestions-list {
  border: 1px solid #D5D6DE;
  border-top: none;
  list-style: none;
  padding: 0;
  margin: 0;
  max-height: 200px;
  overflow-y: auto;
  font-size: 16px;
  position: absolute;
  background-color: #ffffff;
  width: 99.6%;
  margin-top: 64px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  z-index: 10;
}

@media (width >= 768px) {
  .suggestions-list {
    margin-top: 70px;
    width: 99.4%;
  } 
}

@media (width >= 892px) {
  .suggestions-list {
    margin-top: 70px;
    width: 99.5%;
  } 
}

@media (width >= 1075px) {
  .suggestions-list {
    margin-top: 77px;
    width: 99.5%;
  } 
}


@media (width < 768px) {
  .suggestions-list {
    font-size: 12px;
  }
}

.suggestions-list li {
  padding: 6px 8px;
  cursor: pointer;
}

.suggestions-list li:hover {
  background-color: #f0f0f0;
}

.form-field-address {
  position: relative;
}

/* Сообщение об ошибке */
.error-message {
  color: red;
  margin: 0;
  font-size: 14px;
}

@media (width >= 768px) {
  .error-message {
    margin-top: auto;
  }
}

</style>



