<template>
  <form class="base-form" @submit.prevent="$emit('submit')">
    <h2 class="form-title">{{ title }}</h2>
    <slot />
  </form>
</template>

<script>
export default {
  name: 'BaseForm',
  props: {
    title: {
      type: String,
      required: true, // Заголовок формы
    },
  },
};
</script>

<style scoped>
.base-form {
  background-color: #FAFDFC;
  padding: 0 20px;
}

@media (width >= 768px) {
  .form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

@media (width >= 768px) and (width < 1280px) {
  .base-form {
    padding: 50px 98px;
    border-radius: 10px;
    position: absolute;
  }
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

</style>