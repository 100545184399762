<template>
  <BaseForm @submit="registerUser" title="Регистрация">
    <!-- Поля формы -->
    <FormField
        v-model="name"
        label="Имя"
        placeholder="Введите ваше имя"
        :isValid="isNameValid"
        errorMessage="Имя обязательно для заполнения."
        :showErrors="showErrors"
    />
    <FormField
        v-model="email"
        label="Email"
        placeholder="Введите ваш email"
        type="email"
        :isValid="isEmailValid"
        errorMessage="Введите корректный email."
        :showErrors="showErrors"
    />
    <FormField
        v-model="phone"
        label="Телефон"
        placeholder="Введите ваш телефон"
        :isValid="isPhoneValid"
        errorMessage="Введите корректный телефон в формате +7XXXXXXXXXX."
        :showErrors="showErrors"
    />
    <FormField
        v-model="password"
        label="Пароль"
        placeholder="Введите пароль"
        type="password"
        :isValid="isPasswordValid"
        errorMessage="Пароль обязателен для заполнения."
        :showErrors="showErrors"
    />
    <FormField
        v-model="passwordConfirmation"
        label="Подтвердите пароль"
        placeholder="Повторите пароль"
        type="password"
        :isValid="isPasswordConfirmationValid"
        errorMessage="Пароли не совпадают."
        :showErrors="showErrors"
    />
    <FormField
        v-model="registrationType"
        label="Зарегистрироваться как"
        type="select"
        :isValid="isRegistrationTypeValid"
        errorMessage="Пожалуйста, выберите роль."
        :showErrors="showErrors"
    >
      <option disabled value="">Выберите роль</option>
      <option value="buy">Арендатор</option>
      <option value="pay">Арендодатель</option>
    </FormField>

    <!-- Ошибки -->
    <ErrorMessage v-if="errorMessage" :message="errorMessage" />

    <!-- Кнопки -->
    <div class="btn-wrapper">
      <BaseButton type="submit" :disabled="isLoading">Зарегистрироваться</BaseButton>
      <BaseButton variant="light" @click="$router.push({ name: 'LoginPage' })">Войти</BaseButton>
    </div>

    <!-- Модальное окно загрузки -->
    <BaseModal v-if="isLoading" @close="cancelRegistration">
      <template #header>
        Регистрация
      </template>
      <template #body>
        <div class="spinner-wrapper">
          <div class="spinner"></div>
          <p>Регистрация в процессе...</p>
        </div>
      </template>
    </BaseModal>

    <!-- Успешное сообщение -->
    <BaseModal v-if="showSuccessMessage" @close="redirectToLogin">
      <template #header>
        Регистрация успешно завершена
      </template>
      <template #body>
        <p>Письмо для подтверждения регистрации направлено Вам на Email.</p>
      </template>
      <template #footer>
        <BaseButton @click="redirectToLogin">ОК</BaseButton>
      </template>
    </BaseModal>
  </BaseForm>
</template>

<script>
import BaseForm from '@/components/BaseForm.vue';
import FormField from '@/components/FormField.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseModal from '@/components/BaseModal.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import axios from 'axios';

export default {
  components: {
    BaseForm,
    FormField,
    BaseButton,
    BaseModal,
    ErrorMessage,
  },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      password: '',
      passwordConfirmation: '',
      registrationType: '',
      errorMessage: '',
      showErrors: false,
      isLoading: false,
      showSuccessMessage: false,
    };
  },
  computed: {
    isNameValid() {
      return this.name.trim() !== '';
    },
    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
    isPhoneValid() {
      const phoneRegex = /^\+7\d{10}$/;
      return phoneRegex.test(this.phone);
    },
    isPasswordValid() {
      return this.password.trim() !== '';
    },
    isPasswordConfirmationValid() {
      return this.password === this.passwordConfirmation;
    },
    isRegistrationTypeValid() {
      return this.registrationType !== '';
    },
    isFormValid() {
      return (
          this.isNameValid &&
          this.isEmailValid &&
          this.isPhoneValid &&
          this.isPasswordValid &&
          this.isPasswordConfirmationValid &&
          this.isRegistrationTypeValid
      );
    },
  },
  methods: {
    cancelRegistration() {
      // Здесь вы можете добавить логику отмены
      this.isLoading = false;
    },
    redirectToLogin() {
      this.$router.push({ name: "LoginPage" });
    },
    async registerUser() {
      if (!this.isFormValid) {
        this.showErrors = true;
        return;
      }

      this.isLoading = true;

      try {
        const payload = {
          mail: this.email,
          login: this.email,
          phone: this.phone,
          nm: this.name,
          grp_id: this.registrationType,
          pass: this.password,
        };

        const response = await axios.post(
            "https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/register",
            payload,
            {
              headers: { "Content-Type": "application/json" }
            }
        );

        if (response.status === 200) {
          this.isLoading = false;
          this.showSuccessMessage = true;
        }
      } catch (error) {
        this.isLoading = false;
        if (error.response && error.response.data) {
          this.errorMessage = error.response.data.errors?.[0]?.title || "Ошибка регистрации. Попробуйте снова.";
        } else {
          this.errorMessage = "Ошибка сети. Проверьте подключение к интернету.";
        }
      }
    }
  },
};
</script>

<style scoped>
.btn-wrapper {
  width: 100%;
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.spinner-wrapper {
  text-align: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3; /* Светлая часть */
  border-top: 4px solid #3498db; /* Синяя часть */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>

