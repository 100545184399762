<template>
  <div>
    <AppHeader />
    <div class="objects">
        <h1>Мои бронирования </h1>
      <ReservationsList />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import ReservationsList from './ReservationsList.vue';


export default {
  name: 'ReservationsPage',
  components: {
    AppHeader,
    AppFooter,
    ReservationsList
  }
}
</script>

<style scoped>


h1 {
  margin-bottom: 40px;
}
.hero {
  padding: 50px 20px 0;
  flex-direction: column;
}

@media (width >= 768px) {
  .hero {
    padding: 80px 70px 0 44px;
    flex-direction: row;
  } 
}

@media (width >= 1440px) {
  .hero {
    padding: 64px 80px 0;
  } 
}

@media (width >= 768px) {
  .objects-img {
    width: 300px;
    height: 186px;
  } 
}

@media (width >= 1440px) {
  .objects-img {
    width: 386px;
    height: 240px;
  } 
}

@media (width < 768px) {
  .hero-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.objects {
  margin-top: 64px;
}
</style>