<template>
  <BaseForm @submit="submitNewPassword" title="Введите новый пароль">
    <!-- Поле нового пароля -->
    <FormField
        v-model="newPassword"
        label="Новый пароль"
        placeholder="Введите новый пароль"
        type="password"
        :isValid="isPasswordValid"
        errorMessage="Пароль должен быть не менее 6 символов."
        :showErrors="showErrors"
    />

    <!-- Поле подтверждения пароля -->
    <FormField
        v-model="passwordConfirmation"
        label="Подтвердите пароль"
        placeholder="Повторите новый пароль"
        type="password"
        :isValid="isPasswordConfirmationValid"
        errorMessage="Пароли не совпадают."
        :showErrors="showErrors"
    />

    <!-- Ошибки -->
    <ErrorMessage v-if="errorMessage" :message="errorMessage" />

    <!-- Кнопки -->
    <div class="btn-wrapper">
      <BaseButton type="submit" :disabled="!isFormValid || isLoading">
        {{ isLoading ? "Сохранение..." : "Сохранить пароль" }}
      </BaseButton>
    </div>

    <!-- Модальное окно -->
    <BaseModal v-if="isLoading || successMessage" @close="handleModalClose">
      <template #header>
        Сброс пароля
      </template>
      <template #body>
        <div v-if="isLoading" class="spinner-wrapper">
          <div class="spinner"></div>
          <p>Сохраняем новый пароль...</p>
        </div>
        <div v-else>
          <p>Пароль успешно обновлён. Теперь вы можете войти.</p>
        </div>
      </template>
      <template #footer v-if="!isLoading">
        <BaseButton @click="redirectToLogin">Ок</BaseButton>
      </template>
    </BaseModal>
  </BaseForm>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";
import FormField from "@/components/FormField.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import axios from "axios";

export default {
  name: "ResetPasswordForm",
  components: {
    BaseForm,
    FormField,
    BaseButton,
    BaseModal,
    ErrorMessage,
  },
  props: {
    login: { type: String, required: true },
    code: { type: String, required: true },
  },
  data() {
    return {
      newPassword: "", // Новый пароль
      passwordConfirmation: "", // Подтверждение пароля
      errorMessage: "", // Сообщение об ошибке
      successMessage: false, // Указывает на успешный результат
      showErrors: false, // Показать ошибки валидации
      isLoading: false, // Состояние загрузки
    };
  },
  computed: {
    // Валидация нового пароля
    isPasswordValid() {
      return this.newPassword.length >= 6;
    },
    // Валидация подтверждения пароля
    isPasswordConfirmationValid() {
      return this.newPassword === this.passwordConfirmation;
    },
    // Общая валидность формы
    isFormValid() {
      return this.isPasswordValid && this.isPasswordConfirmationValid;
    },
  },
  methods: {
    async submitNewPassword() {
      if (!this.isFormValid) {
        this.showErrors = true;
        return;
      }

      this.isLoading = true;
      this.errorMessage = "";
      this.successMessage = false;

      try {
        const response = await axios.get(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/resetpass`,
            {
              params: {
                login: this.login,
                code: this.code,
                pass: this.newPassword,
              },
            }
        );

        if (response.status === 200) {
          this.successMessage = true;
        } else {
          this.errorMessage = "Ошибка обновления пароля. Проверьте данные и попробуйте снова.";
        }
      } catch (error) {
        this.errorMessage = "Произошла ошибка сети. Проверьте подключение к интернету.";
        console.error("Ошибка обновления пароля:", error);
      } finally {
        this.isLoading = false;
      }
    },
    redirectToLogin() {
      this.$router.push({ name: "LoginPage" });
    },
    handleModalClose() {
      if (this.successMessage) {
        this.redirectToLogin();
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Спиннер */
.spinner-wrapper {
  text-align: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3; /* Светлая часть */
  border-top: 4px solid #3498db; /* Синяя часть */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-wrapper {
  margin-top: 20px;
}
</style>