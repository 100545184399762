<template>
  <button
      :class="['btn', { 'btn-light': variant === 'light' }]"
      :disabled="disabled"
      @click="$emit('click')"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    variant: { type: String, default: 'default' },
    disabled: { type: Boolean, default: false },
  },
};
</script>

<style scoped>
.btn {
  width: 100%;
  font-size: 16px;
  height: 52px;
}
</style>