<template>
  <div>
    <AppHeader />
    <div class="objects">
      <h1>Мои объекты</h1>
      <button class="btn btn-add" @click="goToCreateObject()" >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
          <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3.515v16.97M3.515 12h16.97"/>
        </svg>
        Добавить
      </button>
      <!-- <button class="btn btn-add" >
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
            <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 3.515v16.97M3.515 12h16.97"/>
          </svg>
          Добавить
        </button> -->
      <p v-if="isLoading">Загрузка объектов...</p>
      <table v-else-if="objects.length" class="objects-table">
        <thead>
        <tr>
          <th>ID</th>
          <th>Название</th>
          <th>Адрес</th>
          <th>Цена</th>
          <th>Статус</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="object in objects" :key="object.id" :class="getRowClass(object.sto_id)" @click="viewObject(object.id)" style="cursor: pointer;">
          <td aria-label="ID">{{ object.id }}</td>
          <td aria-label="Название">{{ object.nm }}</td>
          <td aria-label="Адрес">{{ object.location }}</td>
          <td aria-label="Цена">{{ object.price_dec }} р.</td>
          <td aria-label="Статус">{{ getStatusText(object) }}</td>
          <td aria-label="Действия" class="actions">
            <div class="actions-wrapper">
              <!-- Редактировать -->
            <i
                class="edit action-icon"
                @click.stop="editObject(object.id)"
                v-tippy="'Редактировать'"
            ></i>

            <!-- Отправить на модерацию -->
            <i
                v-if="object.sto_id === 'draft' || object.sto_id === 'den'"
                class="send action-icon"
                @click.stop="sendToModerateObject(object.id)"
                v-tippy="'Отправить на модерацию'"
            ></i>

            <!-- Удалить -->
            <i
                class="trash action-icon"
                @click.stop="deleteObject(object.id)"
                v-tippy="'Удалить'"
            ></i>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-else>Объекты отсутствуют</p>

    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import axios from "axios";
import tippy from 'tippy.js';

export default {
  name: 'RentalObjectsPage',
  components: {
    AppHeader,
    AppFooter
  },
  directives: {
    tippy: {
      mounted(el, binding) {
        tippy(el, {
          content: binding.value,
          placement: 'top', // Расположение (top, bottom, left, right)
        });
      },
    },
  },
  data() {
    return {
      objects: [],
      isLoading: true
    };
  },
  methods: {
    getRowClass(sto_id) {
      if (sto_id === 'mod') return 'highlight-yellow';
      if (sto_id === 'den') return 'highlight-red';
      if (sto_id === 'pub') return 'highlight-green';
      return '';
    },
    getStatusText(object) {
      switch (object.sto_id) {
        case 'mod':
          return 'На модерации';
        case 'draft':
          return 'Черновик';
        case 'den':
          return `Отклонено по причине: ${object.den_reason || 'не указана'}`;
        case 'pub':
          return 'Опубликован';
        default:
          return 'Неизвестный статус';
      }
    },
    async fetchObjects() {
      this.isLoading = true;
      try {
        const token = this.$store.getters.token;
        const userId = this.$store.getters.userId;
        const response = await axios.get(
            `https://лк.домшеринг.рф/api/cot?sort=-id&filters[own_usr_id-e]=${userId}&filters[sto_id-ee]=archive`,
            {
              headers: {
                'Token': token,
                'Content-Type': 'multipart/form-data'
              }
            }
        );
        const objects = response.data.data;
        this.objects = [...new Map(objects.map(item => [item.id, item])).values()];
      } catch (error) {
        console.error('Ошибка при загрузке объектов:', error);
        alert('Произошла ошибка при загрузке объектов.');
      } finally {
        this.isLoading = false;
      }
    },
    goToCreateObject() {
      this.$router.push({
        name: 'AddObject'
      }).then(() => {
        window.scrollTo(0, 0);
      });
    },
    viewObject(id) {
      this.$router.push({ name: 'ViewObject', params: { id } }).then(() => {
        window.scrollTo(0, 0);
      });
    },
    editObject(id) {
      this.$router.push({ name: 'EditObject', params: { id } }).then(() => {
        window.scrollTo(0, 0);
      });
    },
    async deleteObject(id) {
      const token = this.$store.getters.token;
      try {
        const response = await axios.patch(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/cot/${id}`,
            { sto_id: 'archive' },
            {
              headers: {
                'Token': token,
                'Content-Type': 'application/json'
              }
            }
        );

        if (response.status === 200) {
          alert('Объект успешно удален');
          this.fetchObjects(); // Обновление списка объектов после изменения
        }
      } catch (error) {
        console.error('Ошибка при удалении объекта:', error);
        alert('Произошла ошибка при удалении объекта.');
      }
    },
    async sendToModerateObject(id) {
      const token = this.$store.getters.token;
      try {
        const response = await axios.patch(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/cot/${id}`,
            { sto_id: 'mod' },
            {
              headers: {
                'Token': token,
                'Content-Type': 'application/json'
              }
            }
        );

        if (response.status === 200) {
          alert('Объект успешно отправлен на модерацию');
          this.fetchObjects(); // Обновление списка объектов после изменения
        }
      } catch (error) {
        console.error('Ошибка при отправке объекта на модерацию:', error);
        alert('Произошла ошибка при отправке объекта на модерацию.');
      }
    }
  },
  mounted() {
    this.fetchObjects();  // Загружаем объекты при монтировании компонента
  }
}
</script>

<style scoped>


h1 {
  margin-bottom: 40px;
}
.hero {
  padding: 50px 20px 0;
  flex-direction: column;
}

@media (width >= 768px) {
  .hero {
    padding: 80px 70px 0 44px;
    flex-direction: row;
  }
}

@media (width >= 1440px) {
  .hero {
    padding: 64px 80px 0;
  }
}

@media (width >= 768px) {
  .objects-img {
    width: 300px;
    height: 186px;
  }
}

@media (width >= 1440px) {
  .objects-img {
    width: 386px;
    height: 240px;
  }
}

@media (width < 768px) {
  .hero-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.objects {
  margin-top: 64px;
}

/* .btn {
  margin-right: 10px;
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
} */

.btn-small {
  font-size: 12px;
  padding: 3px 7px;
}

.btn-danger {
  background-color: #e74c3c;
  color: #fff;
  border: none;
}

.highlight-yellow {
  background-color: #FFFCED;
}

.highlight-red {
  background-color: #FAEAE7;
}

.highlight-green {
  background-color: #CCEBCC;
}

</style>