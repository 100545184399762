<template>
  <div>
    <AppHeader />
    <div class="objects">
      <h1>Бронирования</h1>
      <p v-if="isLoading">Загрузка бронирований...</p>
      <table v-else-if="reservations.length" class="objects-table">
        <thead>
        <tr>
          <th>ID</th>
          <th>Объект</th>
          <th>Контакты</th>
          <th>Период</th>
          <th>Кол-во гостей</th>
          <th>Стоимость</th>
          <th>Примечание</th>
          <th>Действия</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="res in reservations" :key="res.id" :class="getRowClass(res.sts_id)">
          <td aria-label="ID">{{ res.id }}</td>
          <td aria-label="Объект">
            <a
                :href="`/object/${res.cot_id}`"
                target="_blank"
                rel="noopener noreferrer"
                class="object-link"
            >
              {{ res.cot_name }}
            </a>
            <br v-if="res.rom_name" />
            <span v-if="res.rom_name">{{ res.rom_name }}</span>
          </td>
          <td aria-label="Контакты">Имя: {{ res.own_name }}, <br/> Телефон: {{ res.own_phone }}, <br/> Email: {{ res.own_mail }}</td>
          <td aria-label="Период">{{ formattedDate(res.start_dt, res.end_dt) }}</td>
          <td aria-label="Кол-во гостей">{{ res.num_of_guests }}</td>
          <td aria-label="Стоимость">{{ res.total_price }}</td>
          <td aria-label="Примечание">{{ res.note }}</td>
          <td aria-label="Действия">
            {{ res.sts_name }}<br/>
            <div class="actions-wrapper">
              <!-- Отклонить -->
              <i v-if="res.sts_id === 'conf' || res.sts_id === 'pend'" @click.stop="cancelReservation(res.id)" class="conf action-icon" v-tippy="'Отменить'"></i>
            </div>
          </td>
        </tr>
        </tbody>
      </table>
      <p v-else>Бронирования отсутствуют</p>
    </div>

    <div v-if="showCancelModalFlag" class="modal-overlay">
      <div class="modal-content">
        <h3>Отменить бронирование</h3>
        <p>Вы точно хотите отменить бронирование?</p>
        <textarea
            v-model="cancelNote"
            placeholder="Укажите причину отмены (необязательно)"
            class="textarea"
        ></textarea>
        <div class="modal-actions">
          <button class="btn btn-danger" @click="confirmCancelReservation">Подтвердить</button>
          <button class="btn btn-light" @click="closeCancelModal">Отмена</button>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import axios from "axios";
import tippy from 'tippy.js';

function formatUnixToDate(unixTime) {
  const date = new Date(unixTime * 1000);  // Преобразуем UnixTime в миллисекунды
  const day = String(date.getDate()).padStart(2, '0');  // Добавляем ведущий 0
  const month = String(date.getMonth() + 1).padStart(2, '0');  // Месяцы в JS начинаются с 0
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;  // Возвращаем дату в формате DD.MM.YYYY
}

export default {
  name: 'TenantBookingPage',
  components: {
    AppHeader,
    AppFooter
  },
  directives: {
    tippy: {
      mounted(el, binding) {
        tippy(el, {
          content: binding.value,
          placement: 'top', // Расположение (top, bottom, left, right)
        });
      },
    },
  },
  data() {
    return {
      reservations: [],
      showEditModal: false,
      editData: {},
      objects: [],
      rooms: [],
      isLoading: true
    };
  },
  methods: {
    getRowClass(sto_id) {
      if (sto_id === 'pend') return 'highlight-yellow';
      if (sto_id === 'canc') return 'highlight-red';
      if (sto_id === 'conf') return 'highlight-green';
      return '';
    },
    formattedDate(start_dt, end_dt) {
      if (start_dt && end_dt) {
        return `${formatUnixToDate(start_dt)} - ${formatUnixToDate(end_dt)}`;
      } else {
        return 'Дата не указана';
      }
    },
    async fetchReservations() {
      this.isLoading = true;
      try {
        const token = this.$store.getters.token; // Получаем токен из Vuex
        const userId = this.$store.getters.userId;
        // TODO: ves в access_basic прописать
        const response = await axios.get(
          `https://лк.домшеринг.рф/api/ves?sort=-id&filters[usr_id-e]=${userId}`,
          {
            headers: {
              'Token': token,
              'Content-Type': 'multipart/form-data'
            }
          }
        );
        const reservations = response.data.data;
        this.reservations = [...new Map(reservations.map(item => [item.id, item])).values()];
      } catch (error) {
        console.error('Ошибка при загрузке бронирований:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async cancelReservation(id) {
      const token = this.$store.getters.token;

      // Показываем prompt для ввода причины отмены
      const reason = prompt("Укажите причину отмены бронирования:", "Бронирование отменено пользователем.");

      // Если пользователь нажал "Отмена" в prompt, не делаем запрос
      if (reason === null) {
        return;
      }

      try {
        // Отправляем запрос на отмену бронирования с указанием причины
        const response = await axios.patch(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/res/${id}`,
            {
              sts_id: "canc",
              note: reason || "Бронирование отменено пользователем.", // Если пустое значение, используем стандартное сообщение
            },
            {
              headers: {
                Token: token,
                "Content-Type": "application/json",
              },
            }
        );

        if (response.status === 200) {
          alert("Бронирование успешно отменено");
          this.fetchReservations(); // Обновляем список бронирований
        }
      } catch (error) {
        console.error("Ошибка при отмене бронирования:", error);
        alert("Произошла ошибка при отмене бронирования.");
      }
    }
  },
  mounted() {
    this.fetchReservations();  // Загружаем объекты при монтировании компонента
  }
}
</script>

<style scoped>


h1 {
  margin-bottom: 40px;
}
.hero {
  padding: 50px 20px 0;
  flex-direction: column;
}

@media (width >= 768px) {
  .hero {
    padding: 80px 70px 0 44px;
    flex-direction: row;
  }
}

@media (width >= 1440px) {
  .hero {
    padding: 64px 80px 0;
  }
}

@media (width >= 768px) {
  .objects-img {
    width: 300px;
    height: 186px;
  }
}

@media (width >= 1440px) {
  .objects-img {
    width: 386px;
    height: 240px;
  }
}

@media (width < 768px) {
  .hero-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.objects {
  margin-top: 64px;
}

.btn {
  padding: 5px 10px;
  font-size: 14px;
  cursor: pointer;
}

.btn-small {
  font-size: 12px;
  padding: 3px 7px;
}

.btn-danger {
  background-color: #e74c3c;
  color: #fff;
  border: none;
}

.highlight-yellow {
  background-color: #FFFCED;
}

.highlight-red {
  background-color: #FAEAE7;
}

.highlight-green {
  background-color: #CCEBCC;
}

/* .icon-btn {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 1.2em;
} */


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  /* background-color: white;
  padding: 20px;
  border-radius: 10px;
  width: 300px;
  text-align: center; */
  margin: auto;
  position: relative;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.15);
  padding: 79px 58px 30px;
  background-color: #ffffff;
}

@media (width < 768px) {
  .modal-content {
    padding: 79px 40px 30px;
  }
}

.pend {
  background-image: url("@/assets/pend.svg");
  background-size: 24px;
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
}

@media (width >= 1240px) {
  .pend {
    background-size: 28px;
    height: 28px;
    width: 28px;
  }
}

.conf {
  background-image: url("@/assets/conf.svg");
  background-size: 24px;
  height: 24px;
  width: 24px;
  background-repeat: no-repeat;
}

@media (width >= 1240px) {
  .conf {
    background-size: 28px;
    height: 28px;
    width: 28px;
  }
}

.actions-wrapper {
  display: flex;
  justify-content: flex-end;
  gap: 14px;
  margin-top: 14px;
}

@media (width >= 1240px) {
  .actions-wrapper {
    justify-content: space-around;
  }
}

.objects-table tbody {
  font-size: 18px;
}

@media (width >= 1240px) {
  .objects-table tbody {
    border-left: 1px solid #D5D6DE;
  }
}

.edit {
  background-image: url("@/assets/edit.svg");
  background-size: 26px;
  height: 26px;
  width: 26px;
  background-repeat: no-repeat;
}

@media (width >= 1240px) {
  .edit {
    background-size: 30px;
    height: 30px;
    width: 30px;
  }
}

@media screen and (max-width: 1240px) {
  .objects-table thead {
    display: none;
  }

  .objects-table tr {
    display: block;
    margin-bottom: 1rem;
    border-bottom: 2px solid #e8e9eb;
  }
  .objects-table td {
    display: block;
    text-align: right;
  }
  .objects-table td:before {
    content: attr(aria-label);
    float: left;
    font-weight: bold;
  }
}

.button-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  gap: 12px;
}

@media (width >= 768px) {
  .button-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}

.form  {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-around;
  list-style-type: none
}

@media (width >= 768px) {
  .form  {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}

@media (width >= 768px) {
  .field-wrapper-1 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .field-wrapper-2 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 1;
    grid-row-end: 1;
  }

  .field-wrapper-3 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  .field-wrapper-4 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 2;
    grid-row-end: 2;
  }

  .field-wrapper-5 {
    grid-column-start: 1;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
  }

  .field-wrapper-6 {
    grid-column-start: 2;
    grid-column-end: 2;
    grid-row-start: 3;
    grid-row-end: 3;
  }

  .field-wrapper-7 {
    grid-column-start: 1;
    grid-column-end: 3;
    grid-row-start: 4;
    grid-row-end: 4;
  }
}

.form-field textarea {
  padding: 18px 20px;
  border: 1px solid #D5D6DE;
  border-radius: 10px;
  background: #FFFFFF;
  font-size: 16px;
}

@media (width < 768px) {
  .form-field textarea {
    font-size: 12px;
  }
}

.form-field textarea:focus {
  border: 1px solid #199057;
}

.form-field textarea:focus {
  outline: none;
}

</style>