<template>
  <footer class="footer" id="footer">
    <h2>Обратная связь</h2>
    <div class="container">
      <div class="feedback-form">
        <form @submit.prevent="submitForm">
          <input v-model="name" type="text" placeholder="Имя" required />
          <input v-model="email" type="email" placeholder="E-mail" required />
          <input v-model="phone" type="text" placeholder="Телефон" required />
          <textarea v-model="comment" placeholder="Что хотите узнать?" required></textarea>
          <button class="btn" type="submit">Отправить</button>
        </form>
        <p class="contact-info-data-processing">
          Нажимая на кнопку «Отправить», я даю <span style="color: #939393">согласие на обработку персональных данных</span>
        </p>
        <!-- Сообщение об успешной отправке -->
        <p v-if="successMessage" class="success-message">{{ successMessage }}</p>
      </div>
      <div class="contact-info">
        <p class="contact-info-about">
          По всем вопросам относительно работы с сайтом/размещения объявления и т.д. обращаться через форму обратной связи или по телефонам:
        </p>
        <div class="contact-info-social-wrapper">
          <p class="contact-info-phone"><strong>+7-931-234-67-77</strong></p>
        <div class="social-contacts">
          <ul class="social-list">
            <li class="social-item">
              <a class="social-link" href="tg://resolve?domain=domsharing777">
                <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" width="26" height="22" fill="none">
                  <path class="icon-path" d="M2.656 9.544C9.367 6.62 13.842 4.693 16.081 3.762 22.474 1.102 23.802.64 24.668.625c.19-.003.617.044.892.268.233.189.297.444.328.623.03.18.069.588.038.907-.346 3.64-1.845 12.473-2.608 16.55-.322 1.725-.958 2.303-1.573 2.36-1.337.123-2.352-.883-3.647-1.732-2.026-1.328-3.17-2.155-5.137-3.451-2.273-1.498-.8-2.32.496-3.666.339-.352 6.229-5.71 6.343-6.196.014-.06.027-.287-.107-.407-.135-.12-.334-.079-.477-.046-.203.046-3.44 2.185-9.71 6.418-.919.63-1.75.938-2.496.922-.822-.018-2.403-.465-3.579-.847C1.99 11.86.844 11.612.944 10.816c.052-.415.622-.838 1.712-1.272Z"/>
                </svg>
              </a>
            </li>
            <li class="social-item">
              <a class="social-link" href="https://api.whatsapp.com/send?phone=+79312346777">
                <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none">
                  <path class="icon-path" fill-rule="evenodd" d="M21.75 4.094C19.406 1.75 16.281.5 13 .5 6.125.5.5 6.125.5 13c0 2.188.625 4.375 1.719 6.25L.5 25.5l6.563-1.719c1.875.938 3.906 1.563 5.937 1.563 6.875 0 12.5-5.625 12.5-12.5 0-3.281-1.406-6.406-3.75-8.75ZM13 23.312c-1.875 0-3.75-.468-5.312-1.406l-.313-.156-3.906 1.094 1.094-3.75-.313-.469c-1.094-1.719-1.563-3.594-1.563-5.469C2.687 7.531 7.375 2.844 13 2.844c2.813 0 5.313 1.094 7.344 2.969 2.031 2.03 2.968 4.53 2.968 7.343 0 5.469-4.53 10.156-10.312 10.156Zm5.625-7.812c-.313-.156-1.875-.938-2.188-.938-.312-.156-.468-.156-.625.157-.156.312-.78.937-.937 1.25-.156.156-.313.156-.625.156-.313-.156-1.25-.469-2.5-1.563-.938-.78-1.563-1.874-1.719-2.187-.156-.313 0-.469.156-.625l.47-.469c.155-.156.155-.312.312-.469.156-.156 0-.312 0-.468 0-.156-.625-1.719-.938-2.344-.156-.469-.468-.469-.625-.469h-.625c-.156 0-.469 0-.781.313-.313.312-1.094 1.093-1.094 2.656 0 1.563 1.094 2.969 1.25 3.281.156.156 2.188 3.438 5.313 4.688 2.656 1.093 3.125.781 3.75.781s1.875-.781 2.031-1.406c.313-.782.313-1.407.156-1.407-.156-.78-.468-.78-.781-.937Z" clip-rule="evenodd"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        </div>
        <div class="contact-info-social-wrapper">
          <p class="contact-info-phone"><strong>+7-931-234-69-99</strong></p>
        <div class="social-contacts">
          <ul class="social-list">
            <li class="social-item">
              <a class="social-link" href="tg://resolve?domain=domsharingadmin2">
                <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" width="26" height="22" fill="none">
                  <path class="icon-path" d="M2.656 9.544C9.367 6.62 13.842 4.693 16.081 3.762 22.474 1.102 23.802.64 24.668.625c.19-.003.617.044.892.268.233.189.297.444.328.623.03.18.069.588.038.907-.346 3.64-1.845 12.473-2.608 16.55-.322 1.725-.958 2.303-1.573 2.36-1.337.123-2.352-.883-3.647-1.732-2.026-1.328-3.17-2.155-5.137-3.451-2.273-1.498-.8-2.32.496-3.666.339-.352 6.229-5.71 6.343-6.196.014-.06.027-.287-.107-.407-.135-.12-.334-.079-.477-.046-.203.046-3.44 2.185-9.71 6.418-.919.63-1.75.938-2.496.922-.822-.018-2.403-.465-3.579-.847C1.99 11.86.844 11.612.944 10.816c.052-.415.622-.838 1.712-1.272Z"/>
                </svg>
              </a>
            </li>
            <li class="social-item">
              <a class="social-link" href="https://api.whatsapp.com/send?phone=+79312346999">
                <svg aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="none">
                  <path class="icon-path" fill-rule="evenodd" d="M21.75 4.094C19.406 1.75 16.281.5 13 .5 6.125.5.5 6.125.5 13c0 2.188.625 4.375 1.719 6.25L.5 25.5l6.563-1.719c1.875.938 3.906 1.563 5.937 1.563 6.875 0 12.5-5.625 12.5-12.5 0-3.281-1.406-6.406-3.75-8.75ZM13 23.312c-1.875 0-3.75-.468-5.312-1.406l-.313-.156-3.906 1.094 1.094-3.75-.313-.469c-1.094-1.719-1.563-3.594-1.563-5.469C2.687 7.531 7.375 2.844 13 2.844c2.813 0 5.313 1.094 7.344 2.969 2.031 2.03 2.968 4.53 2.968 7.343 0 5.469-4.53 10.156-10.312 10.156Zm5.625-7.812c-.313-.156-1.875-.938-2.188-.938-.312-.156-.468-.156-.625.157-.156.312-.78.937-.937 1.25-.156.156-.313.156-.625.156-.313-.156-1.25-.469-2.5-1.563-.938-.78-1.563-1.874-1.719-2.187-.156-.313 0-.469.156-.625l.47-.469c.155-.156.155-.312.312-.469.156-.156 0-.312 0-.468 0-.156-.625-1.719-.938-2.344-.156-.469-.468-.469-.625-.469h-.625c-.156 0-.469 0-.781.313-.313.312-1.094 1.093-1.094 2.656 0 1.563 1.094 2.969 1.25 3.281.156.156 2.188 3.438 5.313 4.688 2.656 1.093 3.125.781 3.75.781s1.875-.781 2.031-1.406c.313-.782.313-1.407.156-1.407-.156-.78-.468-.78-.781-.937Z" clip-rule="evenodd"/>
                </svg>
              </a>
            </li>
          </ul>
        </div>
        </div>
        <div class="vk-light-wrapper">
          <div class="vk-dark-wrapper">
            <p class="contact-info-phone">Мы ВКонтакте</p>
          <div class="social-contacts">
            <ul class="social-list">
              <li class="social-item">
                <a class="social-link" target="_blank" href="https://vk.com/domsharing">
                  <svg aria-hidden="true" focusable="false" width="29" height="16" fill="none">
                    <path fill="#000" fill-rule="evenodd" d="M28.334 1.083C28.537.46 28.334 0 27.375 0h-3.172c-.807 0-1.18.396-1.38.834 0 0-1.614 3.653-3.9 6.026-.74.687-1.075.906-1.479.906-.201 0-.493-.219-.493-.843v-5.84C16.95.334 16.717 0 16.044 0H11.06c-.504 0-.807.348-.807.677 0 .71 1.142.875 1.26 2.873v4.34c0 .952-.185 1.125-.588 1.125-1.076 0-3.692-3.67-5.244-7.87C5.376.33 5.071 0 4.26 0H1.088C.18 0 0 .396 0 .834c0 .78 1.076 4.652 5.008 9.773C7.63 14.104 11.323 16 14.685 16c2.016 0 2.266-.421 2.266-1.146V12.21c0-.843.19-1.01.83-1.01.47 0 1.277.218 3.159 1.904C23.091 15.103 23.446 16 24.656 16h3.172c.907 0 1.36-.421 1.099-1.252-.287-.828-1.314-2.03-2.676-3.454-.74-.812-1.849-1.686-2.185-2.123-.47-.562-.336-.812 0-1.312 0 0 3.865-5.058 4.268-6.776Z" clip-rule="evenodd"/>
                  </svg>
                </a>
              </li>
            </ul>
          </div>
          </div>
    </div>
      </div>
    </div>
  </footer>
</template>

<script>

export default {
  name: 'AppFooter',
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      comment: '',
      successMessage: '' // Сообщение об успешной отправке
    };
  },
  methods: {
    submitForm() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const currentUnixTime = Math.floor(Date.now() / 1000);

      const raw = JSON.stringify({
        data_tx: `{"name": "${this.name}", "email": "${this.email}", "phone": "${this.phone}", "mes": "${this.comment}"}`,
        evt_id: "message",
        e: 1,
        nm: "обратная связь с сайта",
        reason: "",
        usr_id: 1,
        to_usr_id: 1,
        cre_dt: currentUnixTime
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("https://лк.домшеринг.рф/api/eve", requestOptions)
          .then((response) => response.text())
          .then((result) => {
            console.log(result);
            // Выводим сообщение об успешной отправке и очищаем поля формы
            this.successMessage = 'Форма успешно отправлена!';
            this.clearForm();
          })
          .catch((error) => console.error("Ошибка при отправке формы:", error));
    },
    clearForm() {
      // Очищаем поля формы
      this.name = '';
      this.email = '';
      this.phone = '';
      this.comment = '';
    }
  }
}
</script>

<style scoped>
.footer {
  background-color: #333;
  color: white;
  padding: 30px 20px;
}

@media (width >= 1440px) {
  .footer {
    padding: 40px 80px;
  }
}

@media (width < 1280px) {
  form {
    display: flex;
    flex-direction: column;
  }
}

@media (width >= 1280px) and (width < 1440px) {
  form {
    width: 700px;
  }
}

@media (width >= 1440px) {
  form {
    width: 832px;
  } 
}

.container {
  display: flex;
  justify-content: space-between;
  gap: 25px;
  flex-direction: column-reverse;
}

@media (width >= 1280px) {
  .container {
    gap: 50px;
    flex-direction: row;
    align-items: flex-start;
  } 
}

feedback-form h3 {
  font-family: 'Cabin';
  font-size: 36px;
  font-weight: 600;
  line-height: 46.8px;
}

.feedback-form input,
.feedback-form textarea {
  font-family: inherit;
  border-radius: 10px;
  border: 1px solid #D5D6DE;
  padding: 24px 20px;
  margin-bottom: 10px;
  font-size: 16px;
}

@media (width >= 1280px) and (width < 1440px) {
  .feedback-form input,
  .feedback-form textarea {
    width: 660px;
  }
}

@media (width >= 1440px) {
  .feedback-form input,
  .feedback-form textarea{
    width: 792px;
  } 
}

.btn {
  width: 100%;
}

@media (width < 1280px) {
  .contact-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

@media (width >= 1280px) {
  .contact-info {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
}

@media (width >= 1440px) {
  form {
    width: 832px;
  } 
}

.contact-info-about {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  margin: 0;
}

@media (width >= 1440px) {
  .contact-info-about {
    font-size: 20px;
    line-height: 28px;
  }
}

.contact-info-data-processing {
  font-family: inherit;
  font-size: 16px;
  font-weight: 300;
  line-height: 22px;
  margin: 0;
  padding: 16px 60px;
  text-align: center;
}

@media (width < 700px) {
  .contact-info-data-processing {
    padding: 16px 20px;
  }
}

@media (width >= 1440px) {
  .contact-info-data-processing {
    font-size: 20px;
    line-height: 28px;
    margin: 0 60px;
    text-align: center;
  }
}

.contact-info-phone {
  font-size: 24px;
  font-weight: 700;
  margin: 0;
}

@media (width >= 768px) {
  .contact-info-phone {
    font-size: 30px;
  }
}

@media (width >= 1472px) {
  .contact-info-phone {
    font-size: 35px;
  }
}

.social-list {
  display: flex;
  gap: 16px;
  padding: 0;
  margin: 0;
}

.social-link {
  width: 50px;
  height: 50px;
  background-color: transparent;
  color: #222222;
  display: flex;
  align-items: center;
  top: center;
  margin-left: 11px;
}

/* .social-link:hover {
  opacity: 0.6;
}

.social-link:active {
  opacity: 0.3;
} */

.icon-path {
  fill: #222222;
}

/* .social-link:hover .icon-path,
.social-link:focus .icon-path {
  fill: #ffffff;
} */

/* .social-link:active .icon-path {
  fill: rgba(104, 162, 202, 0.3);
} */

.social-item {
  width: 50px;
  height: 50px;
  background-color: white;
  border-radius: 8px;
}

.social-item::before {
  content: none;
}

@media (width < 768px) {
  .social-contacts {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

h2 {
  text-align: left;
}

.success-message {
  color: #199057;
  font-size: 16px;
  margin-top: 10px;
}

@media (width >= 390px) {
  .contact-info-social-wrapper {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    align-items: center;
  }
}

@media (width < 390px) {
  .contact-info-social-wrapper {
    gap: 20px;
    display: flex;
    flex-direction: column;
  }
}

  .vk-light-wrapper {
    display: flex;
    gap: 32px;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #CFE89F;
    border-radius: 10px;
  }

  .vk-dark-wrapper {
    padding: 20px;
    background-color: #333;
    border-radius: 10px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }

  @media (width < 390px) {
  .vk-dark-wrapper {
    gap: 20px;
    flex-direction: column;
  }
}

@media (width >= 1472px) {
  .vk-dark-wrapper {
    align-items: center;
  }
}
</style>

