<template>
  <div>
    <AppHeader />
    <div class="objects">
      <h1>Редактирование варианта размещения</h1>
      <div class="form">
        <div class="form-field-wrapper">
          <div class="form-field">
            <p class="text">Название</p>
            <input type="text" v-model="roomName" />
          </div>
          <div class="form-field">
            <p class="text">Цена</p>
            <input type="number" v-model="roomPrice" />
          </div>
          <div class="form-field">
            <p class="text">Количество гостей</p>
            <input type="number" v-model="roomGuests" />
          </div>
          <div class="form-field">
            <p class="text">Количество комнат</p>
            <input type="number" v-model="roomApartments" />
          </div>
          <div class="form-field">
            <p class="text">Площадь</p>
            <input type="number" v-model="roomSquare" />
          </div>
          <br/>
          <div class="checkbox-item">
            <input type="checkbox" class="checkbox-input" id="animals" value="Animals" v-model="roomPets" />
            <label for="animals" class="checkbox-text">Можно с животными</label>
          </div>
          <div class="checkbox-item">
            <input type="checkbox" class="checkbox-input" id="breakfast" value="Breakfast" v-model="roomBreakfast" />
            <label for="breakfast" class="checkbox-text">Завтрак включен</label>
          </div>
        </div>
      </div>
    </div>

    <TextAreaField
        label="Описание варианта размещения"
        id="room-description"
        v-model="roomDescription"
        placeholder="Введите описание"
        :maxLength="65535"
    />


    <PhotoLoad @file-selected="handleFileSelection" />
    <div class="photo-wrapper">
      <div class="photo" v-for="(photo, index) in combinedPhotos" :key="photo.id || index">
        <img :src="photo.preview || `https://лк.домшеринг.рф/file/${photo.id}/${photo.fn}`" alt="photo" />
        <button @click="markPhotoForDeletion(index, photo.id)" class="delete-button" :class="{ marked: isPhotoMarked(photo.id) }">✖</button>
      </div>
    </div>

    <button class="btn" @click="updateRoom">Сохранить изменения</button>

    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <div v-if="isLoading">
          <p>Сохранение варианта размещения...</p>
          <div class="spinner"></div>
        </div>
        <div v-else>
          <p>Изменения отправлены на модерацию. Скоро Ваш вариант размещения появится на сайте.</p>
          <button class="btn" @click="closeModal">ОК</button>
        </div>
      </div>
    </div>

    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import PhotoLoad from './PhotoLoad.vue';
import { ref } from 'vue';
import axios from "axios";
import TextAreaField from "@/components/TextAreaField.vue";

export default {
  name: 'EditRoom',
  components: {
    TextAreaField,
    AppHeader,
    AppFooter,
    PhotoLoad
  },
  data() {
    return {
      roomId: this.$route.params.id,
      roomName: '',
      roomDescription: '',
      roomPrice: null,
      roomGuests: null,
      roomApartments: null,
      roomSquare: null,
      roomPets: null,
      roomBreakfast: null,
      selectedFiles: [],
      activities: ref([]),
      userName: '',
      userPhone: '',
      userEmail: '',
      userLocation: '',
      originalUserData: {},
      suggestions: [],  // Подсказки Dadata
      errorMessage: '',
      showModal: false,
      isLoading: false,
      photos: [],
      newPhotos: [],
      photosMarkedForDeletion: [],
      objectId: null
    };
  },
  computed: {
    isFormValid() {
      return this.isDataChanged && this.validateFields();
    },
    isDataChanged() {
      return (
          this.userName !== this.originalUserData.nm ||
          this.userPhone !== this.originalUserData.phone ||
          this.userEmail !== this.originalUserData.mail ||
          this.userLocation !== this.originalUserData.remark_tx
      );
    },
    combinedPhotos() {
      return [...this.photos, ...this.newPhotos]; // Объединение массивов старых и новых фото
    }
  },
  methods: {
    markPhotoForDeletion(index, photoId) {
      if (photoId) {
        // Помечаем фото для удаления и удаляем его из массива `photos`
        if (!this.photosMarkedForDeletion.includes(photoId)) {
          this.photosMarkedForDeletion.push(photoId);
        }
        this.photos.splice(index, 1); // Удаляем фото из массива `photos`
      } else {
        // Если фото новое, удаляем его из массива `newPhotos`
        this.newPhotos.splice(index - this.photos.length, 1);
      }
    },
    isPhotoMarked(photoId) {
      return this.photosMarkedForDeletion.includes(photoId);
    },
    async fetchPhotos() {
      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/fil?filters[rom_id-e]=${this.roomId}`);
        this.photos = response.data.data; // Загружаем фото в массив
      } catch (error) {
        console.error('Ошибка при загрузке фотографий:', error);
      }
    },
    handleFileSelection(fileData) {
      this.newPhotos.push(fileData);
      this.selectedFiles.push(fileData);
      // Добавляем новые фото в массив newPhotos
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1); // Удаляем файл из массива по индексу
    },
    closeModal() {
      this.showModal = false;
      const id = this.objectId
      this.$router.push({ name: 'ViewObject', params: { id } }).then(() => {
        this.$nextTick(() => {
          const target = document.getElementById('rooms');
          if (target) {
            target.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        });
      });
    },
    async createFileRecord(rom_id, filename) {
      const token = this.$store.getters.token;
      const userId = this.$store.getters.userId;
      const fileData = {
        nm: filename,
        own_usr_id: userId,
        rom_id: rom_id
      };
      try {
        const response = await axios.post(
            'https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/fil',
            fileData,
            {
              headers: {
                'Token': token,
                'Content-Type': 'application/json'
              }
            }
        );
        return response.data.data.id;
      } catch (error) {
        console.error('Ошибка при создании записи в fil:', error);
        throw error;
      }
    },
    async uploadFileToServer(file, fileId) {
      const token = this.$store.getters.token;
      try {
        const formData = new FormData();
        formData.append('up', file);

        await axios.post(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/upload/${fileId}`,
            formData,
            {
              headers: {
                'Token': token,
                'Content-Type': 'multipart/form-data'
              }
            }
        );

      } catch (error) {
        console.error('Ошибка при загрузке файла:', error);
      }
    },
    async fetchRoomData() {
      const token = this.$store.getters.token;
      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/rom/${this.roomId}`, {
          headers: {
            'Token': token
          }
        });
        const room = response.data.data;
        this.objectId = room.cot_id;
        this.roomName = room.nm;
        this.roomPrice = room.price_dec;
        this.roomGuests = room.num_of_guests;
        this.roomApartments = room.num_of_rooms;
        this.roomSquare = room.square;
        this.roomPets = room.with_pets_is;
        this.roomBreakfast = room.with_breakfast_is;
        this.roomDescription = room.descr;
      } catch (error) {
        console.error('Ошибка при загрузке данных варианта размещения:', error);
      }
    },
    async updateRoom() {
      this.showModal = true;
      this.isLoading = true;
      try {
        const token = this.$store.getters.token;

        const roomData = {
          nm: this.roomName,
          descr: this.roomDescription,
          price_dec: this.roomPrice,
          num_of_guests: this.roomGuests,
          num_of_rooms: this.roomApartments,
          with_breakfast_is: this.roomBreakfast,
          with_pets_is: this.roomPets,
          square: this.roomSquare,
          cot_id: this.objectId
        };

        await axios.patch(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/rom/${this.roomId}`, roomData, {
          headers: {
            'Token': token,
            'Content-Type': 'application/json'
          }
        });

        for (const photoId of this.photosMarkedForDeletion) {
          await axios.delete(`https://лк.домшеринг.рф/api/fil/${photoId}`, {
            headers: {
              'Token': token,
              'Content-Type': 'application/json'
            }
          });
        }

        for (const fileData of this.selectedFiles) {
          const fileId = await this.createFileRecord(this.roomId, fileData.name);
          await this.uploadFileToServer(fileData.file, fileId);
        }

        this.isLoading = false;
      } catch (error) {
        console.error('Ошибка при сохранении варианта размещения:', error);
        alert('Произошла ошибка при сохранении варианта размещения.');
        this.isLoading = false;
        this.showModal = false;
      }
    },
    validateFields() {
      this.errorMessage = '';

      if (!this.userName.trim() || !this.userEmail.trim() || !this.userPhone.trim() || !this.userLocation.trim()) {
        this.errorMessage = 'Все поля должны быть заполнены.';
        return false;
      }

      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(this.userEmail)) {
        this.errorMessage = 'Неверный формат email.';
        return false;
      }

      const phonePattern = /^\+7\d{10}$/;
      if (!phonePattern.test(this.userPhone)) {
        this.errorMessage = 'Номер телефона должен начинаться с +7 и содержать 10 цифр.';
        return false;
      }

      return true;
    },
  },
  mounted() {
    this.fetchRoomData();
    this.fetchPhotos();
  }
};
</script>

<style scoped>

h1 {
  margin-bottom: 40px;
}

.objects {
  margin-top: 64px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.photo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 30px 20px;
}

@media (width >= 640px) {
  .photo-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}

@media (width >= 1000px) {
  .photo-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}


@media (width >= 1440px) {
  .photo-wrapper {
    padding: 40px 20px;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px 80px;
  }
}

.photo {
  position: relative;
}

.photo img {
  width: 280px;
  object-fit: cover;
}

.btn {
  width: 295px;
  margin: 40px auto;
}

.photo img {
  width: 280px;
  height: 202px;
  object-fit: cover;
}

@media (width >= 768px) {
  .photo img {
    width: 346px;
    height: 250px;
  }
}

.delete-button {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: 2px solid #222222;
  border-radius: 5px;
  background-image: url("@/assets/cross.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: -13px;
  left: 0;
  cursor: pointer;
}

</style>