<template>
  <div>
    <AppHeader />
    <div class="photo-wrapper">
      <div class="photo" v-for="photo in photos" :key="photo.id">
        <img :src="`https://лк.домшеринг.рф/file/${photo.id}/${photo.fn}`" alt="photo" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'PhotosBlock',
  props: {
    cotId: {
      type: String,
      required: false // Установим необязательным, так как может быть передан romId
    },
    romId: {
      type: String,
      required: false // Тоже делаем необязательным
    }
  },
  data() {
    return {
      photos: []
    };
  },
  mounted() {
    this.fetchPhotos();
  },
  methods: {
    async fetchPhotos() {
      let filterParam = '';

      // Определяем фильтр в зависимости от переданных пропсов
      if (this.cotId) {
        filterParam = `filters[cot_id-e]=${this.cotId}`;
      } else if (this.romId) {
        filterParam = `filters[rom_id-e]=${this.romId}`;
      } else {
        console.warn('Не передан ни cotId, ни romId для загрузки фотографий');
        return;
      }

      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/fil?${filterParam}`);
        this.photos = response.data.data; // Загружаем фото в массив
      } catch (error) {
        console.error('Ошибка при загрузке фотографий:', error);
      }
    }
  }
};
</script>

<style scoped>

/* .card {
  padding: 80px;
} */

.photo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 0 20px;
}

@media (width >= 860px) {
  .photo-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    padding: 0 80px;
  } 
}

@media (width >= 1230px) {
  .photo-wrapper {
    grid-template-columns: repeat(3, 1fr);
  } 
}

@media (width >= 1600px) {
  .photo-wrapper {
    grid-template-columns: repeat(4, 1fr);
  } 
}
  .btn {
    width: 295px;
    margin-left: auto;
    margin-right: auto;
  }

  .photo img {
    width: 280px;
    height: 202px;
    object-fit: cover;
  }

  @media (width >= 768px) {
  .photo img {
    width: 346px;
    height: 250px;
  } 
}

</style>