<template>
  <div>
    <AppHeader />
    <div class="objects photo-load">
      <h2>Фото</h2>
      <div class="photo-wrapper">
        <input type="file" class="photo-input" @change="onFileSelected" id="real-input" hidden multiple/>
          <label for="real-input" class="custom-file-upload">
            <picture>
            <source media="(min-width: 1440px)" srcset="@/assets/upload.svg" width="50" height="50"/>
            <source media="(min-width: 768px)" srcset="@/assets/upload.svg" width="40" height="40"/>
            <img class="hero__logo" src="@/assets/upload.svg" width="30" height="30" alt="Загрузка."/>
          </picture>
            Загрузить фото
          </label>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>

export default {
  name: 'PhotoLoad',
  data() {
      return {
      selectedFile: [],
    }
  },
  methods: {
    onFileSelected(event) {
      const files = Array.from(event.target.files);
      const validFiles = files.filter(file => file.type.startsWith('image/')); // Проверка типа файла

      validFiles.forEach(file => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.$emit('file-selected', {
            file: file,
            name: file.name,
            preview: e.target.result // Передаем URL для предпросмотра
          });
        };

        reader.onerror = (error) => {
          console.error('Ошибка чтения файла:', error);
        };

        reader.readAsDataURL(file); // Генерация URL для предпросмотра
      });
    }
  }
}
</script>

<style scoped>

.custom-file-upload {
    border: 5px dashed #CFE89F;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 10px;
    margin-bottom: 10px; 
    height: 216px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* каждый элемент заслуживает собственного пространства */
}

.photo-wrapper {
  width: 100%;
}

.photo-input {
  font-size: 16px;
  line-height: 24px;
}

@media (width >= 768px) {
  .photo-input {
    font-size: 18px;
    line-height: 26px;
  }
}

@media (width >= 1440px) {
  .photo-input {
    font-size: 30px;
    line-height: 40px;
  }
}

h2 {
  margin-bottom: 40px;
}

.objects {
  margin-top: 64px;
}

@media (width >= 768px) {
  .photo-load  {
    margin: 0 43px;
    padding: 40px 37px;
  }
}
</style>