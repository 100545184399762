<template>
  <div class="share-container">
    <button class="btn btn-share" @click="toggleShareMenu">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none">
        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM19 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6ZM5 22a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"/>
        <path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12H9c-2.2 0-4-1-4-4v8"/>
      </svg>
      <i class="fas"></i> Поделиться
    </button>
    <div v-if="showShareMenu" class="share-menu">
      <a
          :href="`https://vk.com/share.php?url=${shareUrl}&title=${customText}`"
          target="_blank"
          rel="noopener noreferrer"
          class="share-link vk"
      >
        <i class="fab fa-vk"></i> ВКонтакте
      </a>
      <a
          :href="`https://t.me/share/url?url=${shareUrl}&text=${customText}`"
          target="_blank"
          rel="noopener noreferrer"
          class="share-link telegram"
      >
        <i class="fab fa-telegram-plane"></i> Телеграм
      </a>
      <a
          :href="`https://wa.me/?text=${encodeURIComponent(customText + ' ' + shareUrl)}`"
          target="_blank"
          rel="noopener noreferrer"
          class="share-link whatsapp"
      >
        <i class="fab fa-whatsapp"></i> WhatsApp
      </a>
      <a
          :href="`mailto:?subject=${customSubject}&body=${customText}%0D%0A${shareUrl}`"
          class="share-link email"
      >
        <i class="fas fa-envelope"></i> Email
      </a>
      <button @click="copyToClipboard" class="share-link copy">
        <i class="fas fa-copy"></i> Скопировать ссылку
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ShareButton',
  props: {
    shareUrl: {
      type: String,
      required: true,
    },
    shareText: {
      type: String,
      required: false,
      default: 'Поделиться',
    },
    customSubject: {
      type: String,
      default: 'Интересный объект для вас!',
    },
    customText: {
      type: String,
      default: 'Посмотрите этот замечательный объект!',
    },
  },
  data() {
    return {
      showShareMenu: false,
    };
  },
  methods: {
    toggleShareMenu() {
      this.showShareMenu = !this.showShareMenu;
    },
    async copyToClipboard() {
      try {
        await navigator.clipboard.writeText(this.shareUrl);
        alert('Ссылка скопирована в буфер обмена');
      } catch (err) {
        console.error('Ошибка при копировании в буфер обмена:', err);
        alert('Не удалось скопировать ссылку');
      }
    },
  },
};
</script>

<style scoped>
.share-container {
  position: relative;
  display: inline-block;
}

.btn-share {
  background-color: #199057;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  width: 190px;
}

.share-menu {
  position: absolute;
  top: 40px;
  left: 0;
  background: white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  z-index: 10;
}

.share-link {
  display: flex;
  align-items: center;
  padding: 8px;
  color: #555;
  text-decoration: none;
  font-size: 14px;
  cursor: pointer;
}

.share-link i {
  margin-right: 8px;
}

.share-link.vk {
  color: #4c75a3;
}

.share-link.telegram {
  color: #0088cc;
}

.share-link.whatsapp {
  color: #25d366;
}

.share-link.email {
  color: #6c757d;
}

.share-link.copy {
  background: none;
  border: none;
  color: #6c757d;
}

.share-link:hover {
  background-color: #f0f0f0;
  border-radius: 3px;
}
</style>