<template>
  <div class="objects">
    <h1>Сменить пароль</h1>
    <div class="form">
      <div class="form-field-wrapper">
        <div class="form-field">
          <p class="text">Текущий пароль</p>
          <input type="password" v-model="currentPassword" placeholder="Введите текущий пароль" />
        </div>
        <div class="form-field">
          <p class="text">Новый пароль</p>
          <input type="password" v-model="newPassword" placeholder="Введите новый пароль" />
        </div>
        <div class="form-field">
          <p class="text">Подтвердить пароль</p>
          <input type="password" v-model="confirmPassword" placeholder="Подтвердите новый пароль" />
        </div>
        <!-- Ошибки валидации -->
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
        <button
            class="btn"
            :disabled="!isFormValid"
            @click="changePassword">
          Сменить пароль
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProfileChangePassword',
  data() {
    return {
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      errorMessage: "",
      successMessage: ""
    };
  },
  computed: {
    isFormValid() {
      return (
          this.currentPassword &&
          this.newPassword &&
          this.confirmPassword &&
          this.newPassword === this.confirmPassword &&
          this.newPassword.length >= 6
      );
    }
  },
  methods: {
    async changePassword() {
      if (!this.isFormValid) {
        this.errorMessage = "Пароли не совпадают или не соответствуют требованиям.";
        this.successMessage = "";
        return;
      }

      try {
        const token = this.$store.getters.token;

        const response = await axios.post(
            "https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/chgpass",
            {
              old_pass: this.currentPassword,
              new_pass: this.newPassword
            },
            {
              headers: {
                Token: token,
                "Content-Type": "application/json"
              }
            }
        );

        if (response.status === 200) {
          this.successMessage = "Пароль успешно изменен.";
          this.errorMessage = "";
          this.currentPassword = "";
          this.newPassword = "";
          this.confirmPassword = "";
        } else {
          throw new Error("Ошибка при смене пароля");
        }
      } catch (error) {
        console.error("Ошибка при смене пароля:", error);
        this.errorMessage =
            error.response?.data?.message || "Ошибка при смене пароля. Попробуйте снова.";
        this.successMessage = "";
      }
    }
  },
  mounted() {
  }
};
</script>

<style scoped>
h1 {
  margin-bottom: 40px;
}
.btn {
  width: 100%;
}

/* Общие стили для полей формы */
.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.form-field-wrapper {
  align-items: end;
}

.form {
  align-items: center;
  justify-content: center;
}

.objects {
  margin-top: 64px;
}
</style>