<template>
  <div class="ticker-wrapper">
    <div class="ticker">
      <div class="ticker-text-container">
        <p class="ticker-text">
          Сайт запущен в тестовом режиме. Мы работаем для Вас и стремимся сделать сервис лучше!
          Если вы обнаружили ошибку, <a href="#footer">напишите нам</a> или <a href="mailto:domsharingrf@yandex.ru">сообщите на почту</a>.
        </p>
      </div>
      <div class="ticker-text-container">
        <p class="ticker-text">
          Сайт запущен в тестовом режиме. Мы работаем для вас и стремимся сделать сервис лучше!
          Если вы обнаружили ошибку, <a href="#footer">напишите нам</a> или <a href="mailto:domsharingrf@yandex.ru">сообщите на почту</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TickerMessage",
  methods: {
    handleClick() {
      // Прокрутка к footer
      const footer = document.getElementById("footer");
      if (footer) {
        footer.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style scoped>
.ticker-wrapper {
  width: 100%;
  background-color: #fff3cd; /* Светло-желтый фон */
  overflow: hidden;
  padding: 10px 0;
  cursor: pointer; /* Указатель, чтобы было понятно, что можно кликнуть */
}

.ticker {
  display: flex;
  animation: scroll-left 15s linear infinite; /* Бесконечная прокрутка */
}

.ticker-text-container {
  flex: none; /* Убираем авторастяжение */
  white-space: nowrap; /* Не переносим текст */
  display: inline-block; /* Отображаем текст как блочный элемент */
  padding-right: 50px; /* Отступ между копиями текста */
}

.ticker-text {
  font-size: 14px;
  color: #856404; /* Темно-желтый цвет */
}

.ticker a {
  color: #004085; /* Темно-синий для ссылок */
  text-decoration: underline;
}

@keyframes scroll-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}
</style>