<template>
  <BaseForm @submit="resetPassword" title="Восстановление пароля">
    <!-- Поле Email -->
    <FormField
        v-model="email"
        label="Email"
        placeholder="Введите ваш email"
        type="email"
        :isValid="isEmailValid"
        errorMessage="Введите корректный email."
        :showErrors="showErrors"
    />

    <!-- Ошибки -->
    <ErrorMessage v-if="errorMessage" :message="errorMessage" />

    <!-- Кнопки -->
    <div class="btn-wrapper">
      <BaseButton type="submit" :disabled="!isEmailValid || isLoading">
        {{ isLoading ? "Отправка..." : "Сбросить пароль" }}
      </BaseButton>
    </div>

    <!-- Модальное окно -->
    <BaseModal v-if="isLoading || successMessage" @close="handleModalClose">
      <template #header>
        Восстановление пароля
      </template>
      <template #body>
        <div v-if="isLoading" class="spinner-wrapper">
          <div class="spinner"></div>
          <p>Отправляем запрос...</p>
        </div>
        <div v-else>
          <p>Инструкция по сбросу пароля отправлена на ваш email.</p>
        </div>
      </template>
      <template #footer v-if="!isLoading">
        <BaseButton @click="redirectToLogin">Ок</BaseButton>
      </template>
    </BaseModal>
  </BaseForm>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";
import FormField from "@/components/FormField.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import axios from "axios";

export default {
  name: "PasswordReset",
  components: {
    BaseForm,
    FormField,
    BaseButton,
    BaseModal,
    ErrorMessage,
  },
  data() {
    return {
      email: "", // Email пользователя
      errorMessage: "", // Сообщение об ошибке
      successMessage: false, // Указывает на успешный результат
      showErrors: false, // Показать ошибки валидации
      isLoading: false, // Состояние загрузки
    };
  },
  computed: {
    // Валидация email
    isEmailValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.email);
    },
  },
  methods: {
    async resetPassword() {
      // Проверка на корректность email
      if (!this.isEmailValid) {
        this.showErrors = true;
        return;
      }

      this.isLoading = true;
      this.errorMessage = "";
      this.successMessage = false;

      try {
        // Выполнение запроса к API
        const response = await axios.get(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/resetpass?login=${this.email}`
        );

        if (response.status === 200) {
          this.successMessage = true;
        } else {
          this.errorMessage =
              "Произошла ошибка при сбросе пароля. Попробуйте снова.";
        }
      } catch (error) {
        this.errorMessage =
            "Произошла ошибка сети. Проверьте подключение к интернету.";
        console.error("Ошибка сброса пароля:", error);
      } finally {
        this.isLoading = false;
      }
    },
    redirectToLogin() {
      this.$router.push({ name: "LoginPage" });
    },
    handleModalClose() {
      if (this.successMessage) {
        this.redirectToLogin();
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
/* Спиннер */
.spinner-wrapper {
  text-align: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3; /* Светлая часть */
  border-top: 4px solid #3498db; /* Синяя часть */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Кнопки */
.btn-wrapper {
  margin-top: 20px;
}

@media (width >= 768px) {
  .form-field {
    width: 566px;
  }
}
</style>