<template>
  <div class="object-list">
    <div class="object-card" v-for="object in objects" :key="object.id" @click="goToObjectPage(object.id)">
      <img :src="`https://лк.домшеринг.рф/file/${object.file_id}/${object.file_name}`" alt="Object" />
      <div class="object-card-text">
        <h3>{{ object.nm }}</h3>
        <p class="object-card-addr">{{ object.object_location }}</p>
        <div class="object-card-info">
          <div class="object-card-size">
            <svg class="user-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M4.92993 19.0706C5.50542 17.9937 6.3557 17.0465 7.41366 16.3022C8.47163 15.5579 9.70459 15.0451 11 14.8043C12.2954 14.5636 13.6235 14.6025 14.9 14.9174C16.1766 15.2322 17.3675 15.814 18.364 16.6162C19.3605 17.4183 20.133 18.4247 20.6256 19.5554" stroke="#000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg> до {{ object.size }} чел.
          </div>
          <div><p class="object-card-price"><strong>от {{ object.price_dec }} р/сут.</strong></p></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ObjectList',
  props: {
    objects: {
      type: Array,
      required: true,
      default: () => [], // Массив объектов должен быть передан через пропсы
    },
  },
  methods: {
    goToObjectPage(id) {
      // Проверяем, существует ли dateRange и является ли оно строкой
      const dateRange = this.$parent.filters.dateRange && typeof this.$parent.filters.dateRange === 'string'
          ? this.$parent.filters.dateRange.split('—')
          : [];

      const startDate = dateRange[0] ? dateRange[0].trim() : null;
      const endDate = dateRange[1] ? dateRange[1].trim() : null;

      const queryParams = {
        startDate: startDate || '',  // Если нет даты, отправляем пустую строку
        endDate: endDate || '',      // Если нет даты, отправляем пустую строку
        guestCount: this.$parent.filters.guestCount || 1,  // Если нет данных, используем значение по умолчанию
      };

      this.$router.push({
        name: 'ViewCard',
        params: { id },
        query: queryParams  // Передаем query параметры
      }).then(() => {
        window.scrollTo(0, 0);
      });
    }
  }
}
</script>

<style scoped>
.object-list {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

/* @media (width >= 870px) { */
@media (width >= 768px) {
  .object-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
    align-items: start;
  } 
}

@media (width >= 1236px) {
  .object-list {
    grid-template-columns: repeat(3, 1fr);
  } 
}

@media (width >= 1600px) {
  .object-list {
    grid-template-columns: repeat(4, 1fr);
  } 
}

.object-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 280px;
  cursor: pointer;
}

@media (width >= 870px) {
  .object-card {
    width: 346px;
  } 
}

.object-card img {
  width: 280px;
  height: 202px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  object-fit: cover;
}

@media (width >= 870px) {
  .object-card img {
    width: 346px;
    height: 250px;
  } 
}

.object-card-text {
  padding: 20px;
}

.object-card h3 {
  text-align: left;
}

.object-card-addr {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #979797;
}

@media (width >= 768px) {
  .object-card-addr {
    font-size: 16px;
    line-height: 22px;
  } 
}

@media (width >= 768px) {
  .object-card-addr {
    font-size: 18px;
    line-height: 24px;
  } 
}

.object-card-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px !important;
}

.object-card-size {
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  color: #222222;
}

@media (width >= 768px) {
  .object-card-size {
    font-size: 16px;
  } 
}

@media (width >= 1440px) {
  .object-card-size {
    font-size: 16px;
  } 
}

.object-card-price {
  font-size: 16px;
  font-weight: 700;
  line-height: 26px;
  color: #199057;
  margin: 0;
}

@media (width >= 768px) {
  .object-card-price {
    font-size: 18px;
  } 
}

@media (width >= 1440px) {
  .object-card-price {
    font-size: 20px;
  } 
}

</style>