<template>
  <div class="logo">
    <router-link to="/">
      <img class="logo" src="@/assets/logo.svg" alt="ДомШеринг" />
    </router-link>
  </div>
  <div class="wrapper">
    <ResetPasswordForm :login="login" :code="code" />
    <picture>
      <source media="(min-width: 1600px)" srcset="@/assets/password.jpg" width="900" height="880" />
      <source media="(min-width: 1280px)" srcset="@/assets/password-1280.jpg" width="580" height="880" />
      <img class="picture" src="@/assets/password-768.jpg" height="700" />
    </picture>
  </div>
</template>

<script>
import ResetPasswordForm from "./ResetPasswordForm.vue";

export default {
  name: "ResetPasswordPage",
  components: {
    ResetPasswordForm,
  },
  data() {
    return {
      login: null, // Логин из URL
      code: null, // Код из URL
    };
  },
  mounted() {
    this.loadUrlParams();
  },
  methods: {
    loadUrlParams() {
      // Извлечение параметров из URL
      const params = new URLSearchParams(window.location.search);
      this.login = params.get("login");
      this.code = params.get("code");

      if (!this.login || !this.code) {
        console.error("Некорректные параметры ссылки.");
      }
    },
  },
};
</script>

<style scoped>
.logo {
  padding: 10px;
  width: 80px;
}

@media (width >= 768px) {
  .logo {
    width: 110px;
  }
}

@media (width >= 1280px) {
  .logo {
    position: absolute;
  }
}

.wrapper {
  padding: 0 0 40px;
}

@media (width >= 768px) {
  .wrapper {
    display: flex;
    justify-content: center;
  }
}

@media (width >= 1280px) {
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
}

.picture {
  display: none;
}

@media (width >= 768px) {
  .picture {
    display: block;
    width: 100%;
  }
}
</style>