<template>
  <div>
    <AppHeader />
    <div class="hero">
      <div class="hero-wrapper">
        <h1>Контакты</h1>
        <p class="description">по всем интересующим вопросам</p>
      </div>
      <img class="contacts-image" src="@/assets/contacts_phone.svg" width="155" height="200" alt="" />
    </div>
    <AppFooter />
  </div>


</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';

export default {
  name: 'ContactsPage',
  components: {
    AppHeader,
    AppFooter,
  }
};
</script>

<style scoped>

.hero {
  padding: 50px 20px;
  flex-direction: column;
}

@media (width >= 768px) {
  .hero {
    padding: 80px 70px 50px 44px;
    flex-direction: row;
  } 
}

@media (width >= 1440px) {
  .hero {
    padding: 64px 80px;
  } 
}
  @media (width >= 768px) {
  .contacts-image {
    width: 210px;
    height: 270px;
  } 
}

@media (width >= 1440px) {
  .contacts-image {
    width: 280px;
    height: 360px;
  } 
}
</style>