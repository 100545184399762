<template>
  <div>
    <AppHeader />
    <div class="objects">
      <h1>Редактирование объекта</h1>
      <ObjectFormData
          v-model:objectName="objectName"
          v-model:objectLocation="objectLocation"
          v-model:selectedObjectTypeId="selectedObjectTypeId"
          v-model:objectSize="objectSize"
          v-model:objectPrice="objectPrice"
          v-model:objectVideo="objectVideo"
          :objectTypes="objectTypes"
          :suggestions="suggestions"
          @fetchSuggestions="fetchSuggestions"
          @selectSuggestion="selectSuggestion"
      />
    </div>

    <TextAreaField
        label="Описание объекта"
        id="object-description"
        v-model="objectDescription"
        placeholder="Введите описание"
        :maxLength="65535"
    />
    <CheckboxList
        title="Активности рядом"
        :items="activities"
        :modelValue="selectedActivityIds"
        @update:modelValue="selectedActivityIds = $event"
        prefix="activity"
    />
    <CheckboxList
        title="Удобства"
        :items="facilities"
        :modelValue="selectedFacilityIds"
        @update:modelValue="selectedFacilityIds = $event"
        prefix="facility"
    />

    <PhotoLoad @file-selected="handleFileSelection" />
    <div class="photo-wrapper">
      <div class="photo" v-for="(photo, index) in combinedPhotos" :key="photo.id || index">
        <button @click="markPhotoForDeletion(index, photo.id)" class="delete-button" :class="{ marked: isPhotoMarked(photo.id) }"></button>
        <img :src="photo.preview || `https://лк.домшеринг.рф/file/${photo.id}/${photo.fn}`" alt="photo" />
      </div>
    </div>

    <button class="btn padding" @click="updateObject">Сохранить изменения</button>

    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <div v-if="isLoading">
          <p>Сохранение изменений...</p>
          <div class="spinner"></div>
        </div>
        <div v-else>
          <p>Изменения отправлены на модерацию. Скоро Ваш объект появится на сайте.</p>
          <button class="btn" @click="closeModal">ОК</button>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import PhotoLoad from './PhotoLoad.vue';
import AppFooter from './AppFooter.vue';
import CheckboxList from './CheckboxList.vue';
import axios from 'axios';
import TextAreaField from "@/components/TextAreaField.vue";
import ObjectFormData from "@/components/ObjectFormData.vue";

export default {
  name: 'EditObject',
  components: {
    ObjectFormData,
    TextAreaField,
    AppHeader,
    AppFooter,
    PhotoLoad,
    CheckboxList
  },
  data() {
    return {
      objectId: this.$route.params.id,
      objectName: '',
      objectPrice: null,
      objectSize: null,
      selectedObjectTypeId: null,
      objectLocation: '',
      objectDescription: '',
      objectVideo: '',
      geoLat: null,
      geoLon: null,
      suggestions: [],
      //selectedActivities: [],
      selectedActivityIds: [],
      activityRecordIds: [],
      //selectedFacilities: [],
      selectedFacilityIds: [],
      facilityRecordIds: [],
      showModal: false,
      isLoading: false,
      photos: [],
      newPhotos: [],
      photosMarkedForDeletion: [],
      selectedFiles: [],
      //selectedFile: null,
      //selectedFileName: null, // Для хранения выбранного ID типа объекта
    };
  },
  computed: {
    activities() {
      return this.$store.getters.activities || [];
    },
    facilities() {
      return this.$store.getters.facilities || [];
    },
    objectTypes() {
      return this.$store.getters.objectTypes || [];
    },
    combinedPhotos() {
      return [...this.photos, ...this.newPhotos]; // Объединение массивов старых и новых фото
    }
  },
  methods: {
    async fetchObjectData() {
      const token = this.$store.getters.token;
      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/cot/${this.objectId}`, {
          headers: {
            'Token': token
          }
        });
        const object = response.data.data;
        this.objectName = object.nm;
        this.objectSize = object.size;
        this.objectLocation = object.location;
        this.objectPrice = object.price_dec;
        this.objectVideo = object.video;
        this.selectedObjectTypeId = object.obj_id;
        this.geoLat = object.latitude;
        this.geoLon = object.longitude;
        this.objectDescription = object.descr;
      } catch (error) {
        console.error('Ошибка при загрузке данных объекта:', error);
      }
    },
    async fetchPhotos() {
      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/fil?filters[cot_id-e]=${this.objectId}`);
        this.photos = response.data.data; // Загружаем фото в массив
      } catch (error) {
        console.error('Ошибка при загрузке фотографий:', error);
      }
    },
    /*markPhotoForDeletion(photoId) {
      if (!this.photosMarkedForDeletion.includes(photoId)) {
        this.photosMarkedForDeletion.push(photoId);
      } else {
        this.photosMarkedForDeletion = this.photosMarkedForDeletion.filter(id => id !== photoId);
      }
    },
    markPhotoForDeletion(index, photoId) {
      if (photoId) {
        // Если фото уже загружено, помечаем его для удаления
        if (!this.photosMarkedForDeletion.includes(photoId)) {
          this.photosMarkedForDeletion.push(photoId);
        } else {
          this.photosMarkedForDeletion = this.photosMarkedForDeletion.filter(id => id !== photoId);
        }
      } else {
        // Если фото новое, удаляем его из массива newPhotos
        this.newPhotos.splice(index - this.photos.length, 1); // Удаляем только новые фото по индексу
      }
    },*/
    markPhotoForDeletion(index, photoId) {
      if (photoId) {
        // Помечаем фото для удаления и удаляем его из массива `photos`
        if (!this.photosMarkedForDeletion.includes(photoId)) {
          this.photosMarkedForDeletion.push(photoId);
        }
        this.photos.splice(index, 1); // Удаляем фото из массива `photos`
      } else {
        // Если фото новое, удаляем его из массива `newPhotos`
        this.newPhotos.splice(index - this.photos.length, 1);
      }
    },
    isPhotoMarked(photoId) {
      return this.photosMarkedForDeletion.includes(photoId);
    },
    async createFileRecord(cot_id, filename) {
      const token = this.$store.getters.token;
      const userId = this.$store.getters.userId;
      const fileData = {
        nm: filename,
        own_usr_id: userId,
        cot_id: cot_id
      };
      try {
        const response = await axios.post(
            'https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/fil',
            fileData,
            {
              headers: {
                'Token': token,
                'Content-Type': 'application/json'
              }
            }
        );
        return response.data.data.id;
      } catch (error) {
        console.error('Ошибка при создании записи в fil:', error);
        throw error;
      }
    },
    async uploadFileToServer(file, fileId) {
      const token = this.$store.getters.token;
      try {
        const formData = new FormData();
        formData.append('up', file);

        await axios.post(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/upload/${fileId}`,
            formData,
            {
              headers: {
                'Token': token,
                'Content-Type': 'multipart/form-data'
              }
            }
        );

      } catch (error) {
        console.error('Ошибка при загрузке файла:', error);
      }
    },
    async loadSelectedActivities() {
      const token = this.$store.getters.token;
      try {
        const response = await axios.get(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/coa?filters[cot_id-e]=${this.objectId}`, {
          headers: {
            'Token': token
          }
        });

        const activityData = response.data.data;
        this.selectedActivityIds = activityData.map(activity => activity.atv_id);
        this.selectedActivities = this.activities.filter(activity => this.selectedActivityIds.includes(activity.id));
        this.activityRecordIds = activityData.map(activity => activity.id); // Сохранение массива id записей
      } catch (error) {
        console.error('Ошибка при загрузке связанных активностей:', error);
      }
    },
    async loadSelectedFacilities() {
      const token = this.$store.getters.token;
      try {
        const response = await axios.get(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/cof?filters[cot_id-e]=${this.objectId}`, {
          headers: {
            'Token': token
          }
        });

        const facilityData = response.data.data;
        this.selectedFacilityIds = facilityData.map(facility => facility.fac_id);
        this.selectedFacilities = this.facilities.filter(facility => this.selectedFacilityIds.includes(facility.id));
        this.facilityRecordIds = facilityData.map(facility => facility.id); // Сохранение массива id записей
      } catch (error) {
        console.error('Ошибка при загрузке связанных удобств:', error);
      }
    },
    async fetchSuggestions() {
      if (!this.objectLocation) {
        this.suggestions = [];
        return;
      }
      this.suggestions = await this.$store.dispatch('getDadataSuggestions', this.objectLocation);
    },
    selectSuggestion(suggestion) {
      this.objectLocation = suggestion.value; // Устанавливаем выбранный адрес
      this.geoLat = suggestion.data.geo_lat;  // Сохраняем широту
      this.geoLon = suggestion.data.geo_lon;  // Сохраняем долготу
      this.suggestions = []; // Очищаем список подсказок
      console.error(this.geoLat);
      console.error(this.geoLon);
    },
    handleFileSelection(fileData) {
      this.newPhotos.push(fileData);
      this.selectedFiles.push(fileData);
      // Добавляем новые фото в массив newPhotos
    },
    closeModal() {
      this.showModal = false;
      if (this.selectedObjectTypeId === 'apt') {
        this.$router.push({ name: 'RentalObjectsPage' }).then(() => {
          window.scrollTo(0, 0);
        });
      } else {
        const id = this.objectId;
        this.$router.push({ name: 'ViewObject', params: { id } }).then(() => {
          window.scrollTo(0, 0);
        });
      }
    },
    async updateObject() {
      this.showModal = true;
      this.isLoading = true;
      try {
        const token = this.$store.getters.token;
        const updatedObjectData = {
          nm: this.objectName,
          descr: this.objectDescription,
          location: this.objectLocation,
          size: this.objectSize,
          price_dec: this.objectPrice,
          video: this.objectVideo,
          obj_id: this.selectedObjectTypeId,
          sto_id: 'mod'
        };

        if (this.geoLat !== null) {
          updatedObjectData.latitude = this.geoLat;
        }

        if (this.geoLon !== null) {
          updatedObjectData.longitude = this.geoLon;
        }

        await axios.patch(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/cot/${this.objectId}`, updatedObjectData, {
          headers: {
            'Token': token,
            'Content-Type': 'application/json'
          }
        });

        for (const activityID of this.activityRecordIds) {
          await axios.delete(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/coa/${activityID}`, {
            headers: {
              'Token': token,
              'Content-Type': 'application/json'
            }
          });
        }

        for (const activityID of this.selectedActivityIds) {
          await axios.post('https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/coa', {
            cot_id: this.objectId,
            atv_id: activityID
          }, {
            headers: {
              'Token': token,
              'Content-Type': 'application/json'
            }
          });
        }

        for (const facilityID of this.facilityRecordIds) {
          await axios.delete(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/cof/${facilityID}`,{
            headers: {
              'Token': token,
              'Content-Type': 'application/json'
            }
          });
        }

        for (const facilityID of this.selectedFacilityIds) {
          await axios.post('https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/cof', {
            cot_id: this.objectId,
            fac_id: facilityID
          }, {
            headers: {
              'Token': token,
              'Content-Type': 'application/json'
            }
          });
        }

        for (const photoId of this.photosMarkedForDeletion) {
          await axios.delete(`https://лк.домшеринг.рф/api/fil/${photoId}`, {
            headers: {
              'Token': token,
              'Content-Type': 'application/json'
            }
          });
        }

        for (const fileData of this.selectedFiles) {
          const fileId = await this.createFileRecord(this.objectId, fileData.name);
          await this.uploadFileToServer(fileData.file, fileId);
        }

        this.isLoading = false;
      } catch (error) {
        console.error('Ошибка при обновлении объекта:', error);
        alert('Произошла ошибка при сохранении изменений.');
        this.isLoading = false;
        this.showModal = false;
      }
    }
  },
  mounted() {
    this.fetchObjectData();
    this.fetchPhotos();
    this.$store.dispatch('loadActivities').then(() => {
      this.loadSelectedActivities(); // Загружаем выбранные активности после загрузки списка всех активностей
    });
    this.$store.dispatch('loadFacilities').then(() => {
      this.loadSelectedFacilities(); // Загружаем выбранные активности после загрузки списка всех активностей
    });
    this.$store.dispatch('loadObjectTypes');
  }
};
</script>

<style scoped>

h1 {
  margin-bottom: 40px;
}

.form-field textarea {
  width: 100%;
  padding: 10px;
}

.preview-image {
  width: 100px;
  height: auto;
  margin-right: 10px;
}

.remove-button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.remove-button:hover {
  background-color: #c0392b;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  max-width: 300px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.btn {
  width: 295px;
  margin-left: auto;
  margin-right: auto;
}


.photo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 30px 20px;
}

@media (width >= 640px) {
  .photo-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}

@media (width >= 1000px) {
  .photo-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}


@media (width >= 1440px) {
  .photo-wrapper {
    padding: 40px 20px;
    grid-template-columns: repeat(4, 1fr);
    padding: 60px 80px;
  }
}

.btn {
  margin-left: auto;
  margin-right: auto;
}

.photo {
  position: relative;
}

.photo img {
  width: 280px;
  object-fit: cover;
}

.padding {
  margin: 0 auto 30px;
}

@media (width >= 768px) {
  .padding {
    margin: 0 auto 40px;
  }
}

@media (width >= 768px) {
  .padding {
    margin: 0 auto 60px;
  }
}

.objects {
  margin-top: 64px;
}

.delete-button {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: 2px solid #222222;
  border-radius: 5px;
  background-image: url("@/assets/cross.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: -13px;
  left: 0;
  cursor: pointer;
}
</style>