import { createStore } from 'vuex';
import axios from 'axios';

export default createStore({
  state: {
    dadataToken: '2467755542e320d99d52db07195776888a7e0366',
    token: localStorage.getItem('token') || null,
    userId: localStorage.getItem('userId') || null,
    userGroup: localStorage.getItem('userGroup') || null,
    objectTypes: [],
    activities: [],
    facilities: [],
    userParams: []
  },
  mutations: {
    SET_TOKEN(state, { token, exp_dt, user }){
      state.token = token;
      state.userId = user.id;
      state.userGroup = user.grp_id;

      localStorage.setItem('token', token);
      localStorage.setItem('tokenExpiration', exp_dt);
      localStorage.setItem('userId', user.id);
      localStorage.setItem('userGroup', user.grp_id);
    },
    LOGOUT(state) {
      state.token = null;
      state.userId = null;
      state.userGroup = null;
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiration');
      localStorage.removeItem('userId');
      localStorage.removeItem('userGroup');
    },
    SET_OBJECT_TYPES(state, objectTypes) {
      state.objectTypes = objectTypes;
    },
    SET_ACTIVITIES(state, activities) {
      state.activities = activities;
    },
    SET_FACILITIES(state, facilities) {
      state.facilities = facilities;
    },
    SET_USER_PARAMS(state, params) {
      state.userParams = params;
    },
  },
  actions: {
    async login({ commit }, credentials) {
      try {
        const response = await axios.post('https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/token', credentials, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = response.data;
        if (response.status === 200) {
          const { token, exp_dt, user } = data.data;
          commit('SET_TOKEN', { token, exp_dt, user });
          return true;
        }
        return false;
      } catch (error) {
        console.error('Ошибка при авторизации:', error);
        return false;
      }
    },
    logout({ commit }) {
      commit('LOGOUT');
    },
    async checkToken({ commit, state }) {
      const tokenExpiration = localStorage.getItem('tokenExpiration');
      const currentTime = new Date().toISOString();

      if (tokenExpiration && currentTime > tokenExpiration) {
        commit('LOGOUT');
        return false;  // Токен истек
      }

      return state.token;
    },
    async fetchData({ commit, state }, { url, mutation, stateKey }) {
      if (state[stateKey] && state[stateKey].length) return; // Если данные уже загружены, не загружаем снова
      try {
        const response = await axios.get(url, {
          headers: {
            'Content-Type': 'application/json'
          }
        });
        const data = response.data.data.map(item => ({
          id: item.id,
          name: item.nm
        }));
        commit(mutation, data);
      } catch (error) {
        console.error(`Ошибка при загрузке данных с ${url}:`, error);
      }
    },
    async loadObjectTypes({ dispatch }) {
      await dispatch('fetchData', {
        url: 'https://лк.домшеринг.рф/api/obj?sort=nm',
        mutation: 'SET_OBJECT_TYPES',
        stateKey: 'objectTypes'
      });
    },
    async loadActivities({ dispatch }) {
      await dispatch('fetchData', {
        url: 'https://лк.домшеринг.рф/api/atv?sort=nm',
        mutation: 'SET_ACTIVITIES',
        stateKey: 'activities'
      });
    },
    async loadFacilities({ dispatch }) {
      await dispatch('fetchData', {
        url: 'https://лк.домшеринг.рф/api/fac?sort=nm',
        mutation: 'SET_FACILITIES',
        stateKey: 'facilities'
      });
    },
    async getDadataSuggestions({ state }, query) {
      const url = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address';
      try {
        const response = await axios.post(url, { query }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Token ${state.dadataToken}`
          }
        });
        return response.data.suggestions || []; // Возвращаем подсказки или пустой массив
      } catch (error) {
        console.error('Ошибка при получении подсказок DaData:', error);
        return []; // Возвращаем пустой массив в случае ошибки
      }
    },
    async fetchUserParams({ commit, getters }) {
      const token = getters.token;
      const userId = getters.userId;

      try {
        const response = await axios.get(`https://лк.домшеринг.рф/api/ups?filters[usr_id-e]=${userId}`, {
          headers: {
            'Token': token,
            'Content-Type': 'application/json'
          }
        });

        commit('SET_USER_PARAMS', response.data.data); // Сохраняем весь набор данных как есть
      } catch (error) {
        console.error('Ошибка при загрузке параметров пользователя:', error);
      }
    }
  },
  getters: {
    isLoggedIn: state => !!state.token,
    userId: state => state.userId,
    userGroup: state => state.userGroup,
    token: state => state.token,
    objectTypes: state => state.objectTypes,
    activities: state => state.activities,
    facilities: state => state.facilities,
    userParams: state => state.userParams,
    dadataToken: state => state.dadataToken,
  }
});