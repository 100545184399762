<template>
  <div>
    <AppHeader />

    <div class="objects">
      <h1>Новый объект</h1>
      <ObjectFormData
          v-model:objectName="objectName"
          v-model:objectLocation="objectLocation"
          v-model:selectedObjectTypeId="selectedObjectTypeId"
          v-model:objectSize="objectSize"
          v-model:objectPrice="objectPrice"
          v-model:objectVideo="objectVideo"
          :objectTypes="objectTypes"
          :suggestions="suggestions"
          @fetchSuggestions="fetchSuggestions"
          @selectSuggestion="selectSuggestion"
      />
    </div>

    <TextAreaField
        label="Описание объекта"
        id="object-description"
        v-model="objectDescription"
        placeholder="Введите описание"
        :maxLength="65535"
    />

    <CheckboxList
        title="Активности рядом"
        :items="activities"
        :modelValue="selectedActivityIds"
        @update:modelValue="selectedActivityIds = $event"
        prefix="activity"
    />
    <CheckboxList
        title="Удобства"
        :items="facilities"
        :modelValue="selectedFacilityIds"
        @update:modelValue="selectedFacilityIds = $event"
        prefix="facility"
    />

    <PhotoLoad @file-selected="handleFileSelection" />
    <div class="photo-wrapper">
      <div class="photo" v-for="(photo, index) in selectedFiles" :key="photo.id || index">
        <img :src="photo.preview" alt="photo" />
        <button @click="removeFile(index)" class="delete-button">✖</button>
      </div>
    </div>

    <div class="btn-wrapper">
      <button class="btn" @click="saveObject('draft')">Сохранить черновик</button>
      <button class="btn btn-moderation" @click="saveObject('mod')">Отправить на модерацию</button>
    </div>
    <div v-if="showModal" class="modal-overlay">
      <div class="modal-content">
        <div v-if="isLoading">
          <p>Создание объекта...</p>
          <div class="spinner"></div>
        </div>
        <div v-else>
          <p>Объект успешно создан!</p>
          <button class="btn" @click="closeModal">ОК</button>
        </div>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import PhotoLoad from './PhotoLoad.vue';
import AppFooter from './AppFooter.vue';
import CheckboxList from './CheckboxList.vue';
import TextAreaField from "./TextAreaField.vue";
import ObjectFormData from "@/components/ObjectFormData.vue";
import axios from 'axios';

export default {
  name: 'AddObject',
  components: {
    AppHeader,
    AppFooter,
    PhotoLoad,
    CheckboxList,
    TextAreaField,
    ObjectFormData
  },
  data() {
    return {
      userId: null,
      objectName: '',
      objectPrice: null,
      objectSize: null,
      objectVideo: '',
      selectedObjectType: '',
      objectLocation: '',
      originalUserData: {},
      suggestions: [],  // Подсказки Dadata
      errorMessage: '',
      selectedActivities: [], // Выбранные активности
      selectedActivityIds: [],
      selectedFacilityIds: [], // Выбранные активности
      geoLat: null, // Широта
      geoLon: null,  // Долгота
      selectedObjectTypeId: null,
      selectedFiles: [],
      selectedFile: null,
      selectedFileName: null, // Для хранения выбранного ID типа объекта
      showModal: false,
      isLoading: false,
      objectId: null,
      objectDescription: "", // Содержимое описания
      textPreview: "", // Предпросмотр описания
    };
  },
  computed: {
    activities() {
      return this.$store.getters.activities || [];
    },
    facilities() {
      return this.$store.getters.facilities || [];
    },
    objectTypes() {
      return this.$store.getters.objectTypes || [];
    }
  },
  methods: {
    handleFileSelection(fileData) {
      this.selectedFiles.push(fileData); // Добавляем полученные файлы в массив
    },
    removeFile(index) {
      this.selectedFiles.splice(index, 1); // Удаляем файл из массива по индексу
    },
    closeModal() {
      this.showModal = false;
      if (this.selectedObjectTypeId === 'apt') {
        this.$router.push({ name: 'RentalObjectsPage' }).then(() => {
          window.scrollTo(0, 0);
        });
      } else {
        const id = this.objectId;
        this.$router.push({ name: 'ViewObject', params: { id } }).then(() => {
          window.scrollTo(0, 0);
        });
      }
    },
    async createFileRecord(cot_id, filename) {
      const token = this.$store.getters.token;
      const userId = this.$store.getters.userId;
      const fileData = {
        nm: filename,
        own_usr_id: userId,
        cot_id: cot_id
      };
      try {
        const response = await axios.post(
            'https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/fil',
            fileData,
            {
              headers: {
                'Token': token,
                'Content-Type': 'application/json'
              }
            }
        );
        return response.data.data.id;
      } catch (error) {
        console.error('Ошибка при создании записи в fil:', error);
        throw error;
      }
    },
    async uploadFileToServer(file, fileId) {
      const token = this.$store.getters.token;
      try {
        const formData = new FormData();
        formData.append('up', file);

        await axios.post(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/upload/${fileId}`,
            formData,
            {
              headers: {
                'Token': token,
                'Content-Type': 'multipart/form-data'
              }
            }
        );

      } catch (error) {
        console.error('Ошибка при загрузке файла:', error);
      }
    },
    async loadActivities() {
      try {
        const response = await axios.get('https://лк.домшеринг.рф/api/atv?sort=nm');
        this.activities = response.data.data.map(activity => ({
          id: activity.id,
          name: activity.nm
        }));
      } catch (error) {
        console.error('Ошибка при загрузке активностей:', error);
      }
    },
    async loadFacilities() {
      try {
        const response = await axios.get('https://лк.домшеринг.рф/api/fac?sort=nm');
        // Сохраняем названия и ID удобств
        this.facilities = response.data.data.map(facility => ({
          id: facility.id, // Сохраняем ID
          name: facility.nm // Сохраняем название
        }));
      } catch (error) {
        console.error('Ошибка при загрузке удобств:', error);
      }
    },
    async loadObjectTypes() {
      try {
        const response = await axios.get('https://лк.домшеринг.рф/api/obj?sort=nm');
        this.objectTypes = response.data.data.map(object => ({
          id: object.id,
          name: object.nm
        }));
      } catch (error) {
        console.error('Ошибка при загрузке типов объектов:', error);
      }
    },
    async fetchSuggestions() {
      if (!this.objectLocation) {
        this.suggestions = [];
        return;
      }
      this.suggestions = await this.$store.dispatch('getDadataSuggestions', this.objectLocation);
    },
    selectSuggestion(suggestion) {
      this.objectLocation = suggestion.value; // Устанавливаем выбранный адрес
      this.geoLat = suggestion.data.geo_lat;  // Сохраняем широту
      this.geoLon = suggestion.data.geo_lon;  // Сохраняем долготу
      this.suggestions = []; // Очищаем список подсказок
      console.error(this.geoLat);
      console.error(this.geoLon);
    },
    async saveObject(sto_id) {
      this.showModal = true;
      this.isLoading = true;
      try {
        const token = this.$store.getters.token;
        const userId = this.$store.getters.userId;
        const objectTypeId = this.selectedObjectTypeId;
        const editorContent = this.objectDescription;

        // 1. Сохранение объекта
        const objectData = {
          nm: this.objectName,
          descr: editorContent,
          location: this.objectLocation,
          price_dec: this.objectPrice,
          size: this.objectSize,
          video: this.objectVideo,
          own_usr_id: userId,
          obj_id: objectTypeId
        };

        // Условно добавляем координаты, если они не равны null
        if (this.geoLat !== null) {
          objectData.latitude = this.geoLat;
        }

        if (this.geoLon !== null) {
          objectData.longitude = this.geoLon;
        }

        const objectResponse = await axios.post('https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/cot', objectData, {
          headers: {
            'Token': token,
            'Content-Type': 'application/json'
          }
        });

        const objectId = objectResponse.data.data.id;
        this.objectId = objectId;

        // 2. Добавление связей с активностями
        for (const activityID of this.selectedActivityIds) {
          await axios.post('https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/coa', {
            cot_id: objectId,
            atv_id: activityID
          }, {
            headers: {
              'Token': token,
              'Content-Type': 'application/json'
            }
          });
        }

        for (const facilityID of this.selectedFacilityIds) {
          await axios.post('https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/cof', {
            cot_id: objectId,
            fac_id: facilityID
          }, {
            headers: {
              'Token': token,
              'Content-Type': 'application/json'
            }
          });
        }

        for (const fileData of this.selectedFiles) {
          const fileId = await this.createFileRecord(objectId, fileData.name);
          await this.uploadFileToServer(fileData.file, fileId);
        }

        if (sto_id === 'mod') {
          await axios.patch(
              `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/cot/${this.objectId}`,
              { sto_id: 'mod' },
              {
                headers: {
                  'Token': token,
                  'Content-Type': 'application/json'
                }
              }
          );
        }

        this.isLoading = false;
      } catch (error) {
        console.error('Ошибка при сохранении объекта:', error);
        alert('Произошла ошибка при сохранении объекта.');
        this.isLoading = false;
        this.showModal = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch('loadActivities');
    this.$store.dispatch('loadFacilities');
    this.$store.dispatch('loadObjectTypes');
  }
};
</script>

<style scoped>



h1 {
  margin-bottom: 40px;
}

.form-field textarea {
  width: 100%;
  padding: 10px;
}

.preview-image {
  width: 100px;
  height: auto;
  margin-right: 10px;
}

.remove-button {
  background-color: #e74c3c;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 10px;
}

.remove-button:hover {
  background-color: #c0392b;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@media (width < 768px) {
  .btn {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}

@media (width >= 768px) {
  .btn {
    width: 330px;
  }
}

.btn-moderation {
  background-color: #193190;
}

.photo-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding: 30px 20px;
}

@media (width >= 640px) {
  .photo-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }
}

@media (width >= 1000px) {
  .photo-wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
}


@media (width >= 1440px) {
  .photo-wrapper {
    padding: 40px 20px;
    grid-template-columns: repeat(4, 1fr);
    padding: 20px 80px;
  }
}

.photo {
  position: relative;
}

.photo img {
  width: 280px;
  object-fit: cover;
}

.padding {
  margin: 0 auto 30px;
}

@media (width >= 768px) {
  .padding {
    margin: 0 auto 40px;
  }
}

@media (width >= 768px) {
  .padding {
    margin: 0 auto 60px;
  }
}

.objects {
  margin-top: 64px;
}

.delete-button {
  width: 30px;
  height: 30px;
  background-color: #ffffff;
  border: 2px solid #222222;
  border-radius: 5px;
  background-image: url("@/assets/cross.svg");
  background-size: 18px;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: -13px;
  left: 0;
  cursor: pointer;
}

.btn-wrapper {
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}

@media (width >= 768px) {
  .btn-wrapper  {
    padding: 30px 80px;
    justify-content: space-between;
    flex-direction: row;
  }
}

@media (width >= 1440px) {
  .btn-wrapper  {
    padding: 60px 80px;
  }
}
</style>