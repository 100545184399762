<template>
  <div class="objects">
    <h1>Личные данные</h1>
    <div class="form">
      <div class="form-field-wrapper">
        <div class="form-field">
          <p class="text">Имя</p>
          <input type="text" v-model="userName" placeholder="Введите ваше имя" />
        </div>
        <div class="form-field">
          <p class="text">Телефон</p>
          <input type="text" v-model="userPhone" placeholder="Введите ваш телефон" />
        </div>
        <div class="form-field">
          <p class="text">Email</p>
          <input type="email" v-model="userEmail" placeholder="Введите ваш email" />
        </div>
        <div class="form-field form-field-address">
          <p class="text">Населенный пункт</p>
          <input type="text" v-model="userLocation" placeholder="Введите населенный пункт" @input="fetchDadataSuggestions" />
          <!-- Подсказки DaData -->
          <ul v-if="suggestions.length" class="suggestions-list">
            <li v-for="(suggestion, index) in suggestions" :key="index" @click="selectSuggestion(suggestion.value)">
              {{ suggestion.value }}
            </li>
          </ul>
        </div>
        <!-- Ошибки валидации -->
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        <button class="btn" :disabled="!isFormValid" @click="saveChanges">Сохранить изменения</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProfilePersonalData',
  data() {
    return {
      userId: null,
      userName: '',
      userPhone: '',
      userEmail: '',
      userLocation: '',
      suggestions: [], // Подсказки DaData
      errorMessage: ''
    };
  },
  computed: {
    isFormValid() {
      const onlyDigits = /^\+7\d{10}$/; // Регулярное выражение для проверки формата телефона
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Регулярное выражение для проверки email
      return (
          this.userName && this.userEmail && this.userPhone && this.userLocation &&
          onlyDigits.test(this.userPhone) && emailPattern.test(this.userEmail)
      );
    }
  },
  methods: {
    async fetchUserProfile() {
      try {
        const token = this.$store.getters.token;
        const response = await axios.get('https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/usr', {
          headers: { 'Token': token }
        });
        if (response.data) {
          const userData = response.data.data[0];
          this.userId = userData.id;
          this.userName = userData.nm || '';
          this.userPhone = userData.phone || '';
          this.userEmail = userData.mail || '';
          this.userLocation = userData.remark_tx || '';
        }
      } catch (error) {
        console.error('Ошибка при загрузке данных профиля:', error);
      }
    },
    async fetchDadataSuggestions() {
      if (!this.userLocation) {
        this.suggestions = [];
        return;
      }
      this.suggestions = await this.$store.dispatch('getDadataSuggestions', this.userLocation);
    },
    selectSuggestion(value) {
      this.userLocation = value;
      this.suggestions = [];
    },
    async saveChanges() {
      if (!this.isFormValid) return;

      try {
        const token = this.$store.getters.token;
        const payload = {
          nm: this.userName,
          mail: this.userEmail,
          phone: this.userPhone,
          remark_tx: this.userLocation
        };
        const response = await axios.patch(`https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/usr/${this.userId}`, payload, {
          headers: { 'Token': token, 'Content-Type': 'application/json' }
        });
        if (response.status === 200) {
          alert('Изменения успешно сохранены');
          this.fetchUserProfile(); // Обновляем данные профиля
        }
      } catch (error) {
        console.error('Ошибка при сохранении данных:', error);
        alert('Произошла ошибка при сохранении данных');
      }
    }
  },
  mounted() {
    this.fetchUserProfile();
  }
};
</script>

<style scoped>
h1 {
  margin-bottom: 40px;
}
.btn {
  width: 100%;
}

/* Общие стили для полей формы */
.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.form-field-wrapper {
  align-items: end;
}

.objects {
  margin-top: 64px;
}

</style>