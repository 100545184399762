<template>
  <div class="objects">
    <h1>Данные об организации</h1>
    <div class="form">
      <div class="form-field-wrapper">
        <div class="form-field">
          <p class="text">ИНН</p>
          <input type="text" v-model="userInn" placeholder="ИНН" @change="fetchCompanyData" />
          <p v-if="!userInn" class="hint">Введите ИНН, чтобы автозаполнить данные об организации</p>
        </div>
        <div class="form-field">
          <p class="text">КПП</p>
          <input type="text" v-model="userKpp" placeholder="КПП" />
        </div>
        <div class="form-field">
          <p class="text">ОГРН</p>
          <input type="text" v-model="userOgrn" placeholder="ОГРН" />
        </div>
        <div class="form-field">
          <p class="text">ОКАТО</p>
          <input type="text" v-model="userOkato" placeholder="ОКАТО" />
        </div>
        <div class="form-field">
          <p class="text">Почтовый адрес</p>
          <input type="text" v-model="userPostalAddr" placeholder="Почтовый адрес" />
        </div>
        <div class="form-field">
          <p class="text">Юридический адрес</p>
          <input type="text" v-model="userUrAddr" placeholder="Юридический адрес" />
        </div>
        <div class="form-field">
          <p class="text">Полное наименование</p>
          <input type="text" v-model="userCompanyName" placeholder="Полное наименование" />
        </div>

        <button class="btn" :disabled="!isFormValid" @click="saveChanges">Сохранить изменения</button>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'ProfileCompanyData',
  computed: {
    organizationParams() {
      return this.$store.getters.userParams;
    },
    isFormValid() {
      const onlyDigits = /^\d+$/;
      return (
          this.userInn && onlyDigits.test(this.userInn) &&
          this.userKpp && onlyDigits.test(this.userKpp) &&
          this.userOgrn && onlyDigits.test(this.userOgrn) &&
          this.userOkato && onlyDigits.test(this.userOkato) &&
          this.userCompanyName && this.userPostalAddr && this.userUrAddr
      );
    }
  },
  data() {
    return {
      userInn: '',
      userKpp: '',
      userOgrn: '',
      userOkato: '',
      userPostalAddr: '',
      userUrAddr: '',
      userCompanyName: '',
      errorMessage: ''
    };
  },
  methods: {
    async loadOrganizationParams() {
      if (!this.organizationParams.length) {
        await this.$store.dispatch('fetchUserParams');
      }
      this.initializeFormFields();
    },
    initializeFormFields() {
      const innParam = this.organizationParams.find(param => param.dpt_id === 'INN');
      const kppParam = this.organizationParams.find(param => param.dpt_id === 'kpp');
      const ogrnParam = this.organizationParams.find(param => param.dpt_id === 'ogrn');
      const okatoParam = this.organizationParams.find(param => param.dpt_id === 'okato');
      const postalAddrParam = this.organizationParams.find(param => param.dpt_id === 'postaddr');
      const urAddrParam = this.organizationParams.find(param => param.dpt_id === 'uraddr');
      const companyNameParam = this.organizationParams.find(param => param.dpt_id === 'orgname');

      this.userInn = innParam ? innParam.val : '';
      this.userKpp = kppParam ? kppParam.val : '';
      this.userOgrn = ogrnParam ? ogrnParam.val : '';
      this.userOkato = okatoParam ? okatoParam.val : '';
      this.userPostalAddr = postalAddrParam ? postalAddrParam.val : '';
      this.userUrAddr = urAddrParam ? urAddrParam.val : '';
      this.userCompanyName = companyNameParam ? companyNameParam.val : '';
    },
    async fetchCompanyData() {
      const dadataToken = this.$store.getters.dadataToken;
      try {
        const response = await axios.post(
            'https://suggestions.dadata.ru/suggestions/api/4_1/rs/findById/party',
            { query: this.userInn },
            {
              headers: {
                Authorization: `Token ${dadataToken}`,
                'Content-Type': 'application/json'
              }
            }
        );

        const companyData = response.data.suggestions[0].data;
        this.userKpp = companyData.kpp || '';
        this.userOgrn = companyData.ogrn || '';
        this.userOkato = companyData.okato || '';
        this.userPostalAddr = companyData.address.data.postal_code || '';
        this.userUrAddr = companyData.address.data.source || '';
        this.userCompanyName = companyData.name.full_with_opf || '';
      } catch (error) {
        console.error('Ошибка при получении данных компании:', error);
        this.errorMessage = 'Не удалось получить данные компании по ИНН';
      }
    },
    async saveChanges() {
      try {
        const token = this.$store.getters.token;
        const userId = this.$store.getters.userId;

        const payload = [
          { dpt_id: 'INN', val: this.userInn },
          { dpt_id: 'kpp', val: this.userKpp },
          { dpt_id: 'ogrn', val: this.userOgrn },
          { dpt_id: 'okato', val: this.userOkato },
          { dpt_id: 'postaddr', val: this.userPostalAddr },
          { dpt_id: 'uraddr', val: this.userUrAddr },
          { dpt_id: 'orgname', val: this.userCompanyName }
        ];

        const existingParams = this.organizationParams || [];

        for (const param of payload) {
          const existingParam = existingParams.find(item => item.dpt_id === param.dpt_id);

          if (existingParam) {
            await axios.patch(
                `https://лк.домшеринг.рф/api/ups/${existingParam.id}`,
                { val: param.val },
                {
                  headers: {
                    'Token': token,
                    'Content-Type': 'application/json'
                  }
                }
            );
          } else {
            await axios.post(
                'https://лк.домшеринг.рф/api/ups',
                {
                  dpt_id: param.dpt_id,
                  usr_id: userId,
                  val: param.val
                },
                {
                  headers: {
                    'Token': token,
                    'Content-Type': 'application/json'
                  }
                }
            );
          }
        }

        alert('Изменения успешно сохранены');
      } catch (error) {
        console.error('Ошибка при сохранении данных:', error);
        alert('Произошла ошибка при сохранении данных');
      }
    }
  },
  mounted() {
    this.loadOrganizationParams();
  }
};
</script>

<style scoped>
h1 {
  margin-bottom: 40px;
}

.btn {
  width: 100%;
}

@media (width >= 768px) {
  .btn {
    margin-top: auto;
  }
}

.text {
  font-size: 16px;
  font-weight: 400;
  color: #686868;
  margin: 0;
}

/* Общие стили для полей формы */
.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.objects {
  margin-top: 64px;
}

</style>