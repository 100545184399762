<template>
  <div>
    <AppHeader />
    <ProfileCompanyData />
    <ProfileBankDetails />
    <ProfilePersonalData />
    <ProfileChangePassword />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import ProfileBankDetails from './ProfileBankDetails.vue';
import ProfileCompanyData from './ProfileCompanyData.vue';
import ProfilePersonalData from './ProfilePersonalData.vue';
import ProfileChangePassword from './ProfileChangePassword.vue';

export default {
  name: 'RentalProfile',
  components: {
    AppHeader,
    AppFooter,
    ProfileBankDetails,
    ProfileCompanyData,
    ProfilePersonalData,
    ProfileChangePassword
  }
};
</script>

<style scoped>

h1 {
  margin-bottom: 40px;
}
.btn {
  width: 100%;
}

.text {
  font-size: 16px;
  font-weight: 400;
  color: #686868;
  margin: 0;
}

/* Общие стили для полей формы */
.form-field {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

@media (width < 768px) {
  .objects {
    margin-top: 64px;
    margin-bottom: 30px;
  }
}

</style>