<template>
  <div class="objects">
    <h2>{{ title }}</h2>
    <button class="btn" @click="toggleSelectAll">
      {{ allSelected ? 'Снять выделение' : 'Выбрать все' }}
    </button>
    <br/>
    <div id="v-model-multiple-checkboxes" class="checkbox-wrapper">
      <div class="checkbox-list" v-if="items.length > 0">
        <div v-for="item in items" :key="item.id" class="checkbox-item">
          <input
              type="checkbox"
              :id="`${prefix}-${item.id}`"
              :value="item.id"
              :checked="modelValue.includes(item.id)"
              @change="handleCheckboxChange($event, item.id)"
              class="checkbox-input"
          />
          <label :for="`${prefix}-${item.id}`" class="checkbox-text">{{ item.name }}</label>
        </div>
      </div>
      <p v-else>Данные отсутствуют</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CheckboxList',
  props: {
    title: { type: String, required: true },
    items: { type: Array, required: true },
    modelValue: { type: Array, required: true },
    prefix: { type: String, default: 'item' }, // Для уникальности `id`
  },
  emits: ['update:modelValue'],
  computed: {
    allSelected() {
      return this.items.length > 0 && this.items.every(item => this.modelValue.includes(item.id));
    },
  },
  methods: {
    handleCheckboxChange(event, itemId) {
      const newValue = [...this.modelValue];

      if (event.target.checked) {
        newValue.push(itemId);
      } else {
        const index = newValue.indexOf(itemId);
        if (index > -1) {
          newValue.splice(index, 1);
        }
      }

      this.$emit('update:modelValue', newValue);
    },
    toggleSelectAll() {
      if (this.allSelected) {
        this.$emit('update:modelValue', []);
      } else {
        const allIds = this.items.map(item => item.id);
        this.$emit('update:modelValue', allIds);
      }
    },
  },
};
</script>

<style scoped>

@media (width >= 768px) {
  .btn {
    width: 226px;
  }
}

@media (width >= 768px) {
  .btn-all {
    background-color: #DF3939;
  }
}

@media (width >= 768px) {
  .btn-add {
    width: 226px;
    background-color: #199057;
  }
}

</style>