<template>
  <div>
    <AppHeader />
    <ProfilePersonalData />
    <ProfileChangePassword />
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';
import ProfilePersonalData from './ProfilePersonalData.vue';
import ProfileChangePassword from './ProfileChangePassword.vue';

export default {
  name: 'TenantProfile',
  components: {
    AppHeader,
    AppFooter,
    ProfilePersonalData,
    ProfileChangePassword
  }
};
</script>

<style scoped>

</style>