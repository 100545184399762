<template>
  <div class="form">
    <div class="form-field-wrapper">

      <!-- Название объекта -->
      <div class="form-field">
        <p class="text">Название</p>
        <input
            type="text"
            :value="objectName"
            @input="$emit('update:objectName', $event.target.value)"
            placeholder="Введите название объекта"
        />
      </div>

      <!-- Адрес с подсказками -->
      <div class="form-field form-field-address">
        <p class="text">Адрес</p>
        <input
            type="text"
            :value="objectLocation"
            @input="onAddressInput"
            placeholder="Введите адрес"
        />
        <ul v-if="suggestions.length" class="suggestions-list">
          <li
              v-for="suggestion in suggestions"
              :key="suggestion.value"
              @click="$emit('selectSuggestion', suggestion)"
          >
            {{ suggestion.value }}
          </li>
        </ul>
      </div>


      <!-- Тип объекта -->
      <div class="form-field">
        <p class="text">Тип объекта</p>
        <select
            :value="selectedObjectTypeId"
            @change="$emit('update:selectedObjectTypeId', $event.target.value)"
        >
          <option v-for="type in objectTypes" :key="type.id" :value="type.id">
            {{ type.name }}
          </option>
        </select>
      </div>

      <!-- Вместимость -->
      <div class="form-field">
        <p class="text">Вместимость</p>
        <input
            type="number"
            :value="objectSize"
            @input="$emit('update:objectSize', $event.target.value)"
            placeholder="Введите вместимость"
        />
      </div>

      <!-- Цена -->
      <div class="form-field">
        <p class="text">Цена</p>
        <input
            type="number"
            :value="objectPrice"
            @input="$emit('update:objectPrice', $event.target.value)"
            placeholder="Введите цену"
        />
      </div>

      <!-- Ссылка на видео -->
      <div class="form-field">
        <p class="text">Ссылка на видео YouTube/VK</p>
        <input
            type="text"
            :value="objectVideo"
            @input="$emit('update:objectVideo', $event.target.value)"
            placeholder="Введите ссылку на видео"
        />
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'ObjectFormData',
  props: {
    objectName: { type: String, required: true },
    objectLocation: { type: String, required: true },
    selectedObjectTypeId: { type: [String, Number], required: true },
    objectSize: { type: Number, required: true },
    objectPrice: { type: Number, required: true },
    objectVideo: { type: String, required: true },
    objectTypes: { type: Array, required: true },
    suggestions: { type: Array, default: () => [] },
  },
  emits: [
    'update:objectName',
    'update:objectLocation',
    'update:selectedObjectTypeId',
    'update:objectSize',
    'update:objectPrice',
    'update:objectVideo',
    'fetchSuggestions',
    'selectSuggestion'
  ],
  methods: {
    onAddressInput(event) {
      const value = event.target.value;
      this.$emit('update:objectLocation', value); // Обновляем значение адреса
      this.$emit('fetchSuggestions', value);      // Вызываем метод для получения подсказок
    }
  },
};
</script>

<style scoped>

</style>