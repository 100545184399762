<template>
  <div class="objects text-area-field">
    <h2><label :for="id" >{{ label }}</label></h2>
    <textarea
        :id="id"
        v-model="inputValue"
        :placeholder="placeholder"
        :rows="10"
        @input="handleInput"
    ></textarea>
    <div class="char-count">
      {{ currentLength }} / {{ maxLength }}
    </div>
  </div>
</template>

<script>
export default {
  name: "TextAreaField",
  props: {
    label: { type: String, required: true },
    id: { type: String, required: true },
    modelValue: { type: String, required: true },
    placeholder: { type: String, default: "" },
    maxLength: { type: Number, default: 65535 }, // Максимальный размер для поля TEXT в MySQL
  },
  emits: ["update:modelValue"],
  computed: {
    currentLength() {
      return this.inputValue.length;
    },
  },
  data() {
    return {
      inputValue: this.modelValue,
    };
  },
  watch: {
    modelValue(newValue) {
      this.inputValue = newValue;
    },
    inputValue(newValue) {
      this.$emit("update:modelValue", newValue);
    },
  },
  methods: {
    handleInput(event) {
      const value = event.target.value;
      if (value.length <= this.maxLength) {
        this.inputValue = value;
      } else {
        this.inputValue = value.slice(0, this.maxLength);
      }
    },
  },
};
</script>

<style scoped>
.text-area-field label {
  margin-bottom: 10px;
  font-weight: bold;
}

textarea {
  width: 97%;
  resize: vertical;
  padding: 18px 20px;
  font-size: 16px;
  border: 1px solid #D5D6DE;
  border-radius: 10px;
  font-family: inherit;
}

@media (width < 768px) {
  textarea {
    font-size: 12px;
    width: 92%;
  }
}

.char-count {
  margin-top: 5px;
  font-size: 14px;
  color: #555;
  text-align: right;
}

@media (width < 768px) {
  .char-count {
    margin-left: auto;
  }
}
</style>