<template>
  <BaseForm @submit="confirmRegistration" title="Подтверждение регистрации">
    <!-- Ошибки -->
    <ErrorMessage v-if="errorMessage" :message="errorMessage" />

    <!-- Кнопка подтверждения -->
    <div class="btn-wrapper">
      <BaseButton type="submit" :disabled="isLoading">
        {{ isLoading ? "Подтверждение..." : "Подтвердить регистрацию" }}
      </BaseButton>
    </div>

    <!-- Модальное окно -->
    <BaseModal v-if="isLoading || successMessage" @close="handleModalClose">
      <template #header>
        Подтверждение регистрации
      </template>
      <template #body>
        <div v-if="isLoading" class="spinner-wrapper">
          <div class="spinner"></div>
          <p>Подтверждаем вашу регистрацию...</p>
        </div>
        <div v-else>
          <p>Регистрация подтверждена. Сейчас вы будете перенаправлены на вход.</p>
        </div>
      </template>
    </BaseModal>
  </BaseForm>
</template>

<script>
import BaseForm from "@/components/BaseForm.vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseModal from "@/components/BaseModal.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import axios from "axios";

export default {
  name: "ConfirmRegistrationForm",
  components: {
    BaseForm,
    BaseButton,
    BaseModal,
    ErrorMessage,
  },
  props: {
    login: { type: String, required: true },
    code: { type: String, required: true },
  },
  data() {
    return {
      errorMessage: "", // Сообщение об ошибке
      successMessage: false, // Успешный результат
      isLoading: false, // Состояние загрузки
    };
  },
  methods: {
    async confirmRegistration() {
      if (this.isLoading) return;

      this.errorMessage = "";
      this.successMessage = false;
      this.isLoading = true;

      try {
        const response = await axios.get(
            `https://xn--j1ab.xn--c1abdmsids4e.xn--p1ai/api/register`,
            {
              params: {
                login: this.login,
                code: this.code,
              },
            }
        );

        if (response.status === 200) {
          this.successMessage = true;
          setTimeout(this.redirectToLogin, 3000);
        } else {
          this.errorMessage = "Ошибка подтверждения регистрации. Попробуйте снова.";
        }
      } catch (error) {
        this.errorMessage = "Произошла ошибка сети. Проверьте подключение к интернету.";
        console.error("Ошибка подтверждения регистрации:", error);
      } finally {
        this.isLoading = false;
      }
    },
    redirectToLogin() {
      this.$router.push({ name: "LoginPage" });
    },
    handleModalClose() {
      if (this.successMessage) {
        this.redirectToLogin();
      } else {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.spinner-wrapper {
  text-align: center;
}

.spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3; /* Светлая часть */
  border-top: 4px solid #3498db; /* Синяя часть */
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 0 auto 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-wrapper {
  margin-top: 20px;
}
</style>