<template>
  <div>
    <AppHeader />
    <div class="hero">
      <div class="hero-wrapper">
        <h1>Вы не авторизованы!</h1>
        <p class="description">Войдите в систему для дальнейшей работы. <br>Если у вас еще нет аккаунта, то зарегистрируйтесь.</p>
      </div>
      <img class="not-authorized-image" src="@/assets/not_authorized.svg" width="144" height="200" alt="" />
    </div>
    <div class="light-wrapper">
      <div class="dark-wrapper">
        <button class="btn" @click="goToLogin">Вход</button>
        <button class="btn btn-light" @click="goToRegistration">Регистрация</button>
      </div>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from './AppHeader.vue';
import AppFooter from './AppFooter.vue';

export default {
  name: 'NotAuthorized',
  components: {
    AppHeader,
    AppFooter,
  },
  methods: {
    goToLogin() {
      this.$router.push({ name: 'LoginPage' });  // Переход на страницу входа
    },
    goToRegistration() {
      this.$router.push({ name: 'RegistrationPage' });  // Переход на страницу регистрации
    }
  }
};
</script>

<style scoped>

  h1 {
    color: #199057;
  }

  .hero {
  padding: 50px 20px 0;
  flex-direction: column;
}

@media (width >= 768px) {
  .hero {
    padding: 80px 70px 0 50px;
    flex-direction: row;
  } 
}

@media (width >= 1440px) {
  .hero {
    padding: 64px 80px 0;
  } 
}

  @media (width < 768px) {
  .dark-wrapper {
    flex-direction: column;
  } 
}

  .dark-wrapper {
    gap: 20px;
  }
 

  .btn {
    width: 100%;
  }

  @media (width >= 768px) {
  .not-authorized-image {
    width: 216px;
    height: 300px;
  } 
}

@media (width >= 1440px) {
  .not-authorized-image {
    width: 316px;
    height: 436px;
  } 
}
</style>