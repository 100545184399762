<template>
  <div>
    <AppHeader />
    <div class="hero">
      <div class="hero-wrapper">
        <h1>Бронирование жилья: </h1>
        <p class="description">квартиры, коттеджи, глэмпинги, кемпинги и т. д.</p>
      </div>
      <img class="objects-img" src="@/assets/objects_hero.svg" alt="" />
    </div>
    <SearchForm :initialFilters="filters" @apply-filters="applyFilters" />

    <!-- Переключатель между режимами списка и карты -->
    <div class="view-toggle">
      <button class="btn btn-left" @click="isMapView = false" :class="{ active: !isMapView }">Список</button>
      <button class="btn btn-right" @click="isMapView = true" :class="{ active: isMapView }">Карта</button>
    </div>

    <!-- Режим списка -->
    <section v-if="!isMapView" class="objects">
      <h2>{{ areFiltersApplied ? 'Найденные объекты' : 'Самые популярные места' }}</h2>
      <ObjectList :objects="objects" />
    </section>

    <!-- Режим карты -->
    <section v-else class="map-section">
      <div id="map" class="map"></div>
    </section>

    <AppFooter />
  </div>
</template>

<script>
import axios from 'axios';
import L from 'leaflet'; // Импортируем Leaflet
import "leaflet.markercluster/dist/MarkerCluster.css";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
import "leaflet.markercluster";
import 'leaflet/dist/leaflet.css';
import { nextTick } from 'vue';
import AppHeader from './AppHeader.vue';
import SearchForm from './SearchForm.vue';
import ObjectList from './ObjectList.vue';
import AppFooter from './AppFooter.vue';

export default {
  name: 'MainPage',
  components: {
    AppHeader,
    SearchForm,
    ObjectList,
    AppFooter
  },
  data() {
    return {
      filters: {
        selectedObjectTypes: [],
        selectedActivities: [],
        guestCount: 1,
      },
      isMapView: false, // Переключатель режима карты и списка
      objects: [], // Список объектов для отображения
      map: null // Экземпляр карты Leaflet
    };
  },
  computed: {
    // Проверяем, были ли применены какие-либо фильтры
    areFiltersApplied() {
      return Object.keys(this.filters).length > 0;
    }
  },
  methods: {
    applyFilters(newFilters) {
      this.filters = {
        selectedActivities: newFilters.activities || [],
        selectedObjectTypes: newFilters.objectTypes || [],
        guestCount: newFilters.guestCount || 1,
      };

      // Сериализуем массивы для передачи в URL
      const query = {
        selectedActivities: JSON.stringify(this.filters.selectedActivities),
        selectedObjectTypes: JSON.stringify(this.filters.selectedObjectTypes),
        guestCount: this.filters.guestCount,
      };

      this.$router.push({ query });
      this.fetchObjects();
      if (this.isMapView) {
        // Используем $nextTick, чтобы убедиться, что DOM обновлен перед загрузкой карты
        this.$nextTick(() => {
          this.loadMap();  // Загружаем карту только после того, как DOM обновлен
        });
      }
    },
    /*applyFilters(newFilters) {
      this.filters = {
        selectedActivities: newFilters.selectedActivities || [],
        selectedObjectTypes: newFilters.selectedObjectTypes || [],
        guestCount: newFilters.guestCount || 1,
      };
      this.$router.push({ query: this.filters });
      this.fetchObjects();
      if (this.isMapView) {
        // Используем $nextTick, чтобы убедиться, что DOM обновлен перед загрузкой карты
        this.$nextTick(() => {
          this.loadMap();  // Загружаем карту только после того, как DOM обновлен
        });
      }
    },*/
    async fetchObjects() {
      try {
        let query = 'https://лк.домшеринг.рф/api/voa?sort=-id';

        console.log(this.filters.selectedActivities.length);
        // Применение фильтров по активностям и типам объектов
        if (this.filters.selectedActivities && this.filters.selectedActivities.length) {
          this.filters.selectedActivities.forEach(activity => {
            query += `&filters[activities-x]=${activity}`;
          });
        }

        if (this.filters.selectedObjectTypes && this.filters.selectedObjectTypes.length) {
          query += `&filters[object_type-a]=`;
          this.filters.selectedObjectTypes.forEach(type => {
            query += `${type},`;
          });
        }

        if (this.filters.guestCount) {
          query += `&filters[size-ge]=${this.filters.guestCount}`;
        }

        const response = await axios.get(query);
        const objects = response.data.data;

        /*
        let dateRange = this.filters.dateRange;
        if (typeof dateRange === 'string') {
          dateRange = dateRange.split('—').map(date => date.trim());
        }

        console.log('Updated dateRange:', dateRange);


        if (Array.isArray(dateRange) && dateRange.length === 2) {
          const startDateFilter = new Date(dateRange[0].split('.').reverse().join('-')).setHours(0, 0, 0, 0);
          const endDateFilter = new Date(dateRange[1].split('.').reverse().join('-')).setHours(23, 59, 59, 999);

          console.log('Start Date:', startDateFilter, 'End Date:', endDateFilter);

          this.objects = objects.filter((object) => {
            const objectPeriods = objects.filter(o => o.id === object.id);

            // Фильтруем объект, если хотя бы один его период НЕ пересекается с диапазоном дат фильтра
            const isObjectAvailable = objectPeriods.some(o => {
              if (!o.start_dt && !o.end_dt) {
                // Если нет указанных дат для объекта, он доступен
                return true;
              }

              const startDate = o.start_dt ? new Date(o.start_dt.split(' ')[0]).getTime() : null;
              const endDate = o.end_dt ? new Date(o.end_dt.split(' ')[0]).getTime() : null;

              console.log('Object Start Date:', startDate, 'Object End Date:', endDate);

              // Проверка на пересечение дат: если даты объекта не пересекаются с датами фильтра, объект считается доступным
              return (endDate < startDateFilter || startDate > endDateFilter);
            });

            // Если хотя бы один период доступен, показываем объект
            return isObjectAvailable;
          });

          // Убираем дубликаты объектов после фильтрации
          this.objects = [...new Map(this.objects.map(item => [item.id, item])).values()];
        } else { */
          // Если нет фильтра по датам, убираем дубликаты и выводим все объекты
          this.objects = [...new Map(objects.map(item => [item.id, item])).values()];
        //}

        if (this.isMapView && !this.map) {
          this.loadMap(); // Загружаем карту, если она не была загружена
        }
      } catch (error) {
        console.error('Ошибка при загрузке объектов:', error);
      }
    },
    // Инициализация карты Leaflet и добавление маркеров
    /*loadMap() {
      nextTick(() => {
        if (this.map) {
          this.map.remove(); // Удаляем старую карту перед повторной инициализацией
          this.map = null; // Обнуляем объект карты
        }

        // Создаем карту с центром в Санкт-Петербурге
        this.map = L.map('map', {
          center: [59.9342802, 30.3350986],  // Координаты Санкт-Петербурга
          zoom: 10,
          zoomAnimation: false,  // Отключаем анимацию зума
          //fadeAnimation: false,   // Отключаем fade-анимацию
          attributionControl: false  // Отключаем стандартную атрибуцию
        });

        // Создаем кастомную атрибуцию
        const myAttrControl = L.control.attribution().addTo(this.map);
        myAttrControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');  // Устанавливаем кастомный префикс атрибуции

        // Добавляем слой карт от OpenStreetMap
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          detectRetina: true,
          attribution: 'Data by &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, under <a href="https://opendatacommons.org/licenses/odbl/">ODbL.</a>'
        }).addTo(this.map);

        // Добавляем обработчик на закрытие попапа
        this.map.on('popupclose', () => {
          if (this.map) {
            this.map.invalidateSize();  // Обновляем размеры карты при закрытии попапа
          }
        });

        // Добавляем маркеры для каждого объекта
        this.objects.forEach((obj) => {
          // Создаем кастомный маркер с иконкой
          const customIcon = L.icon({
            iconUrl: require('@/assets/location_map.svg'),  // Путь к вашей иконке маркера
            iconSize: [25, 41], // Размер иконки
            iconAnchor: [12, 41], // Точка привязки иконки (середина внизу)
            popupAnchor: [1, -34], // Точка, откуда будет "выплывать" окно
            // shadowUrl: 'https://your-site.com/path/to/marker-shadow.png',  // Путь к тени маркера (если нужно)
            shadowSize: [41, 41], // Размер тени
          });

          const marker = L.marker([obj.latitude, obj.longitude], { icon: customIcon }).addTo(this.map);

          // Добавляем всплывающее окно с информацией о объекте
          marker.bindPopup(`
          <div style="text-align: center;">
          <img src="https://лк.домшеринг.рф/file/${obj.file_id}/${obj.file_name}" style="width: 100%; height: auto; max-width: 200px;" /><br>
          <strong>${obj.nm}</strong><br>
          ${obj.object_location}<br>
          до ${obj.max_guests} чел.<br>
          от ${obj.min_price} р/сут.<br>
          <a href="/object/${obj.id}?startDate=${this.filters.dateRange ? this.filters.dateRange.split('—')[0].trim() : ''}&endDate=${this.filters.dateRange ? this.filters.dateRange.split('—')[1].trim() : ''}&guestCount=${this.filters.guestCount || 1}" class="popup-link" target="_blank">Посмотреть подробнее</a>
          </div>
          `);
        });
      });
    }*/
    loadMap() {
      nextTick(() => {
        if (this.map) {
          this.map.remove(); // Удаляем старую карту перед повторной инициализацией
          this.map = null; // Обнуляем объект карты
        }

        // Опции карты
        const defaultCenter = [59.9342802, 30.3350986]; // Координаты Санкт-Петербурга
        const defaultZoom = 10;

        // Проверяем геолокацию пользователя
        const initializeMap = (center) => {
          this.map = L.map("map", {
            center: center,
            zoom: defaultZoom,
            zoomAnimation: false,
            attributionControl: false,
          });

          const myAttrControl = L.control.attribution().addTo(this.map);
          myAttrControl.setPrefix('<a href="https://leafletjs.com/">Leaflet</a>');

          L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
            maxZoom: 19,
            detectRetina: true,
            attribution: 'Data by &copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, under <a href="https://opendatacommons.org/licenses/odbl/">ODbL.</a>',
          }).addTo(this.map);

          const markers = L.markerClusterGroup();

          // Добавляем маркеры объектов
          this.objects.forEach((obj) => {
            const customIcon = L.icon({
              iconUrl: require("@/assets/location_map.svg"),
              iconSize: [25, 41],
              iconAnchor: [12, 41],
              popupAnchor: [1, -34],
              shadowSize: [41, 41],
            });

            const marker = L.marker([obj.latitude, obj.longitude], { icon: customIcon }); //.addTo(this.map);

            marker.bindPopup(`
          <div style="text-align: center;">
          <img src="https://лк.домшеринг.рф/file/${obj.file_id}/${obj.file_name}" style="width: 100%; height: auto; max-width: 200px;" /><br>
          <strong>${obj.nm}</strong><br>
          ${obj.object_location}<br>
          до ${obj.size} чел.<br>
          от ${obj.price_dec} р/сут.<br>
          <a href="/object/${obj.id}?startDate=${this.filters.dateRange ? this.filters.dateRange.split("—")[0].trim() : ""}&endDate=${this.filters.dateRange ? this.filters.dateRange.split("—")[1].trim() : ""}&guestCount=${this.filters.guestCount || 1}" class="popup-link" target="_blank">Посмотреть подробнее</a>
          </div>
        `);
            markers.addLayer(marker);
          });
          this.map.addLayer(markers);
        };

        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
              (position) => {
                const userLocation = [position.coords.latitude, position.coords.longitude];
                initializeMap(userLocation);
              },
              (error) => {
                console.error("Геолокация недоступна:", error);
                initializeMap(defaultCenter);
              }
          );
        } else {
          console.warn("Геолокация не поддерживается в этом браузере.");
          initializeMap(defaultCenter);
        }
      });
    }
  },
  watch: {
    isMapView(newValue) {
      // Если переключаемся на режим карты, загружаем карту
      if (newValue && this.objects.length) {
        this.loadMap();
      }
    },
  },
  mounted() {
    if (Object.keys(this.$route.query).length > 0) {
      this.filters = {
        selectedActivities: this.$route.query.selectedActivities ? JSON.parse(this.$route.query.selectedActivities) : [],
        selectedObjectTypes: this.$route.query.selectedObjectTypes ? JSON.parse(this.$route.query.selectedObjectTypes) : [],
        guestCount: this.$route.query.guestCount ? Number(this.$route.query.guestCount) : 1,
      };
      this.fetchObjects();
    } else {
      this.fetchObjects();
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (Object.keys(to.query).length > 0) {
        vm.filters = {
          selectedActivities: to.query.selectedActivities ? JSON.parse(to.query.selectedActivities) : [],
          selectedObjectTypes: to.query.selectedObjectTypes ? JSON.parse(to.query.selectedObjectTypes) : [],
          guestCount: to.query.guestCount ? Number(to.query.guestCount) : 1,
        };
        vm.fetchObjects();
      }
    });
  }
}
</script>

<style scoped>

.hero {
  padding: 50px 20px 0;
  flex-direction: column;
  gap: 15px;
}

@media (width >= 768px) {
  .hero {
    padding: 80px 70px 0 44px;
    flex-direction: row;
  } 
}

@media (width >= 768px) and (width < 892px) {
  .hero {
    padding: 80px 70px 30px 44px;
  } 
}

@media (width >= 1440px) {
  .hero {
    padding: 64px 80px 0;
  } 
}

@media (width >= 768px) {
  .objects-img {
    width: 300px;
    height: 186px;
  } 
}

@media (width >= 768px) and (width < 892px) {
  .objects-img {
    margin-bottom: -50px;
  } 
}

@media (width >= 1440px) {
  .objects-img {
    width: 386px;
    height: 240px;
  } 
}

@media (width < 768px) {
  .hero-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.view-toggle {
  display: flex;
  justify-content: center;
  margin: 20px;
}

.view-toggle button {
  padding: 10px 20px;
  border: none;
  background-color: #ffffff;
  border: 2px solid #199057;
  color: #199057;
  cursor: pointer;
  width: 280px;
}

.view-toggle button.active {
  background-color: #199057;
  color: white;
}


.btn-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

@media (width < 768px) {
  .btn-left {
    font-size: 16px;
  }
}
.btn-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

@media (width < 768px) {
  .btn-right {
    font-size: 16px;
  }
}


.map-section {
  width: 100%;
  height: 500px;
}

.map {
  width: 100%;
  height: 500px; /* Задайте нужную высоту карты */
  position: relative;
  z-index: 1; /* Карта будет под остальными элементами */
}

.description  {
  br {
    display: none;
  }

  @media (min-width: 980px) {
    br {
      display: initial;
    }
  }
}

</style>